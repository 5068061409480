
/*
Purpose: Edit details of staff
Designed: BY KTH
Date:30/10/2023 
Project: TCMS
*/

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { Row, Col, Stack, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { security_key, options_emp_type, options_time_zone, 
    options_countries, options_gender, BASE_URL} from "../global";
    import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
    import PhoneInput from 'react-phone-input-2';

    import Tooltip from 'react-bootstrap/Tooltip';

    import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons
    import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
    import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
    import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  
    import dayjs from 'dayjs';


    const phoneNumberRules = {
        'SG': {
          min: 8,
          max: 8,
          message: "Phone number must be exactly 8 digits.",
        }, // Singapore
      
        'AU': {
          min: 9,
          max: 10,
          message: "Phone number must be between 9 and 10 digits.",
        }, // Australia
      
        'GB': {
          min: 10,
          max: 10,
          message: "Phone number must be exactly 10 digits.",
        }, // United Kingdom
      
        'NZ': {
          min: 8,
          max: 10,
          message: "Phone number must be between 8 and 10 digits.",
        }, // New Zealand
      
        
        'General': {
        min: 5,
        max: 15,
        message: "Phone number must be between 5 to 15 digits.",
      } // general number lenght
      };
//staff function
function EditStaff() {


    const [passwordVisible, setPasswordVisible] = useState(false);
   
  
    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    const [ConpasswordVisible, setConPasswordVisible] = useState(false);
   
  
    const toggleConPasswordVisibility = () => {
        setConPasswordVisible(!ConpasswordVisible);
    };

  const navigate = useNavigate();

  const handleSave = () => {
      navigate("/ListStaff");
  };

  const [staffData, setStaffData] = useState([]);

  //const [id, setId] = useState('');
 
  const location = useLocation();
  useEffect(() => {
    
    const { pk_staff_key } = location.state || {};
    const extractedStafId = pk_staff_key;


    if (extractedStafId !=='' && extractedStafId !== undefined) {
      //setId(extractedId);
      fetchData(extractedStafId);
    }else{
        navigate("/ListStaff");
      }
  }, [location]);
 
 const [imageUrl, setImageUrl] = useState('');
//Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleStaff",
                center_key: sessionStorage.getItem('key'),
                pk_staff_key: id
            });

            if (response.data.success === true) {
                if(response.data.data.length > 0){
                    setStaffData(response.data.data[0]);

                    if(response.data.data[0]['staff_profile_pic'] != null && response.data.data[0]['staff_profile_pic'] != ''){

                    

                   const fullImageUrl = `${response.data.data[0]['staff_profile_pic']}`;
                   
                    setImageUrl(fullImageUrl);
                    }
              
                }
            
            
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
//Api call End


    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);
        

        // if(!imageUrl){

        //     // try {
        //     //     // Create a FormData object to send the form data, including the image file
        //     //     const formData = new FormData();
        //     //     formData.append("mode", "file_s3_upload_image");
        //     //     formData.append("image", formValues.staff_profile_pic); // Append the image file
        
        //     //     // Make an API request here with the FormData object
        //     //     const response_file = await axios.post(BASE_URL+'API/upload/index.php', formData);
        
        //     //     // Handle the API response here
        //     //     if (response_file.data.success === true) {

                    try {
                        const response = await axios.post(BASE_URL+"API/api_index.php", {
                            security_key: security_key,
                            mode: "updateStaff",
                            file_name: formValues.staff_profile_pic,
                            ...formValues,
                        });
            
                        if (response.data.success === true) {
                            if(response.data.data === 1){
                                //resetFormFields(resetForm, setValues, initialValues);
                                toast.success("Success! Your form was submitted", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
            
                                // After 3 seconds, redirect to another page
                                setTimeout(function () {
                                    navigate("/ListStaff");
                                    setIsSubmitting(false); // Re-enable the submit button
                                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
            
                                
                            }else{
                                toast.error("Failure! Unable to update staff. Try again later", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
            
                                setIsSubmitting(false); // Re-enable the submit button
                            }
                            
                        } else {
            
                            toast.error("Failure! Unable to update staff. Try again later", {
                                autoClose: 3000, // 3 seconds in this example
                            });
            
                            setIsSubmitting(false); // Re-enable the submit button
                            
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                            toast.error("Failure! Unable to update staff. Try again later", {
                                autoClose: 3000, // 3 seconds in this example
                            });
            
                            setIsSubmitting(false); // Re-enable the submit button
                        
                    } finally {
                        setIsSubmitting(false); // Re-enable the submit button
                        
                    }


        //         }else{

        //         }
        
        //         // You can also perform further actions based on the API response
        //         // For example, show a success message or redirect to another page.
        //       } catch (error) {
        //         // Handle any API request errors here
        //         toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //             autoClose: 3000, // 3 seconds in this example
        //         });

        //         setIsSubmitting(false); // Re-enable the submit button
        
                
        //       }
        
                           

        // }else{

        //     try {
        //         const response = await axios.post(BASE_URL+"API/api_index.php", {
        //             security_key: security_key,
        //             mode: "updateTutor_new",
        //             file_name: formValues.staff_profile_pic,
        //             ...formValues,
        //         });
    
        //         if (response.data.success === true) {
        //             if(response.data.data === 1){
        //                 //resetFormFields(resetForm, setValues, initialValues);
        //                 toast.success("Success! Your form was submitted", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });
    
        //                 // After 3 seconds, redirect to another page
        //                 setTimeout(function () {
        //                     navigate("/list-tutor");
        //                 }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
    
        //                 setIsSubmitting(false); // Re-enable the submit button
        //             }else{
        //                 toast.error("Oops! Something went wrong.", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });
    
        //                 setIsSubmitting(false); // Re-enable the submit button
        //             }
                    
        //         } else {
    
        //             toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                 autoClose: 3000, // 3 seconds in this example
        //             });
    
        //             setIsSubmitting(false); // Re-enable the submit button
                    
        //         }
        //     } catch (error) {
        //         console.error("Error fetching data:", error);
        //             toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                 autoClose: 3000, // 3 seconds in this example
        //             });
    
        //             setIsSubmitting(false); // Re-enable the submit button
                
        //     } finally {
        //         setIsSubmitting(false); // Re-enable the submit button
                
        //     }

        // }

       
       
    };

   
    const basicSchema1 = yup.object().shape({
        staff_fname: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        staff_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        //salutation: yup.mixed().required("Salutation is required."),
        staff_dob: yup
        .string()
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          const parsedDate = dayjs(value, 'YYYY-MM-DD');
          return parsedDate.isValid();
        }).required("Date of birth is required."),
        staff_gender: yup.mixed().required("Gender is required."),
        //staff_profile_pic: yup.mixed().required("File is required."),
        staff_notes: yup.string().notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        staff_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Address is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        staff_zip: yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").required("Postal code is required.").matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
        //staff_address2: yup.mixed().required("Address2 is required."),
        //tut_suburb: yup.mixed().required("Suburb is required."),
        staff_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required(" City/Town is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        staff_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("State is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        //tut_postcode: yup.mixed().required("Postcode is required."),
        //staff_country: yup.mixed().required("Country is required."),
        staff_mobile_countryCode: yup.string().notRequired(),
        staff_other_countryCode: yup.string().notRequired(),
        staff_mobile_phone: yup
        .string()
        .test(
          'valid-phone-number',
          function (value) {
            const { staff_mobile_countryCode } = this.parent;
            const countryCodeMap = {
                '44': 'GB',   // UK
                '61': 'AU',   // Australia
                '64': 'NZ',   // New Zealand
                '65': 'SG',   // Singapore
                
              };
        
             
              const countryKey = countryCodeMap[staff_mobile_countryCode];
        
              
              const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
        
            
              const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${staff_mobile_countryCode}\\s?`, 'g'), '');
             
              if (countrySpecificRule) {
                const { min, max, message } = countrySpecificRule;
                const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                // Check if the phone number length is within the valid range for the country
                if (phoneLength < min || phoneLength > max) {
                  return this.createError({ message: message });
                }
              }
        
              return true;
          }
        )
        .required("Mobile phone is required."),
        staff_other_phone: yup
        .string()
        .test(
          'valid-phone-number',
          function (value) {
            const { staff_other_countryCode } = this.parent;
            const countryCodeMap = {
                '44': 'GB',   // UK
                '61': 'AU',   // Australia
                '64': 'NZ',   // New Zealand
                '65': 'SG',   // Singapore
                
              };
        
             
              const countryKey = countryCodeMap[staff_other_countryCode];
        
              
              const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
        
            
              const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${staff_other_countryCode}\\s?`, 'g'), '');
              if (!cleanPhoneNumber ) {
                return true; 
              }
              if (countrySpecificRule) {
                const { min, max, message } = countrySpecificRule;
                const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                // Check if the phone number length is within the valid range for the country
                if (phoneLength < min || phoneLength > max) {
                  return this.createError({ message: message });
                }
              }
        
              return true;
          }
        )
        .notRequired(),
        //staff_other_phone: yup.string().min(7, "Minimum 5 digit required").required("Phone is required."),
        //tut_mobile: yup.mixed().required("Mobile is required."),
        staff_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
            return value && value.includes('.');
          }),
        // ta_login_id: yup.string().required("Username is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        // ta_password: yup.string().min(5, "Minimum 5 characters is required.").matches(passwordRules).required("Password is required."),
        // ta_confirm_password: yup
        //     .string()
        //     .oneOf([yup.ref("ta_password"), null], "Password is not matching")
        //     .required("Confirm password is required."),
        //staff_timezone: yup.mixed().required("Time zone is required."),
        //is_active: yup.boolean().oneOf([true], "Please check the Is Active checkbox").required("Please check the Is Active checkbox"),
        staff_type: yup.mixed().required("Staff type is required."),
        staff_st_date: yup
        .string()
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          const parsedDate = dayjs(value, 'YYYY-MM-DD');
          return parsedDate.isValid();
        }).required("Start date is required."),
        staff_en_date: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
         
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),
        
        //staff_en_date: yup.mixed().required("End date is required."),
        //effective_date: yup.mixed().required("Effective date is required."),
        //te_wage_typ: yup.mixed().required("Wage type is required."),
        //te_wages:  yup.number().positive().integer().required("Wage rate is required."),
         staff_monthly_wage: yup.number().integer().min(0, "Minimum value 0 required").required("Monthly Wage is required."),
        staff_weekly_wage: yup.number().integer().min(0, "Minimum value 0 required").required("Weekly Wage is required.")
    
    });

    const formik  = useFormik({
        initialValues: {
                /*tbl_tutor*/
           
            staff_dob: staffData ? staffData.staff_dob : '',
            staff_fname: staffData ? staffData.staff_fname : '',
            staff_surname: staffData ? staffData.staff_surname : '',
            staff_profile_pic: staffData ? staffData.staff_profile_pic : null,
            staff_gender: staffData ? staffData.staff_gender : '',
            staff_notes: staffData ? staffData.staff_notes : '',
            staff_address: staffData ? staffData.staff_address : '',
            staff_mobile_countryCode: "",
            staff_other_countryCode: "",
            staff_city: staffData ? staffData.staff_city : '',
            staff_state: staffData ? staffData.staff_state : '',
            
            staff_country: staffData ? staffData.staff_country !=null &&  staffData.staff_country !='' ?  staffData.staff_country : sessionStorage.getItem('country_code')!=null &&  sessionStorage.getItem('country_code')!='' ? sessionStorage.getItem('country_code'): 'SG' : sessionStorage.getItem('country_code')!=null &&  sessionStorage.getItem('country_code')!='' ? sessionStorage.getItem('country_code'): 'SG',
            //staff_country: staffData ? staffData.staff_country : '',
            staff_zip: staffData ? staffData.staff_zip : '',
            staff_mobile_phone: staffData ? staffData.staff_mobile_phone : '',
            staff_other_phone: staffData ? staffData.staff_other_phone : '',
            
            staff_emailid: staffData ? staffData.staff_emailid : '',

            /*tbl_tutor_auth*/
            // ta_login_id: staffData ? staffData.ta_login_id : '',
            // ta_password: staffData ? staffData.ta_password : '',
            // ta_confirm_password: staffData ? staffData.ta_password : '', //Not in db

            /*tbl_tut_employee*/
            staff_timezone: staffData ? staffData.staff_timezone !=null && staffData.staff_timezone !='' ? staffData.staff_timezone : sessionStorage.getItem('time_zone') : sessionStorage.getItem('time_zone'),
            is_active: staffData ? parseInt(staffData.visibility) === 1 ? true : false : false, //Not in db
           
            staff_type: staffData ? staffData.staff_type : '',
            staff_st_date: staffData ? staffData.staff_st_date : '', //Not in form
            staff_en_date: staffData ? staffData.staff_en_date : '', //Not in form
            
           

            staff_monthly_wage: staffData ? staffData.staff_monthly_wage : '0',
            staff_weekly_wage: staffData ? staffData.staff_weekly_wage : '0',
            
           
            /*Common*/
            updated_by: sessionStorage.getItem('key'),
            fk_cent_key: staffData ? staffData.fk_cent_key : '',
            pk_staff_key: staffData ? staffData.pk_staff_key : '',

            },
        enableReinitialize: true,
        validationSchema: basicSchema1,
        onSubmit,
    });
      // Now, you can set variables as needed:
      const values = formik.values;
      const handleBlur = formik.handleBlur;
      const touched = formik.touched;
      const handleChange = formik.handleChange;
      //const handleDateChange = formik.handleChange;
      const errors = formik.errors;
      const handleSubmit = formik.handleSubmit;
      const setTouched = formik.setTouched;
      const resetForm = formik.resetForm;
      const setValues = formik.setValues;
      const handleReset = formik.handleReset;
      const fileInputRef = useRef(null);
   
    function handelRemove(event) {
        event.preventDefault();
        setImageUrl('');
    }



    const [isSubmittingPass, setIsSubmittingPass] = useState(false);

    const [showModal1, setShowModal1] = useState(false);
    const toggleModal1 = () => setShowModal1(!showModal1);

    //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    const validationSchema1 = yup.object().shape({
        sa_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
        sa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        sa_confirm_password: yup
            .string()
            .oneOf([yup.ref("sa_password"), null], "Password is not matching")
            .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

    });




    const initialValues1 = {
       
        pk_staff_key: staffData ? staffData.pk_staff_key : '',

        /*tbl_center_auth*/
        sa_login_id:  staffData ? staffData.sa_login_id : '',
        
        sa_password:  staffData ? staffData.sa_direct_password : '',
        sa_confirm_password: "", //Not in db
        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
  };


    const formik1 = useFormik({
        initialValues: initialValues1,
        validationSchema: validationSchema1,
        enableReinitialize: true,
        onSubmit: async (values) => {

            setIsSubmittingPass(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateStaffAuth",
                    ...values,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Manage Login.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setTimeout(function () {
                            navigate("/ListStaff");
                            setIsSubmittingPass(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
                        //formik1.resetForm();
                        // toggleModal1();



                       
                    } else if (response.data.data === 2) {

                        toast.error("Staff username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button

                    } else {
                        toast.error("Failure! Unable to update manage login. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update manage login. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmittingPass(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update manage login. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmittingPass(false); // Re-enable the submit button

            } finally {
                setIsSubmittingPass(false); // Re-enable the submit button

            }

        },
    });

    const handleUsernameBlur = useCallback(async () => {
        const username = formik1.values.sa_login_id;
        if (username) {
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    pk_staff_key: staffData.pk_staff_key,
                    userID: username,
                    security_key: security_key,
                    mode: "checkStaffUserId",
                });

                if (response.data.success === true) {
                    formik1.setFieldTouched("sa_login_id", true, false);
                    formik1.setFieldError("sa_login_id", "Username is not available");
                } else {
                    formik1.setFieldTouched("sa_login_id", true, false);
                    formik1.setFieldError("sa_login_id", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik1.setFieldError("sa_login_id", null);
            }
        }
    }, [formik1.values.sa_login_id]);
    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);
  return (

    <>
    {/* React alert */}
     <ToastContainer position="top-center" />
    <Modal show={showModal1} onHide={toggleModal1} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Login</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik1.handleSubmit}>
                    <Modal.Body>

                        {/* --------------Date--------------- */}

                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>TCMS Login</h6>
                        </div>
                        <Row>


                            {/* --------------Contact Details Title--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Username</h6>
                                <Form.Control
                                    type="text"
                                    //readOnly
                                    id="sa_login_id"
                                    value={formik1.values.sa_login_id}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    //onBlur={handleUsernameBlur}
                                    className={formik1.errors.sa_login_id && formik1.touched.sa_login_id ? "input-error" : ""}
                                />
                                {formik1.errors.sa_login_id && formik1.touched.sa_login_id && <p className="error">{formik1.errors.sa_login_id}</p>}
                            </Col>


                            {/* --------------Contact Details Title--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control
                                    type={passwordVisible ? "text" : "password"}

                                    id="sa_password"
                                    value={formik1.values.sa_password}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    className={formik1.errors.sa_password && formik1.touched.sa_password ? "input-error" : ""}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik1.errors.sa_password && formik1.touched.sa_password && <p className="error">{formik1.errors.sa_password}</p>}
                            </Col>


                            {/* --------------Contact Details Title--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control
                                    type={ConpasswordVisible ? "text" : "password"}

                                    id="sa_confirm_password"
                                    value={formik1.values.sa_confirm_password}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    className={formik1.errors.sa_confirm_password && formik1.touched.sa_confirm_password ? "input-error" : ""}
                                />
                                <button
                                    type="button"
                                    onClick={toggleConPasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik1.errors.sa_confirm_password && formik1.touched.sa_confirm_password && (
                                    <p className="error">{formik1.errors.sa_confirm_password}</p>
                                )}
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>

                        <div className="mx-auto d-flex gap-2" >

                            <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                            <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass}>Submit</Button>

                        </div>

                    </Modal.Footer>
                </Form>
            </Modal>
     {/* center form */}
<Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
    <div className="">

        <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'> Edit Staff </h5>
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                        <Typography color="text.primary">Staff</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div>
                            <div className='d-flex justify-content-end gap-2'>
                                <div >
                                <Button variant="contained"  className="primary-filled" style={{ fontSize: '12px' }} onClick={() => toggleModal1()} > Manage Login</Button>
                                    {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                        <Dropdown.Item onClick={() => toggleModal1()}>  Manage Login
                                        </Dropdown.Item>


                                    </DropdownButton> */}
                                </div>
                            </div>
                        </div>
         
        </div>
        
       


                {/* --------------Tutor Personal--------------- */}
        <Stack>
            <div className="my-card p-4 bg-white">
                <div className='section-heading border-bottom pb-3 mb-3'>
                    <h6 className='mb-0'>Personal </h6>
                </div>
                    <Row>          

                        {/* --------------Tutor Salutation---------------        
                        {hide_some_filed && (
                        <Form.Group as={Row} className="mb-3" controlId="salutation">
                        <Form.Label column sm="3">
                            <h6>Salutation</h6>
                        </Form.Label>
                        <Col lg="4" md="6" sm="12" className='position-relative'>
                             <Select menuPlacement="auto"  options={options_salutation} 
                                //value={values.salutation}
                                onChange={(selectedOption) => {
                                    const event = {
                                        target: {
                                            name: "salutation",
                                            value: selectedOption["value"],
                                        },
                                    };
                                    handleChange(event);
                                }}
                                onBlur={(event) => {
                                    handleBlur(event);
                                    setTouched({ ...touched, salutation: true });
                                }}
                                className={errors.salutation && touched.salutation ? "input-error" : ""}

                                
                                
                            />
                            {errors.salutation && touched.salutation && <p className="error">{errors.salutation}</p>}
                        </Col>
                    </Form.Group>
                        )} */}

                {/* --------------Tutor First Name--------------- */}
                
                    <Col lg="4" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">First Name</h6>
                        <Form.Control type="text" 
                            id='staff_fname'
                            value={values.staff_fname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_fname && touched.staff_fname ? "input-error" : ""}
                            
                         />
                        {errors.staff_fname && touched.staff_fname && <p className="error">{errors.staff_fname}</p>}
                    </Col>
                                   
                {/* --------------Tutor Last Name--------------- */}
                
                    <Col lg="4" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Last Name</h6>
                        <Form.Control type="text" 
                        id='staff_surname'
                            value={values.staff_surname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_surname && touched.staff_surname ? "input-error" : ""}
                            
                        />
                        {errors.staff_surname && touched.staff_surname && <p className="error">{errors.staff_surname}</p>}
                    </Col>
                                    
                                {/* --------------Student Gender--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Gender</h6>
                                     <Select menuPlacement="auto"  options={options_gender}
                                     name="staff_gender"
                                     value={options_gender.find(option => option.value === values.staff_gender)}
                                     id="staff_gender"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "staff_gender",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, staff_gender: true });
                                     }}
                                     className={errors.staff_gender && touched.staff_gender ? "input-error" : ""}
                                      />
                                      {errors.staff_gender && touched.staff_gender && <p className="error">{errors.staff_gender}</p>}
                                </Col>
                                  

                {/* --------------Tutor Date of Birth--------------- */}   
            
                    <Col lg="4" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Date of Birth</h6>
                        {/* <Form.Control type="date" 
                            id='staff_dob'
                            max={new Date().toISOString().split("T")[0]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.staff_dob}
                            className={errors.staff_dob && touched.staff_dob ? "input-error" : "mb-0"}
                        
                         /> */}
                         <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.staff_dob, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "staff_dob",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_dob: true });
                                            }}
                                            name="staff_dob"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'staff_dob',
                                                },
                                                }}
                                            className={errors.staff_dob && touched.staff_dob ? "input-error w-100" : "w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                        {errors.staff_dob && touched.staff_dob && <span className="error">{errors.staff_dob}</span>}
                    </Col>
                                   
                {/* --------------Tutor Photo--------------- */}   
                
                    <Col lg="4" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable">Photo</h6>
                        {!imageUrl && ( 
                       
                        <Form.Control type="file" 
                         name="staff_profile_pic"
                         id="staff_profile_pic"
                         accept=".png, .jpeg, .jpg, .jfif"
                         onChange={(event) => {
                             setTouched({ ...touched, staff_profile_pic: true });
                             if (event.target.files.length > 0) {
                                 const fileName = event.target.files[0];
                                 async function handleFileUpload(fileName) {
                                    try {
                                        // Create a FormData object to send the form data, including the image file
                                        const formData = new FormData();
                                        formData.append("mode", "file_s3_upload_image");
                                        formData.append("user", "staff");
                                        formData.append("image", fileName); // Append the image file
                                
                                        // Make an API request here with the FormData object
                                        const response_file = await axios.post(BASE_URL+'API/upload/index.php', formData);
                                
                                        // Handle the API response here
                                        if (response_file.data.success === true) {
                                          
                                            handleChange({ target: { name: "staff_profile_pic", value: response_file.data.data } });
                                        } else {
                                            handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                            fileInputRef.current.value = null;
                                        }
                                
                                        // You can also perform further actions based on the API response
                                        // For example, show a success message or redirect to another page.
                                    } catch (error) {
                                       
                                        handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                        fileInputRef.current.value = null;
                                    }
                                }
                                
                                // Call the async function when needed, passing the fileName as an argument
                                handleFileUpload(fileName);
                                
                                 
                             } else {
                                 // handleChange(event);
                             
                                 handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                 fileInputRef.current.value = null;
                                }
                         }}
                         ref={fileInputRef}
                         className={errors.staff_profile_pic && touched.staff_profile_pic ? "input-error" : ""}
                          />
                          
                    
                        )}
                         {imageUrl && (
                                <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                <img src={imageUrl} alt='image' width="50" height="50" />
                                    <div className="text-end">
                                        <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                                    </div>
                                </Col>
                            )}
                         
                        {errors.staff_profile_pic && touched.staff_profile_pic && <p className="error">{errors.staff_profile_pic}</p>}
                    </Col>
                 

                             

                {/* --------------Tutor Notes--------------- */}         
                
                    <Col lg="4" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable">Notes</h6>
                        <Form.Control as="textarea" rows={3}
                        id="staff_notes"
                        value={values.staff_notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.staff_notes && touched.staff_notes ? "input-error control_textarea" : "control_textarea mb-0"}
                         />
                        {errors.staff_notes && touched.staff_notes && <p className="error">{errors.staff_notes}</p>}
                    </Col>
                
                </Row>
            </div>
        </Stack>

        {/* --------------Tutor Personal End--------------- */}


        {/* --------------Contact Details--------------- */}
        <Stack className='mt-4'>
                <div className="my-card p-4 bg-white">
                    <div className='section-heading border-bottom pb-3 mb-3'>
                        <h6 className='mb-0'>Contact Details </h6>
                    </div>
        
                    <Row>
                               

                    {/* --------------Contact Address1--------------- */}         
                    
                        <Col lg="12" md="12" sm="12" className='position-relative'>
                            <h6 className="form-lable required-field">Address</h6>
                            <Form.Control as="textarea" rows={3}
                                
                                id="staff_address"
                                value={values.staff_address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.staff_address && touched.staff_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                />
                                {errors.staff_address && touched.staff_address && <p className="error">{errors.staff_address}</p>}
                        </Col>
                                   

                        {/* --------------Contact Address2--------------- */}         
                        {/* {hide_some_filed && (
                        <Form.Group as={Row} className="mb-3" controlId="staff_address2">
                            <Form.Label column sm="3">
                                <h6>Address 2</h6>
                            </Form.Label>
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <Form.Control as="textarea" rows={3}
                                // value={staffData.staff_city}
                                value={values.staff_address2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.staff_address2 && touched.staff_address2 ? "input-error control_textarea" : "control_textarea"}
                                 />
                                {errors.staff_address2 && touched.staff_address2 && <p className="error">{errors.staff_address2}</p>}
                            </Col>
                        </Form.Group>
                        )} */}
                        {/* --------------Contact Details Title--------------- */}
                        {/* {hide_some_filed && (
                        <Form.Group as={Row} className="mb-3" controlId="tut_suburb">
                            <Form.Label column sm="3">
                                <h6>Suburb</h6>
                            </Form.Label>
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <Form.Control type="text"
                                value={values.tut_suburb}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.tut_suburb && touched.tut_suburb ? "input-error" : ""}
                                 />
                                {errors.tut_suburb && touched.tut_suburb && <p className="error">{errors.tut_suburb}</p>}
                            </Col>
                        </Form.Group>
                        )} */}

                        {/* --------------Contact Details city--------------- */}
                        
                        <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                            <h6 className="form-lable required-field">City/Town</h6>
                            <Form.Control type="text"
                            id='staff_city'
                            value={values.staff_city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_city && touched.staff_city ? "input-error" : ""}
                             />
                            {errors.staff_city && touched.staff_city && <p className="error">{errors.staff_city}</p>}
                        </Col>
                                   

                    {/* --------------Contact Details Title--------------- */}
                    
                        <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                            <h6 className="form-lable required-field">County/Region/State</h6>
                            <Form.Control type="text"
                            id='staff_state'
                            value={values.staff_state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_state && touched.staff_state ? "input-error" : ""}
                             />
                            {errors.staff_state && touched.staff_state && <p className="error">{errors.staff_state}</p>}
                        </Col>
                                    

                                    {/* --------------Contact Details Title--------------- */}
                                    {/* {hide_some_filed && (
                                    <Form.Group as={Row} className="mb-3" controlId="tut_postcode">
                                        <Form.Label column sm="3">
                                            <h6>Postcode</h6>
                                        </Form.Label>
                                        <Col lg="4" md="6" sm="12" className='position-relative'>
                                           
                                            <Form.Control type="text"
                                             value={values.tut_postcode}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             className={errors.tut_postcode && touched.tut_postcode ? "input-error" : ""}
                                             />
                                            {errors.tut_postcode && touched.tut_postcode && <p className="error">{errors.tut_postcode}</p>}
                                        </Col>
                                    </Form.Group>

                                    )} */}

                                    {/* --------------Student Country--------------- */}         
                                    
                        <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                            <h6 className="form-lable required-field">Country</h6>
                             <Select menuPlacement="auto"  options={options_countries} 
                            //value={values.country["value"]}
                            value={options_countries.find(option => option.value && values.staff_country && option.value.toString() === values.staff_country.toString())}
                            // value={options_countries.find(option => option.value === values.staff_country)}
                            id='staff_country'
                            isDisabled={true}
                            onChange={(selectedOption) => {
                                const event = {
                                    target: {
                                        name: "staff_country",
                                        value: selectedOption["value"],
                                    },
                                };
                                handleChange(event);
                            }}
                            onBlur={(event) => {
                                handleBlur(event);
                                setTouched({ ...touched, staff_country: true });
                            }}
                            className={errors.staff_country && touched.staff_country ? "input-error" : ""}
                            />
                            {errors.staff_country && touched.staff_country && <p className="error">{errors.staff_country}</p>}
                        </Col>

                        <Col lg="4" md="6" sm="12" className='position-relative'>
                            <h6 className="form-lable required-field">Postal Code</h6>
                            <Form.Control type="text"  
                                value={values.staff_zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="staff_zip"
                                className={errors.staff_zip && touched.staff_zip ? "input-error" : ""} 
                                />
                            {errors.staff_zip && touched.staff_zip && <p className="error">{errors.staff_zip}</p>}
                        </Col>
                    </Row>           
                
                </div>
            </Stack>
        
    {/* --------------Contact Details End--------------- */}

            {/* --------------Contact Details--------------- */}
            <Stack className='mt-4'>
                <div className="my-card p-4 bg-white">
                    <div className='section-heading border-bottom pb-3 mb-3'>
                        <h6 className='mb-0'>Contact </h6>
                    </div>
        
                    <Row>


                    {/* --------------Contact Details Title--------------- */}
                    
                        <Col lg="4" md="6" sm="12" className='position-relative'>
                            <h6 className="form-lable required-field">Mobile Phone</h6>
                            {/* <Form.Control 
                            type="text"
                            id='staff_mobile_phone'
                            value={values.staff_mobile_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_mobile_phone && touched.staff_mobile_phone ? "input-error" : ""}
                            
                             />
                            {errors.staff_mobile_phone && touched.staff_mobile_phone && <p className="error">{errors.staff_mobile_phone}</p>} */}
                             <PhoneInput
                                name="staff_mobile_phone"
                                 countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                country={sessionStorage.getItem('country_alph_code')!=null &&  sessionStorage.getItem('country_alph_code')!='' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                value={values.staff_mobile_phone}
                                onChange={(phone, country) =>  {
                                    const event = {
                                    target: {
                                        name: "staff_mobile_phone",
                                        value: phone,
                                    },
                                    };
                                    const event1 = {
                                        target: {
                                            name: "staff_mobile_countryCode",
                                            value: country.dialCode,
                                        },
                                        };
                                    handleChange(event1);
                                    handleChange(event);
                                }}
                                onBlur={(event) => {
                                    handleBlur(event);
                                    setTouched({ ...touched, staff_mobile_phone: true });
                                }}
                                inputProps={{id: 'staff_mobile_phone'}}
                                id="staff_mobile_phone"
                                inputClass={errors.staff_mobile_phone && touched.staff_mobile_phone ? 'input-error form-control' : 'form-control'}
                            />
                            {errors.staff_mobile_phone && touched.staff_mobile_phone && <span className="error">{errors.staff_mobile_phone}</span>}
                        </Col>
                                   
                    {/* --------------Contact Details Title--------------- */}
                    
                        <Col lg="4" md="6" sm="12" className='position-relative'>
                            <h6 className="form-lable required-field">Other Phone</h6>
                            {/* <Form.Control type="text"
                            id='staff_other_phone'
                            value={values.staff_other_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.staff_other_phone && touched.staff_other_phone ? "input-error" : ""}
                            
                             />
                            {errors.staff_other_phone && touched.staff_other_phone && <p className="error">{errors.staff_other_phone}</p>} */}
                             <PhoneInput
                                name="staff_other_phone"
                                 countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                country={sessionStorage.getItem('country_alph_code')!=null &&  sessionStorage.getItem('country_alph_code')!='' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                value={values.staff_other_phone}
                                onChange={(phone, country) =>  {
                                    const event = {
                                    target: {
                                        name: "staff_other_phone",
                                        value: phone,
                                    },
                                    };
                                    const event1 = {
                                        target: {
                                            name: "staff_other_countryCode",
                                            value: country.dialCode,
                                        },
                                        };
                                    handleChange(event1);
                                    handleChange(event);
                                }}
                                onBlur={(event) => {
                                    handleBlur(event);
                                    setTouched({ ...touched, staff_other_phone: true });
                                }}
                                inputProps={{id: 'staff_other_phone'}}
                                id="staff_other_phone"
                                inputClass={errors.staff_other_phone && touched.staff_other_phone ? 'input-error form-control' : 'form-control'}
                            />
                            {errors.staff_other_phone && touched.staff_other_phone && <span className="error">{errors.staff_other_phone}</span>}
                        </Col>
                                   
                                    {/* --------------Contact Details Title--------------- */}
                                    {/* {hide_some_filed && (
                                    <Form.Group as={Row} className="mb-3" controlId="tut_mobile">
                                        <Form.Label column sm="3">
                                            <h6>Mobile</h6>
                                        </Form.Label>
                                        <Col lg="4" md="6" sm="12" className='position-relative'>
                                            <h6 className="form-lable required-field">Work Phone</h6>
                                            <Form.Control type="text"
                                              value={values.tut_mobile}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={errors.tut_mobile && touched.tut_mobile ? "input-error" : ""}
                                             
                                             />
                                            {errors.tut_mobile && touched.tut_mobile && <p className="error">{errors.tut_mobile}</p>}
                                        </Col>
                                    </Form.Group>
                                    )} */}
                    {/* --------------Contact Details Title--------------- */}
                    
                        <Col lg="4" md="6" sm="12" className='position-relative'>
                            <h6 className="form-lable required-field">Email</h6>
                            <Form.Control type="text"
                            id='staff_emailid'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.staff_emailid}
                                className={errors.staff_emailid && touched.staff_emailid ? "input-error" : ""}
                                
                                 />
                                {errors.staff_emailid && touched.staff_emailid && <p className="error">{errors.staff_emailid}</p>}
                        </Col>

                        
                                  {/* --------------Student Parents / Guardians --------------- */}         
                                
                                  <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none'}}>
                                    <h6 className="form-lable required-field">Time zone</h6>
                                     <Select menuPlacement="auto"  options={options_time_zone}
                                    id='staff_timezone'
                                        value={options_time_zone.find(option => option.value === values.staff_timezone)}
                                        name="staff_timezone"
                                        isDisabled={true}
                                            // value={values.staff_timezone["value"]}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: "staff_timezone",
                                                        value: selectedOption["value"],
                                                    },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_timezone: true });
                                            }}
                                            className={errors.staff_timezone && touched.staff_timezone ? "input-error" : ""}
                                        
                                    />
                                    {errors.staff_timezone && touched.staff_timezone && <p className="error">{errors.staff_timezone}</p>}
                                </Col>
                                   

                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <Form.Check
                                    type="checkbox"
                                    id='is_active'
                                    label="Is Active"
                                    checked={values.is_active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    
                                    />
                                    {errors.is_active && touched.is_active && <p className="error">{errors.is_active}</p>}
                                </Col>
                    </Row>   
    
                </div>
            </Stack>
                    
    {/* --------------Contact Details End--------------- */}


                {/* --------------Login Details--------------- */}
                {/* <Stack className='mt-4'>
                    <div className="my-card p-4 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Login </h6>
                        </div>
            
                            <Row> */}
                                

                            {/* --------------Contact Details Title--------------- */}
                            
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control type="text"
                                    id='ta_login_id' 
                                    value={values.ta_login_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.ta_login_id && touched.ta_login_id ? "input-error" : ""}
                                     />
                                        {errors.ta_login_id && touched.ta_login_id && <p className="error">{errors.ta_login_id}</p>}
                                </Col> */}
                                   
                            {/* --------------Contact Details Title--------------- */}
                            
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control type="password"
                                    id='ta_password'
                                        value={values.ta_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ta_password && touched.ta_password ? "input-error" : ""}
                                         />
                                        {errors.ta_password && touched.ta_password && <p className="error">{errors.ta_password}</p>}
                                </Col> */}
                                    
                                    {/* --------------Contact Details Title--------------- */}
{/*                                    
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control type="password" 
                                    id='ta_confirm_password'
                                        value={values.ta_confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ta_confirm_password && touched.ta_confirm_password ? "input-error" : ""}
                                    
                                     />
                                        {errors.ta_confirm_password && touched.ta_confirm_password && (
                                            <p className="error">{errors.ta_confirm_password}</p>
                                        )}
                                </Col> */}
                                   
                                  
                            {/* </Row>
                
                        </div>
                    </Stack> */}
                
            {/* --------------Login Details End--------------- */}


                {/* --------------Conditions Details--------------- */}
                <Stack className='mt-4'>
                <div className="my-card p-4 bg-white">
                    <div className='section-heading border-bottom pb-3 mb-3 d-flex'>
                        <h6 className='mb-0'>Employment Conditions</h6>&nbsp;
                        {/* {['right'].map((placement) => (
                            <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                    <Tooltip id={`tooltip-${placement}`} className='custom-tooltip'>
                                        If both flat rate fee and per student fee are input, the wage calculated for the tutor will use the following formula: Flat Rate Fee + (Number of Students * Per Student Fee)
                                    </Tooltip>
                                }
                                >
                                    <Button variant="secondary" className='no-bdr'>
                                        <i class="fa-solid fa-info-circle"></i>
                                    </Button>
                            </OverlayTrigger>
                        ))} */}
                    </div>
        
                        <Row>
                               
                        {/* --------------Student Parents / Guardians --------------- */}         
                        
                            <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                <h6 className="form-lable required-field">Staff Type</h6>
                                 <Select menuPlacement="auto"  options={options_emp_type} 
                                id='staff_type'
                                value={options_emp_type.find(option => option.value === values.staff_type)}

                                name="staff_type"
                                //value={values.staff_type["value"]}
                                onChange={(selectedOption) => {
                                    const event = {
                                        target: {
                                            name: "staff_type",
                                            value: selectedOption["value"],
                                        },
                                    };
                                    handleChange(event);
                                }}
                                onBlur={(event) => {
                                    handleBlur(event);
                                    setTouched({ ...touched, staff_type: true });
                                }}
                                className={errors.staff_type && touched.staff_type ? "input-error" : ""}
                                
                                />
                                {errors.staff_type && touched.staff_type && <p className="error">{errors.staff_type}</p>}
                            </Col>
                                  
                                    {/* --------------Student Parents / Guardians --------------- */}  
                                    {/* {hide_some_filed && (       
                                    <Form.Group as={Row} className="mb-3" controlId="effective_date">
                                        <Form.Label column sm="3">
                                            <h6>Effective Date</h6>
                                        </Form.Label>
                                        <Col lg="4" md="6" sm="12" className='position-relative'>
                                            
                                            <Form.Control type="date" 
                                              value={values.effective_date}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={errors.effective_date && touched.effective_date ? "input-error" : ""}
                                              
                                            />
                                            {errors.effective_date && touched.effective_date && <p className="error">{errors.effective_date}</p>}
                                        </Col>
                                    </Form.Group>
                                    )} */}
                                    
                        {/* --------------Start Date--------------- */}         
                        
                            <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                <h6 className="form-lable required-field">Start Date</h6>
                                {/* <Form.Control type="date" 
                                id="staff_st_date"
                                min={values.staff_st_date}
                                    value={values.staff_st_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_st_date && touched.staff_st_date ? "input-error" : ""}
                                    
                                /> */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.staff_st_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "staff_st_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_st_date: true });
                                            }}
                                            name="staff_st_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'staff_st_date',
                                                },
                                                }}
                                            className={errors.staff_st_date && touched.staff_st_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs(values.staff_st_date, 'YYYY-MM-DD')}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                {errors.staff_st_date && touched.staff_st_date && <span className="error">{errors.staff_st_date}</span>}
                            </Col>
                                    
                                    
                        {/* ------------- Emp End DATE--------------- */}         
                        {/* {hide_some_filed && (   */}
                            <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                <h6 className="form-lable ">End Date</h6>
                                {/* <Form.Control type="date" 
                                id='staff_en_date'
                                min={values.staff_st_date ? values.staff_st_date : undefined} // Set min to staff_st_date if it's defined
                                    value={values.staff_en_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_en_date && touched.staff_en_date ? "input-error" : ""}
                                    
                                /> */}

                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.staff_en_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "staff_en_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_en_date: true });
                                            }}
                                            name="staff_en_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'staff_en_date',
                                                },
                                                }}
                                            className={errors.staff_en_date && touched.staff_en_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs(values.staff_st_date, 'YYYY-MM-DD')}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                {errors.staff_en_date && touched.staff_en_date && <span className="error">{errors.staff_en_date}</span>}
                            </Col>
                        {/* )} */}
                       
                            
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Monthly Wage</h6>
                                <Form.Control
                                    type="number"
                                    id="staff_monthly_wage"
                                    
                                    value={values.staff_monthly_wage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_monthly_wage && touched.staff_monthly_wage ? "input-error" : "mb-0"}
                                />
                                {errors.staff_monthly_wage && touched.staff_monthly_wage && <p className="error">{errors.staff_monthly_wage}</p>}
                            </Col>
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Weekly Wage</h6>
                                <Form.Control
                                    type="number"
                                    id="staff_weekly_wage"
                                    
                                    value={values.staff_weekly_wage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_weekly_wage && touched.staff_weekly_wage ? "input-error" : "mb-0"}
                                />
                                {errors.staff_weekly_wage && touched.staff_weekly_wage && <p className="error">{errors.staff_weekly_wage}</p>}
                            </Col>
                                     
                        </Row>
                    </div>
                </Stack>
                    
                {/* --------------Conditions Details End--------------- */}

                <Stack gap={2} className="mt-4">
                    <div className="text-end">
                        
                        <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                    </div>
                </Stack>
                
        
        </div>
    </Form>
    </>
  );
}

export default EditStaff;
