/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/


import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Stack, Form, Button, Dropdown, DropdownButton, ButtonGroup, Modal } from 'react-bootstrap';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
import PhoneInput from 'react-phone-input-2';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import axios from "axios";
import {
    security_key, options_time_zone,
    options_countries, options_gender, options_days, options_preferedmethodofcontact,
    options_refferalsource, options_campaign, options_status, options_dig_type, options_duration, options_months, BASE_URL
} from "../global";

import './add-student.css';
import { unstable_gridFocusColumnHeaderFilterSelector } from '@mui/x-data-grid';

const phoneNumberRules = {
    'SG': {
      min: 8,
      max: 8,
      message: "Phone number must be exactly 8 digits.",
    }, // Singapore
  
    'AU': {
      min: 9,
      max: 10,
      message: "Phone number must be between 9 and 10 digits.",
    }, // Australia
  
    'GB': {
      min: 10,
      max: 10,
      message: "Phone number must be exactly 10 digits.",
    }, // United Kingdom

    'NZ': {
      min: 8,
      max: 10,
      message: "Phone number must be between 8 and 10 digits.",
    }, // New Zealand
  
    
    'General': {
    min: 5,
    max: 15,
    message: "Phone number must be between 5 to 15 digits.",
  } // general number lenght
  };

function EditStudent() {
    const [options_yearlevel, setYearLevelOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [options_assessor_name, setAssesOptions] = useState([]);

    const [showOtherField, setShowOtherField] = useState(false);
    

    const [passwordVisible, setPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [ConpasswordVisible, setConPasswordVisible] = useState(false);


    const toggleConPasswordVisibility = () => {
        setConPasswordVisible(!ConpasswordVisible);
    };


    const navigate = useNavigate();



    const onGroupContainerClick = useCallback(() => {
        navigate("/list-students");
    }, [navigate]);

   


    const basicSchema = yup.object().shape({
        st_first_name: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_dob: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
         
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),
        // st_gender: yup.mixed().required("Gender is required."),
        // st_dob: yup.date().required("Date of birth is required."),
        // st_year_level: yup.mixed().required("Year level is required."),
        fk_sc_id: yup.string().min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        st_email: yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
            return !value || value && value.includes('.');
          }),
        // st_phone: yup.string().min(7, "Minimum 5 digit required").notRequired(),
        //st_timezone: yup.mixed().required("Timezone is required."),
        st_countryCode: yup.string().notRequired(),
        st_phone: yup
        .string()
        .test(
          'valid-phone-number',
          function (value) {
            const { st_countryCode } = this.parent;
            const countryCodeMap = {
              '44': 'GB',   // UK
              '61': 'AU',   // Australia
              '64': 'NZ',   // New Zealand
              '65': 'SG',   // Singapore
              
            };
      
           
            const countryKey = countryCodeMap[st_countryCode];
      
            
            const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
      
          
            const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${st_countryCode}\\s?`, 'g'), '');
            if (!cleanPhoneNumber ) {
                return true; 
              }
            if (countrySpecificRule) {
              const { min, max, message } = countrySpecificRule;
              const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
              // Check if the phone number length is within the valid range for the country
              if (phoneLength < min || phoneLength > max) {
                return this.createError({ message: message });
              }
            }
      
            return true;  // Validation passes if no errors
          }
        )
        .notRequired(),

        // shs_question: yup.mixed().required("Question is required."),
        // shs_answer: yup.mixed().required("Answer is required."),

        st_is_adultlearner: yup.bool(),

        /*tbl_parent*/
        par_firstname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),
        par_surname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("Lastname is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),

        par_emailid: yup.string().when("st_is_adultlearner", {
            is: false,
            then: () =>
              yup
                .string()
                .email("Please enter a valid email address")
                .notRequired()
                .matches(
                  /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
                  "Input cannot start with a special character"
                )
                .test(
                  "no-dot",
                  "Please enter a valid email address",
                  function (value) {
                    return !value || (value && value.includes("."));
                  }
                ),
          }),
        par_countryCode: yup.string().notRequired(),
        par_phone: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().test('email-or-phone', 'valid-phone-number', function (value) {
                const par_emailid = this.parent.par_emailid;
                
                if (!value && (!par_emailid || !yup.string().email().isValidSync(par_emailid))) {
                  return this.createError({
                    path: 'par_phone',
                    message: 'Email or phone is required.',
                  });
                }
                
                const { par_countryCode } = this.parent;
                const countryCodeMap = {
                    '44': 'GB',   // UK
                    '61': 'AU',   // Australia
                    '64': 'NZ',   // New Zealand
                    '65': 'SG',   // Singapore
                    
                  };
            
                 
                  const countryKey = countryCodeMap[par_countryCode];
            
                  
                  const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
            
                
                  const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${par_countryCode}\\s?`, 'g'), '');
                  if (!cleanPhoneNumber ) {
                    return true; 
                  }
                  if (countrySpecificRule) {
                    const { min, max, message } = countrySpecificRule;
                    const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                    // Check if the phone number length is within the valid range for the country
                    if (phoneLength < min || phoneLength > max && !par_emailid) {
                      return this.createError({ message: message });
                    }
                  }
            
                 
                return true;
              }),
          }),
      

        par_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        par_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
       // par_country: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_zip: yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").notRequired().matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),

        /*tbl_parent_auth*/

        // pa_shp_question: yup.mixed().required("Question is required."),
        // pa_shp_answer: yup.mixed().required("Answer is required."),


        sp_start_ofDay: yup
        .string()
        .nullable()
        .test('is-required', 'At least one select dropdown is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),
    
      sp_start_ofDay_mon: yup
        .string()
        .nullable()
        .test('is-required', 'At least one select dropdown is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),

        lead_pref_tim_contact: yup
            .string()
            .nullable(true)
            .test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is null or a valid time
            return value === undefined || value === null || value === '' || dayjs(value, 'HH:mm:ss').isValid();
            }),
        ls_date: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
        
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),
        /*tbl_lead*/
        // lead_pref_meth_contact: yup.mixed().required("Method of contact is required."), //My point reset date-10
        // lead_pref_tim_contact: yup.mixed().required("Time to contact is required."),
        // lead_ref_source: yup.mixed().required("Referal source is required."),
        // lead_ref_campaign: yup.mixed().required("Referal campaign is required."),
        // ls_date: yup.date().required("Date is required."),
        //ls_status: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        ls_description: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        /*tbl_dynostics*/
        // la_date: yup.date().required("Date is required."),
        //la_duration: yup.mixed().required("Duration is required."),
        // la_assessor: yup.string().required("Assessor is required."),
        la_goals:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
         la_notes:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
         la_outcome:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        // la_type:  yup.mixed().required("Type is required."),
        ps_relationship: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("Relationship is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        }),
        

    });


    useEffect(() => {
        // Make an API request to fetch the options
        
        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            country_id: sessionStorage.getItem('country_code'),
            mode: "getCountryYearLevel"
        })
            .then(response_year => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedyearOptions = response_year.data.data.map(option => ({
                    value: option.activity_level_id,
                    label: option.level_name
                }));
                setYearLevelOptions(formattedyearOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });

        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            center_key: sessionStorage.getItem('key'),
            mode: "getAllassessorData"
        })
            .then(response_asses => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedAssesOptions = response_asses.data.data.map(option => ({
                    value: option.first_name + ' ' + option.last_name,
                    label: option.first_name + ' ' + option.last_name
                }));
                setAssesOptions(formattedAssesOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });
    }, []);

    const [Id, setId] = useState(null);

    //Get key value start
    const location = useLocation();
    useEffect(() => {
        
    const { pk_student_key } = location.state || {};
    const extractedSId = pk_student_key;

        if (extractedSId !== '' && extractedSId !== undefined) {
            fetchData(extractedSId);
            setId(extractedSId);
        }else{
            navigate("/list-students");
        }
    }, [location]);
    //Get key value end
    const [StudentData, setStudentData] = useState({});
    
    const [imageUrl, setImageUrl] = useState('');


      const handleClick_student_view = () => {
        // You can use the 'id' value here for further processing
        navigate(`/view-student`, { state: { pk_student_key: `${Id}` } });
    
      };


      const handleClick_enroll = () => {
        // You can use the 'id' value here for further processing

        navigate(`/student-entrolments`, { state: { pk_student_key: `${Id}` } });

    };
    
    //Api call start
    const [clickedValue, setClickedValue] = useState(1);

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleStudent",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setStudentData(response.data.data[0]);
                    if (response.data.data[0]['st_avatar'] != null && response.data.data[0]['st_avatar'] != '') {



                        const fullImageUrl = `${response.data.data[0]['st_avatar']}`;

                        setImageUrl(fullImageUrl);
                    }
                    
                    setClickedValue(response.data.data[0]['sp_pay_type'] );

                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    //Api call End






    const initialValues = {

        pk_student_key: StudentData ? StudentData.pk_student_key : '',
        pk_lead_key: StudentData ? StudentData.pk_lead_key : '',
        pk_par_key: StudentData ? StudentData.pk_par_key : '',

        /*tbl_student*/
        st_first_name: StudentData ? StudentData.st_first_name : '',
        st_surname: StudentData ? StudentData.st_surname : '',
        st_gender: StudentData ? StudentData.st_gender : '',
        st_dob: StudentData ? StudentData.st_dob : '',
        st_year_level: StudentData ? StudentData.st_year_level : '',
        fk_sc_id: StudentData ? StudentData.fk_sc_id : '',
        st_email: StudentData ? StudentData.st_email : '',
        st_address: StudentData ? StudentData.st_address : '',
        st_city: StudentData ? StudentData.st_city : '',
        st_state: StudentData ? StudentData.st_state : '',
        st_country: StudentData ? StudentData.st_country : '',
        st_zip: StudentData ? StudentData.st_zip : '',
        st_phone: StudentData ? StudentData.st_phone : '',
        st_illnesses: StudentData ? StudentData.st_illnesses : '',
        st_timezone: StudentData ? StudentData.st_timezone != null && StudentData.st_timezone != '' ? StudentData.st_timezone : sessionStorage.getItem('time_zone') : sessionStorage.getItem('time_zone'),
        st_is_adultlearner: StudentData ? StudentData.st_is_adultlearner == 1 ? true : false : false,
        fk_cent_key: StudentData ? StudentData.fk_cent_key : '',
        st_avatar: StudentData ? StudentData.st_avatar : '',

        /*tbl_student_auth*/

        shs_question: StudentData ? StudentData.shs_question : '',
        shs_answer: StudentData ? StudentData.shs_answer : '',

        /*tbl_parent*/
        par_firstname: StudentData ? StudentData.par_firstname : '',
        par_surname: StudentData ? StudentData.par_surname : '',
        par_emailid: StudentData ? StudentData.par_emailid : '',
        par_phone: StudentData ? StudentData.par_phone : '',
        par_address: StudentData ? StudentData.par_address : '',
        par_city: StudentData ? StudentData.par_city : '',
        par_state: StudentData ? StudentData.par_state : '',
        par_country: StudentData ? StudentData.par_country != null && StudentData.par_country != '' ? StudentData.par_country : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG' : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG',
        par_zip: StudentData ? StudentData.par_zip : '',
        st_countryCode:'',
        par_countryCode:'',
        /*tbl_parent_auth*/

        pa_shp_question: StudentData ? StudentData.parent_questions : '',
        pa_shp_answer: StudentData ? StudentData.parent_answer : '',



        /*tbl_lead*/
        lead_pref_meth_contact: StudentData ? StudentData.lead_pref_meth_contact : '', //My point reset date-10
        lead_pref_tim_contact: StudentData ? StudentData.lead_pref_tim_contact : '',
        lead_ref_source: StudentData ? StudentData.lead_ref_source : '',
        lead_ref_campaign: StudentData ? StudentData.lead_ref_campaign : '',
        lead_ref_campaign_others: StudentData ? StudentData.lead_ref_campaign_others : '',
        ls_date: StudentData ? StudentData.ls_date : '',
        ls_status: StudentData ? StudentData.ls_status : '',
        ls_description: StudentData ? StudentData.ls_description : '',

        /*tbl_dynostics*/
        la_date: StudentData ? StudentData.la_date : null,
        la_duration: StudentData ? StudentData.la_duration : '',
        la_assessor: StudentData ? StudentData.la_assessor : '',
        la_goals: StudentData ? StudentData.la_goals : '',
        la_notes: StudentData ? StudentData.la_notes : '',
        la_outcome: StudentData ? StudentData.la_outcome : '',
        la_type: StudentData ? StudentData.la_type : '', // Online or centre
        ps_relationship: StudentData ? StudentData.ps_relationship : '',

        updated_by: sessionStorage.getItem('key'),

        sp_start_ofDay: StudentData ? StudentData.sp_pay_type != '' && StudentData.sp_pay_type != null && StudentData.sp_pay_type==1 ? StudentData.sp_start_ofDay : '' : '',
        sp_start_ofDay_mon: StudentData ? StudentData.sp_pay_type != '' && StudentData.sp_pay_type != null && StudentData.sp_pay_type==2 ? StudentData.sp_start_ofDay : '' : '',
    };

    useEffect(() => {
   
            if (StudentData.lead_ref_campaign === "Others") {
              setShowOtherField(true)
            } else {
                setShowOtherField(false)
            }
        }, [StudentData]); 
    
    // Event handler for the first element
    const handleOnClick1 = () => {
        // Set the clicked value to 1
        setClickedValue(1);
    };

    // Event handler for the second element
    const handleOnClick2 = () => {
        // Set the clicked value to 2
        setClickedValue(2);
    };


    // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);

            
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateStudent",
                    payment_type: clickedValue,
                    ...formValues,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/list-students");
                            setIsSubmitting(false); // Re-enable the submit button

                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    }else if (response.data.data == 4) {
                        toast.error("Year level is not available, Try another option", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        handleScrollAndFocus('st_year_level');
                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to update student. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        setTimeout(function () {
                            setIsSubmitting(false); // Re-enable the submit button
                        }, 3000);
                    }

                } else {

                    toast.error("Failure! Unable to update student. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update student. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const fileInputRef = useRef(null);

    
    const stYearLevelRef = useRef(null);

    const scrollToField = (fieldRef) => {
        if (fieldRef && fieldRef.current) {
            fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            fieldRef.current.focus();
        }
    };

    const handleScrollAndFocus = (fieldId) => {
        switch (fieldId) {
            case 'st_year_level':
                scrollToField(stYearLevelRef);
                break;
            default:
                break;
        }
    };


    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields

    };

    function handelRemove(event) {
        event.preventDefault();
        setImageUrl('');
    }




    const [isSubmittingPass, setIsSubmittingPass] = useState(false);

    const [showModal1, setShowModal1] = useState(false);
    const toggleModal1 = () => setShowModal1(!showModal1);

    //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    const validationSchema1 = yup.object().shape({
        sa_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
        sa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        sa_conform_password: yup
            .string()
            .oneOf([yup.ref("sa_password"), null], "Password is not matching")
            .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

    });



    const initialValues1 = {

        pk_student_key: StudentData ? StudentData.pk_student_key : '',

        /*tbl_center_auth*/
        sa_login_id: StudentData ? StudentData.sa_login_id : '',
        sa_password: StudentData ? StudentData.sa_direct_password : '',
        sa_conform_password: '',
        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
    };

    const formik1 = useFormik({
        initialValues: initialValues1,
        validationSchema: validationSchema1,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmittingPass(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateStudentAuth_new",
                    ...values,
                });

                if (response.data.success === true) {
                    // Reset the form
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Manage Login", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setTimeout(function () {
                            navigate("/list-students");
                            setIsSubmittingPass(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                       

                    } else if (response.data.data === 2) {

                        toast.error("Student username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button

                    } else {
                        toast.error("Failure! Unable to update manage login. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update manage login. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmittingPass(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update manage login. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmittingPass(false); // Re-enable the submit button

            } finally {
                setIsSubmittingPass(false); // Re-enable the submit button

            }

        },
    });

    const handleUsernameBlur = useCallback(async () => {
        const username = formik1.values.sa_login_id;
        // if (username) {
        //     try {
        //         const response = await axios.post(BASE_URL + "API/api_index.php", {
        //             pk_student_key: StudentData.pk_student_key,
        //             userID: username,
        //             security_key: security_key,
        //             mode: "checkStudentUserId",
        //         });

        //         if (response.data.success === true) {
        //             formik1.setFieldTouched("sa_login_id", true, false);
        //             formik1.setFieldError("sa_login_id", "Username is not available");
        //         } else {
        //             formik1.setFieldTouched("sa_login_id", true, false);
        //             formik1.setFieldError("sa_login_id", null);
        //         }
        //     } catch (error) {
        //         console.error("Error checking username:", error);
        //         formik1.setFieldError("sa_login_id", null);
        //     }
        // }
    }, [formik1.values.sa_login_id]);


    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);
     

    return (

        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Modal show={showModal1} onHide={toggleModal1} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Login</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik1.handleSubmit}>
                    <Modal.Body>

                        {/* --------------Date--------------- */}

                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Student App Login</h6>
                        </div>
                        <Row>


                            {/* --------------Student Username--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Username</h6>
                                <Form.Control type="text"
                                    name="sa_login_id"
                                    // readOnly
                                    autoComplete="off"

                                    value={formik1.values.sa_login_id}
                                    onChange={formik1.handleChange}
                                    // onBlur={handleBlur}
                                    id="sa_login_id"
                                    onBlur={formik1.handleBlur}
                                    className={formik1.errors.sa_login_id && formik1.touched.sa_login_id ? "input-error" : ""}
                                />
                                {formik1.errors.sa_login_id && formik1.touched.sa_login_id && <p className="error">{formik1.errors.sa_login_id}</p>}
                            </Col>

                            {/* --------------Student Password--------------- */}


                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control type={passwordVisible ? "text" : "password"}
                                   autoComplete="new-password"

                                    value={formik1.values.sa_password}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    id="sa_password"
                                    className={formik1.errors.sa_password && formik1.touched.sa_password ? "input-error" : "pswicon-wrap"}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik1.errors.sa_password && formik1.touched.sa_password && <p className="error">{formik1.errors.sa_password}</p>}
                            </Col>


                            {/* --------------Student Confirm Password--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control type={ConpasswordVisible ? "text" : "password"}
                                    value={formik1.values.sa_conform_password}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    id="sa_conform_password"
                                    autoComplete="new-password"
                                    className={formik1.errors.sa_conform_password && formik1.touched.sa_conform_password ? "input-error" : ""}
                                />
                                <button
                                    type="button"
                                    onClick={toggleConPasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik1.errors.sa_conform_password && formik1.touched.sa_conform_password && <p className="error">{formik1.errors.sa_conform_password}</p>}
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>

                        <div className="mx-auto d-flex gap-2" >

                            <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                            <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass}>Submit</Button>

                        </div>

                    </Modal.Footer>
                </Form>
            </Modal>
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Edit Student </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>

                                    <Typography color="text.primary">Student</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>

                        <div>
                            {/* <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button> */}

                            <div className='d-flex justify-content-end gap-2'>
                                <div >
                                <Button variant="contained"  className="primary-filled" style={{ fontSize: '12px' }} onClick={() => toggleModal1()} > Manage Login</Button>
                                    {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                        <Dropdown.Item onClick={() => toggleModal1()}>  Manage Login
                                        </Dropdown.Item>


                                    </DropdownButton> */}
                                </div>
                                <div >
                                    <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1"  className="ps-0 rounded-10 primary-filled btn-action text-light">
                                    <Dropdown.Item eventKey="2" onClick={handleClick_student_view}>Student Particulars</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={handleClick_enroll}>Student Enrolments</Dropdown.Item>
                                    <Dropdown.Item eventKey="3"><NavLink to='/list-students' >Student List</NavLink></Dropdown.Item>

                                    </DropdownButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* --------------Student Particulars--------------- */}

                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Student Particulars </h6>
                            </div>
                            <Row>

                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_first_name"
                                        className={errors.st_first_name && touched.st_first_name ? "input-error" : ""}
                                    />
                                    {errors.st_first_name && touched.st_first_name && <p className="error">{errors.st_first_name}</p>}
                                </Col>

                                {/* --------------Student SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_surname"
                                        className={errors.st_surname && touched.st_surname ? "input-error" : ""}
                                    />
                                    {errors.st_surname && touched.st_surname && <p className="error">{errors.st_surname}</p>}
                                </Col>


                                {/* --------------Student Gender--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Gender</h6>
                                     <Select menuPlacement="auto"  options={options_gender}
                                        name="st_gender"
                                        value={options_gender.find(option => option.value && values.st_gender && option.value.toString() === values.st_gender.toString())}

                                        id="st_gender"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_gender",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_gender: true });
                                        }}
                                        className={errors.st_gender && touched.st_gender ? "input-error" : ""}
                                    />
                                    {errors.st_gender && touched.st_gender && <p className="error">{errors.st_gender}</p>}
                                </Col>

                                {/* --------------Student Date of Birth--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Date of Birth</h6>
                                    {/* <Form.Control
                                        type="date"

                                        max={new Date().toISOString().split("T")[0]}
                                        value={values.st_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_dob"
                                        className={errors.st_dob && touched.st_dob ? "input-error" : ""}
                                    /> */}

                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.st_dob, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                
                                                const event = {
                                                target: {
                                                    name: "st_dob",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, st_dob: true });
                                            }}
                                            name="st_dob"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'st_dob',
                                                },
                                                }}
                                            className={errors.st_dob && touched.st_dob ? "input-error w-100" : "w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.st_dob && touched.st_dob && <span className="error">{errors.st_dob}</span>}
                                </Col>


                                {/* --------------Student Year Level--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Year Level</h6>
                                     <Select menuPlacement="auto"  options={options_yearlevel}
                                        name="st_year_level"
                                        value={options_yearlevel.find(option => option.value && values.st_year_level && option.value.toString() === values.st_year_level.toString())}

                                        id="st_year_level"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_year_level",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_year_level: true });
                                        }}
                                        className={errors.st_year_level && touched.st_year_level ? "input-error" : ""}
                                        ref={stYearLevelRef}
                                    />
                                    {errors.st_year_level && touched.st_year_level && <p className="error">{errors.st_year_level}</p>}
                                </Col>

                                {/* --------------Student School--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">School</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.fk_sc_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fk_sc_id"
                                        className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""}
                                    />
                                    {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">School</h6>
                                     <Select menuPlacement="auto"  options={options_school}
                                     name="fk_sc_id"
                                     value={options_school.find(option => option.value && values.fk_sc_id && option.value.toString() === values.fk_sc_id.toString())}
                                     
                                     id="fk_sc_id"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "fk_sc_id",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, fk_sc_id: true });
                                     }}
                                     className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""} />
                                      {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col> */}

                                {/* --------------Student Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Email <a href={`mailto:${values.st_email !== '' ? values.st_email : 'userID@example.com'}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a></h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_email"
                                        className={errors.st_email && touched.st_email ? "input-error" : ""}
                                    />
                                    {errors.st_email && touched.st_email && <p className="error">{errors.st_email}</p>}
                                </Col>



                                {/* --------------Student city--------------- */}
                                {/*                             
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control type="text" 
                                    value={values.st_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_city"
                                    className={errors.st_city && touched.st_city ? "input-error" : ""}
                                     />
                                    {errors.st_city && touched.st_city && <p className="error">{errors.st_city}</p>}
                                </Col> */}

                                {/* --------------Student state--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text" 
                                     value={values.st_state}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_state"
                                     className={errors.st_state && touched.st_state ? "input-error" : ""} />
                                      {errors.st_state && touched.st_state && <p className="error">{errors.st_state}</p>}
                                </Col> */}

                                {/* --------------Student Country--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                    name="st_country"
                                    value={options_countries.find(option => option.value && values.st_country && option.value.toString() === values.st_country.toString())}
                                    
                                    id="st_country"
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "st_country",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, st_country: true });
                                    }}
                                    className={errors.st_country && touched.st_country ? "input-error" : ""}
                                     />
                                     {errors.st_country && touched.st_country && <p className="error">{errors.st_country}</p>}
                                </Col> */}


                                {/* --------------Student Postal Code--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text" 
                                     value={values.st_zip}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_zip"
                                     className={errors.st_zip && touched.st_zip ? "input-error" : ""}
                                    />
                                    {errors.st_zip && touched.st_zip && <p className="error">{errors.st_zip}</p>}
                                </Col> */}



                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number <a href={`https://wa.me/${values ? values.st_phone != '' && values.st_phone != null ? values.st_phone : '' : ''}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a></h6>
                                    {/* <Form.Control type="number" 
                                     value={values.st_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_phone"
                                     className={errors.st_phone && touched.st_phone ? "input-error" : ""}
                                      />
                                       {errors.st_phone && touched.st_phone && <p className="error">{errors.st_phone}</p>} */}
                                    <PhoneInput
                                        name="st_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.st_phone}
                                        // onChange={(value) => {
                                        //     const event = {
                                        //         target: {
                                        //             name: "st_phone",
                                        //             value: value,
                                        //         },
                                        //     };
                                        //     handleChange(event);
                                        // }}
                                        onChange={(phone, country) => 
                                        
                                            setValues({
                                            ...values,
                                            st_phone: phone,
                                            st_countryCode: country.dialCode
                                            })
                                        }
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_phone: true });
                                        }}
                                        inputProps={{id: 'st_phone'}}
                                        id="st_phone"
                                        inputClass={errors.st_phone && touched.st_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.st_phone && touched.st_phone && <span className="error">{errors.st_phone}</span>}
                                </Col>


                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Photo</h6>
                                    {!imageUrl && (<Form.Control type="file"
                                        name="st_avatar"
                                        id="st_avatar"
                                        accept=".png, .jpeg, .jpg, .jfif"
                                        onChange={(event) => {
                                            setTouched({ ...touched, st_avatar: true });
                                            if (event.target.files.length > 0) {
                                                const fileName = event.target.files[0];
                                                async function handleFileUpload(fileName) {
                                                    try {
                                                        // Create a FormData object to send the form data, including the image file
                                                        const formData = new FormData();
                                                        formData.append("mode", "file_s3_upload_image");
                                                        formData.append("user", "student");
                                                        formData.append("image", fileName); // Append the image file

                                                        // Make an API request here with the FormData object
                                                        const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                                                        // Handle the API response here
                                                        if (response_file.data.success === true) {
                                                          
                                                            handleChange({ target: { name: "st_avatar", value: response_file.data.data } });
                                                        } else {
                                                            handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                            fileInputRef.current.value = null;
                                                        }

                                                        // You can also perform further actions based on the API response
                                                        // For example, show a success message or redirect to another page.
                                                    } catch (error) {
                                                     
                                                        handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                        fileInputRef.current.value = null;
                                                    }
                                                }

                                                // Call the async function when needed, passing the fileName as an argument
                                                handleFileUpload(fileName);


                                            } else {
                                                // handleChange(event);
                                                
                                                handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                fileInputRef.current.value = null;
                                            }
                                        }}
                                        ref={fileInputRef}
                                        className={errors.st_avatar && touched.st_avatar ? "input-error" : ""}
                                    />

                                    )}
                                    {imageUrl && (
                                        <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                            <img src={imageUrl} alt='image' width="50" height="50" />
                                            <div className="text-end">
                                                <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                                            </div>
                                        </Col>
                                    )}
                                    {errors.st_avatar && touched.st_avatar && <p className="error">{errors.st_avatar}</p>}
                                </Col>
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Are There Any Illnesses Or Disabilities You Feel We Should Know About?</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.st_illnesses}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_illnesses"
                                        className={errors.st_illnesses && touched.st_illnesses ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.st_illnesses && touched.st_illnesses && <p className="error">{errors.st_illnesses}</p>}
                                </Col>







                                {/* --------------Contact Checkbox--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        label="Adult Learner"
                                        name="st_is_adultlearner"
                                        id="st_is_adultlearner"
                                        checked={values.st_is_adultlearner}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='mt-1'
                                    />
                                    {errors.st_is_adultlearner && touched.st_is_adultlearner && <p className="error">{errors.st_is_adultlearner}</p>}
                                </Col>

                                {/* --------------Contact Address1--------------- */}
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control as="textarea" 
                                    rows={3} 
                                    value={values.st_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_address"
                                    className={errors.st_address && touched.st_address ? "input-error control_textarea" : "control_textarea"}
                                     />
                                      {errors.st_address && touched.st_address && <p className="error">{errors.st_address}</p>}
                                </Col> */}

                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Student Particulars End--------------- */}

                    {/* --------------student Login Details--------------- */}

                    <Stack className='mt-4' style={{ display: 'none' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Student Secret Question & Answer</h6>
                            </div>

                            <Row>


                                {/* --------------Student Secret Question--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Secret Question</h6>
                                    <Form.Control type="text"
                                        value={values.shs_question}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="shs_question"
                                        className={errors.shs_question && touched.shs_question ? "input-error" : ""}
                                    />
                                    {errors.shs_question && touched.shs_question && <p className="error">{errors.shs_question}</p>}
                                </Col>

                                {/* --------------Student Secrect Answer--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Secret Answer</h6>
                                    <Form.Control type="text"
                                        value={values.shs_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="shs_answer"
                                        className={errors.shs_answer && touched.shs_answer ? "input-error" : ""}
                                    />
                                    {errors.shs_answer && touched.shs_answer && <p className="error">{errors.shs_answer}</p>}
                                </Col>



                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-4' style={{ display: formik.values.st_is_adultlearner != null && formik.values.st_is_adultlearner === true ? 'none' : 'block' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Parents / Guardians Details </h6>
                            </div>

                            <Row>



                                {/* --------------Student Parents / Guardians --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">relationship</h6>
                                    <Form.Control type="text"
                                        value={values.ps_relationship}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ps_relationship"
                                        className={errors.ps_relationship && touched.ps_relationship ? "input-error" : ""}
                                    />
                                    {errors.ps_relationship && touched.ps_relationship && <p className="error">{errors.ps_relationship}</p>}
                                </Col>


                                {/* --------------Contact First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_firstname"
                                        className={errors.par_firstname && touched.par_firstname ? "input-error" : ""} />
                                    {errors.par_firstname && touched.par_firstname && <p className="error">{errors.par_firstname}</p>}
                                </Col>

                                {/* --------------Contact SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_surname"
                                        className={errors.par_surname && touched.par_surname ? "input-error" : ""} />
                                    {errors.par_surname && touched.par_surname && <p className="error">{errors.par_surname}</p>}
                                </Col>

                                {/* --------------Contact Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Email <a href={`mailto:${values.par_emailid !== '' ? values.par_emailid : 'userID@example.com'}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a>
                                    {values.st_email && values.st_email!='' ? 
                                        ['top'].map((placement) => (
                                            <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                                    Paste student email
                                                </Tooltip>
                                            }
                                            >
                                                {/* <Button variant="secondary" className="no-bdr"> */}
                                                    <i class="fa-solid fa-paste" style={{ color: "black"}} onClick={() => handleChange({
                                                        target: { name: "par_emailid", value: values.st_email },
                                                    })}></i>
                                                {/* </Button> */}
                                            </OverlayTrigger>   
                                        ))
                                        : null
                                    }
                                    </h6>
                                    <Form.Control type="text"
                                        value={values.par_emailid}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_emailid"
                                        className={errors.par_emailid && touched.par_emailid ? "input-error" : ""}
                                    />
                                    {errors.par_emailid && touched.par_emailid && <p className="error">{errors.par_emailid}</p>}
                                </Col>

                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number  <a href={`https://wa.me/${values ? values.par_phone != '' && values.par_phone != null ? values.par_phone : '' : ''}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a>
                                    {values.st_phone && values.st_phone!='' ?
                                        ['top'].map((placement) => (
                                            <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                                Paste student contact number
                                                </Tooltip>
                                            }
                                            >
                                                {/* <Button variant="secondary" className="no-bdr"> */}
                                                    <i class="fa-solid fa-paste" style={{ color: "black"}} onClick={() => handleChange({
                                                        target: { name: "par_phone", value: values.st_phone },
                                                    }) }></i>
                                                {/* </Button> */}
                                            </OverlayTrigger>   
                                        ))
                                        : null
                                    }
                                    </h6>
                                    {/* <Form.Control type="number" 
                                     value={values.par_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="par_phone"
                                     className={errors.par_phone && touched.par_phone ? "input-error" : ""} 
                                      />
                                    {errors.par_phone && touched.par_phone && <p className="error">{errors.par_phone}</p>} */}
                                    <PhoneInput
                                        name="par_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.par_phone}
                                        // onChange={(value) => {
                                        //     const event = {
                                        //         target: {
                                        //             name: "par_phone",
                                        //             value: value,
                                        //         },
                                        //     };
                                        //     handleChange(event);
                                        // }}

                                        onChange={(phone, country) => 
                                        
                                            setValues({
                                            ...values,
                                            par_phone: phone,
                                            par_countryCode: country.dialCode
                                            })
                                        }
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_phone: true });
                                        }}
                                        inputProps={{id: 'par_phone'}}
                                        id="par_phone"
                                        inputClass={errors.par_phone && touched.par_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.par_phone && touched.par_phone && <span className="error">{errors.par_phone}</span>}
                                </Col>





                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Contact Details End--------------- */}


                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-4' style={{ display: 'none' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Parents / Guardians Secret Question & Answer </h6>
                            </div>

                            <Row>

                                {/* --------------Student Secret Question--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Secret Question</h6>
                                    <Form.Control type="text"
                                        value={values.pa_shp_question}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_shp_question"
                                        className={errors.pa_shp_question && touched.pa_shp_question ? "input-error" : ""}
                                    />
                                    {errors.pa_shp_question && touched.pa_shp_question && <p className="error">{errors.pa_shp_question}</p>}
                                </Col>

                                {/* --------------Student Secrect Answer--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Secret Answer</h6>
                                    <Form.Control type="text"
                                        value={values.pa_shp_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_shp_answer"
                                        className={errors.pa_shp_answer && touched.pa_shp_answer ? "input-error" : ""}
                                    />
                                    {errors.pa_shp_answer && touched.pa_shp_answer && <p className="error">{errors.pa_shp_answer}</p>}
                                </Col>

                            </Row>
                        </div>
                    </Stack>

                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>
                            <Row>

                                {/* --------------Contact Address1--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Address</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.par_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_address"
                                        className={errors.par_address && touched.par_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.par_address && touched.par_address && <p className="error">{errors.par_address}</p>}
                                </Col>
                                {/* --------------Student city--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">City/Town</h6>
                                    <Form.Control type="text"
                                        value={values.par_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_city"
                                        className={errors.par_city && touched.par_city ? "input-error" : ""}
                                    />
                                    {errors.par_city && touched.par_city && <p className="error">{errors.par_city}</p>}
                                </Col>

                                {/* --------------Student state--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">County/Region/State</h6>
                                    <Form.Control type="text"
                                        value={values.par_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_state"
                                        className={errors.par_state && touched.par_state ? "input-error" : ""}
                                    />
                                    {errors.par_state && touched.par_state && <p className="error">{errors.par_state}</p>}
                                </Col>

                                {/* --------------Student Country--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                        name="par_country"
                                        value={options_countries.find(option => option.value && values.par_country && option.value.toString() === values.par_country.toString())}

                                        id="par_country"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "par_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_country: true });
                                        }}
                                        className={errors.par_country && touched.par_country ? "input-error" : ""}
                                    />
                                    {errors.par_country && touched.par_country && <p className="error">{errors.par_country}</p>}
                                </Col>


                                {/* --------------Student Postal Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Postal Code</h6>
                                    <Form.Control type="text"
                                        value={values.par_zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_zip"
                                        className={errors.par_zip && touched.par_zip ? "input-error" : ""}
                                    />
                                    {errors.par_zip && touched.par_zip && <p className="error">{errors.par_zip}</p>}
                                </Col>


                                {/* -------------- How did you hear about us?--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">How did you hear about us?</h6>
                                     <Select menuPlacement="auto"  options={options_campaign}
                                        name="lead_ref_campaign"
                                        value={options_campaign.find(option => option.value && values.lead_ref_campaign && option.value.toString() === values.lead_ref_campaign.toString())}

                                        id="lead_ref_campaign"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_ref_campaign",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_campaign: true });
                                        }}
                                        className={errors.lead_ref_campaign && touched.lead_ref_campaign ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_campaign && touched.lead_ref_campaign && <p className="error">{errors.lead_ref_campaign}</p>}
                                </Col> */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">How did you hear about us?</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_campaign}
                    name="lead_ref_campaign"
                    value={options_campaign.find(
                      (option) =>
                        option.value &&
                        values.lead_ref_campaign &&
                        option.value.toString() ===
                          values.lead_ref_campaign.toString() 
                    )  }
                    
                      
                    id="lead_ref_campaign"
                    onChange={(selectedOption) => {
                      const value = selectedOption["value"];
                     
                      if (value === "Others") {
                        setShowOtherField(true);
                      
                        const event = {
                            target: {
                              name: "lead_ref_campaign",
                              value: selectedOption["value"],
                            },
                          };
  
                          handleChange(event);
                    } else {
                        setShowOtherField(false);
                        const event = {
                          target: {
                            name: "lead_ref_campaign",
                            value: selectedOption["value"],
                          },
                        };
                            handleChange(event);
                     const resetEvent = {
                                target: {
                                  name: "lead_ref_campaign_others",
                                  value: null,
                                },
                              };
                      
                              handleChange(resetEvent);
                      }
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, lead_ref_campaign: true });
                    }}
                    className={
                      errors.lead_ref_campaign && touched.lead_ref_campaign
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.lead_ref_campaign && touched.lead_ref_campaign && (
                    <p className="error">{errors.lead_ref_campaign}</p>
                  )}
                </Col>
                {showOtherField && (
                  <Col lg="4" md="6" sm="12" className="position-relative">
                    <h6 className="form-lable">Please specify</h6>
                    <Form.Control
                      type="text"
                      id="lead_ref_campaign_others"
                      name="lead_ref_campaign_others"
                      value={values.lead_ref_campaign_others }
                      onChange={handleChange}
                      onBlur={() =>
                        setTouched({ ...touched, lead_ref_campaign_others: true })
                      }
                      className={
                        errors.lead_ref_campaign_others && touched.lead_ref_campaign_others
                          ? "input-error"
                          : ""
                      }
                    />
                    {errors.lead_ref_campaign_others && touched.lead_ref_campaign_others && (
                      <p className="error">{errors.lead_ref_campaign_others}</p>
                    )}
                  </Col>
                )}
                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable required-field">Timezone</h6>
                                     <Select menuPlacement="auto"  options={options_time_zone}
                                        name="st_timezone"

                                        value={options_time_zone.find(option => option.value && values.st_timezone && option.value.toString() === values.st_timezone.toString())}
                                        isDisabled={true}
                                        id="st_timezone"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_timezone: true });
                                        }}
                                        className={errors.st_timezone && touched.st_timezone ? "input-error" : ""}
                                    />
                                    {errors.st_timezone && touched.st_timezone && <p className="error">{errors.st_timezone}</p>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Lead Details--------------- */}

                    <Stack className='mt-4' >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Lead Details </h6>
                            </div>

                            <Row>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable"> Preferred Method Of Contact</h6>
                                     <Select menuPlacement="auto"  options={options_preferedmethodofcontact}
                                        name="lead_pref_meth_contact"
                                        value={options_preferedmethodofcontact.find(option => option.value && values.lead_pref_meth_contact && option.value.toString() === values.lead_pref_meth_contact.toString())}

                                        id="lead_pref_meth_contact"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_pref_meth_contact",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_meth_contact: true });
                                        }}
                                        className={errors.lead_pref_meth_contact && touched.lead_pref_meth_contact ? "input-error" : ""}
                                    />
                                    {errors.lead_pref_meth_contact && touched.lead_pref_meth_contact && <p className="error">{errors.lead_pref_meth_contact}</p>}

                                </Col>

                                {/* --------------Details Preferred  Time Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable"> Preferred Time To Contact</h6>
                                    <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                        <TimePicker 
                                        value={dayjs(values.lead_pref_tim_contact, 'HH:mm:ss')}
                                        onChange={(time) =>  {
                                        const event = {
                                        target: {
                                            name: "lead_pref_tim_contact",
                                            value: time != null ? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                        },
                                        };
                                        
                                        handleChange(event);
                                        
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_tim_contact: true });
                                        }}
                                        slotProps={{
                                            textField: { readOnly: true, size: 'small',
                                            id: 'lead_pref_tim_contact',
                                            },
                                        }}
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                        />
                                    
                                    </LocalizationProvider>
                                    {errors.lead_pref_tim_contact && touched.lead_pref_tim_contact && <span className="error">{errors.lead_pref_tim_contact}</span>}
                                </Col>


                                {/* --------------Details Referral Source--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Referral Source</h6>
                                     <Select menuPlacement="auto"  options={options_refferalsource}
                                        name="lead_ref_source"
                                        value={options_refferalsource.find(option => option.value && values.lead_ref_source && option.value.toString() === values.lead_ref_source.toString())}

                                        id="lead_ref_source"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_ref_source",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_source: true });
                                        }}
                                        className={errors.lead_ref_source && touched.lead_ref_source ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_source && touched.lead_ref_source && <p className="error">{errors.lead_ref_source}</p>}
                                </Col>


                                {/* -------------- Details Status --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status Date</h6>
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.ls_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "ls_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, ls_date: true });
                                            }}
                                            name="ls_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'ls_date',
                                                },
                                                }}
                                            className={errors.ls_date && touched.ls_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.ls_date && touched.ls_date && <span className="error">{errors.ls_date}</span>}
                                </Col>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status</h6>
                                     <Select menuPlacement="auto"  options={options_status}
                                        name="ls_status"
                                        value={options_status.find(option => option.value && values.ls_status && option.value.toString() === values.ls_status.toString())}

                                        id="ls_status"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ls_status",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ls_status: true });
                                        }}
                                        className={errors.ls_status && touched.ls_status ? "input-error" : ""}

                                    />
                                    {errors.ls_status && touched.ls_status && <p className="error">{errors.ls_status}</p>}

                                </Col>
                                {/* --------------description--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status Description</h6>
                                    <Form.Control
                                        as="textarea" rows={3}

                                        value={values.ls_description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_description"
                                        className={errors.ls_description && touched.ls_description ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.ls_description && touched.ls_description && <p className="error">{errors.ls_description}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Additional Details--------------- */}
                    <Stack className='mt-4' >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Initial Diagnostics Details </h6>
                            </div>

                            <Row>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Initial Diagnostics Type</h6>
                                     <Select menuPlacement="auto"  options={options_dig_type}
                                        name="la_type"
                                        value={options_dig_type.find(option => option.value && values.la_type && option.value.toString() === values.la_type.toString())}

                                        id="la_type"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_type: true });
                                        }}
                                        className={errors.la_type && touched.la_type ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_type && touched.la_type && <p className="error">{errors.la_type}</p>}
                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Assessor Name</h6>
                                     <Select menuPlacement="auto"  options={options_assessor_name}
                                        name="la_assessor"
                                        value={options_assessor_name.find(option => option.value && values.la_assessor && option.value.toString() === values.la_assessor.toString())}

                                        id="la_assessor"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_assessor",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_assessor: true });
                                        }}
                                        className={errors.la_assessor && touched.la_assessor ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_assessor && touched.la_assessor && <p className="error">{errors.la_assessor}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Assessor Name</h6>
                                    <Form.Control type="text" 
                                    value={values.la_assessor}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="la_assessor"
                                    className={errors.la_assessor && touched.la_assessor ? "input-error" : ""}
                                     />
                                     {errors.la_assessor && touched.la_assessor && <p className="error">{errors.la_assessor}</p>}
                                </Col> */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Test Duration</h6>
                                     <Select menuPlacement="auto"  options={options_duration}
                                        name="la_duration"
                                        value={options_duration.find(option => option.value && values.la_duration && option.value.toString() == values.la_duration.toString())}

                                        id="la_duration"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_duration",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_duration: true });
                                        }}
                                        className={errors.la_duration && touched.la_duration ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_duration && touched.la_duration && <p className="error">{errors.la_duration}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Result Outcome</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_outcome}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_outcome"
                                        className={errors.la_outcome && touched.la_outcome ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.la_outcome && touched.la_outcome && <p className="error">{errors.la_outcome}</p>}
                                </Col>

                                {/* --------------Additional Goal--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">What is your goal</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_goals}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_goals"
                                        className={errors.la_goals && touched.la_goals ? "input-error control_textarea" : "control_textareamb-0"} />
                                    {errors.la_goals && touched.la_goals && <p className="error">{errors.la_goals}</p>}
                                </Col>

                                {/* --------------Additional Notes--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Initial Diagnostics Notes</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_notes"
                                        className={errors.la_notes && touched.la_notes ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.la_notes && touched.la_notes && <p className="error">{errors.la_notes}</p>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Additional Details End--------------- */}


                    {/* --------------Payment Details--------------- */}
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Payment </h6>
                            </div>
                            {/* <Tab.Container defaultActiveKey={StudentData.sp_pay_type === '2' ? 'second' : 'first'}> */}
                            <Tab.Container defaultActiveKey={"first"} activeKey={clickedValue}  onSelect={(k) => setClickedValue(k)} >
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row justify-content-center mb-3 text-dark outside mx-auto">
                                            <Nav.Item>
                                                <Nav.Link eventKey="1" className="px-4 py-2 text-dark me-2" >Weekly</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="2" className="px-4 py-2 text-dark"  >Monthly</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="1">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className='required-field'>Select day of Week</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_days}
                                                                name="sp_start_ofDay"
                                                                value={options_days.find(option => option.value && values.sp_start_ofDay && option.value.toString() === values.sp_start_ofDay.toString())}
                                                                id="sp_start_ofDay"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay: true });
                                                                }}
                                                                className={errors.sp_start_ofDay && touched.sp_start_ofDay ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay && touched.sp_start_ofDay && <span className="error">{errors.sp_start_ofDay}</span>}

                                                            <Col xs={12} md={4}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="2">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className='required-field'>Select day of Month</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_months}
                                                                name="sp_start_ofDay_mon"
                                                                value={options_months.find(option => option.value && values.sp_start_ofDay_mon && option.value.toString() === values.sp_start_ofDay_mon.toString())}
                                                                id="sp_start_ofDay_mon"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay_mon",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay_mon: true });
                                                                }}
                                                                className={errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon && <span className="error">{errors.sp_start_ofDay_mon}</span>}

                                                            <Col xs={12} md={8}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                        </div>
                    </Stack>


                    {/* --------------Payment Details End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={onGroupContainerClick}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>

                </div>

            </Form>
        </>
    );
}

export default EditStudent;