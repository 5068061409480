import { useState, useRef, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { format, parse } from "date-fns";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
//import Moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";

import InfoIcon from "@mui/icons-material/Info";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PersonIcon from "@mui/icons-material/Person";
//import { INITIAL_EVENTS, createEventId } from './event-utils'
import { Row, Col, Card, Form, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import Moment from "moment";
import Badge from "react-bootstrap/Badge";

import ListAltIcon from "@mui/icons-material/ListAlt";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";

import * as bootstrap from "bootstrap";
import { options_attendance_status, security_key, BASE_URL } from "../global";
import Spinner from "react-bootstrap/Spinner";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import "bootstrap/dist/css/bootstrap.min.css";
import "moment-timezone";
import { DateCalendar } from "@mui/x-date-pickers";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const basicSchema = yup.object().shape({
  nt_notes: yup.string().required("Please enter a note to save it."),
});

export default function BasicTabs({ isOpen, toggleSidebar }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isdeferSubmitting, setIsDeferSubmitting] = useState(false);
  const [isnoteDelSubmitting, setIsnoteDelSubmitting] = useState(false);
  const [isforfeitSubmitting, setIsForfeitSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);

  const [modalShow2, setModalShow2] = useState(false);
  //event popup
  const [showModal_event, setShowModal_event] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [showForfeit, setForfeitShow] = useState(false);
  const [showDefer, setDeferShow] = useState(false);

  const [data_forfeit, setForfeitLabelData] = useState([]);
  const [data_defercancel, setDeferLabelData] = useState([]);
  const [data_tutsess, setTutSessDetailsData] = useState([]);

  //Reassign Start
  const [ismsSubmitting, setIsmsSubmitting] = useState(false);
  const [isMSforfeitSubmitting, setIsMSForfeitSubmitting] = useState(false);
  const [showMSForfeit, setMSForfeitShow] = useState(false);
  const [dataNewEvent, setNewEventData] = useState([]);
  const [data_MS, setMSDetailsData] = useState([]);
  const [isSetMS, setIsSetMs] = useState(false);
  //Reassign End

  // status start
  const [editStatus, setEditStatusData] = useState([]);
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const handleEditClick = (row) => {
    const updatedEditData = { ...row };
    setEditStatusData(updatedEditData);
    setModalShowEdit(true);
  };
  //status end

  //Defer Requert start
  const [recordsDR, setDRRecords] = useState([]);
  const [data_DR, setDRData] = useState([]);
  const [showDeferRequest, setDeferRequestShow] = useState(false);
  const [isdeferRequestSubmitting, setIsDeferRequestSubmitting] =
    useState(false);
  const [recordsDRDisApproved, setDRDisApprovedRecords] = useState([]);
  const [showDeferRequestDisApprove, setDeferRequestDisApproveShow] =
    useState(false);
  const [
    isdeferRequestDisApproveSubmitting,
    setIsDeferRequestDisApproveSubmitting,
  ] = useState(false);

  //Defer Requert End

  //Edit Date/time start
  const [showEditDT, setEditDTShow] = useState(false);
  const [EventData, setEditData] = useState([]);
  const [iSEditDTSubmitting, setIsEditDTSubmitting] = useState(false);
  //Edit Date/time End

  //Tutor Start
  const [options_tutor, setOptions] = useState([]);
  //Tutor End

  //Reenrolled start

  const [SelectStudentDetail, setSelectStudentDetail] = useState([]);
  const [ReEnrollRecords, setReEnrollRecords] = useState([]);
  const [showReenrol, setshowReenrol] = useState(false);
  const toggleReenrol = () => {
    setshowReenrol(!showReenrol);
  };
  const [ReEnrolSelectSession, setReEnrolSelectSession] = useState(null);
  const [ReEnrolConfirm, setReEnrolConfirm] = useState(false);
  const [isReEnrolSubmitting, setReEnrolSubmitting] = useState(false);

  //Reenrolled end

  //event popup END
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Api call start
  const [data, setItems] = useState([]);

  const [records, setRecords] = useState(data);

  const [recordsNotes, setNotesRecords] = useState(data);
  const [recordsMS, setMSRecords] = useState(data);
  const [ophdata, setOPHRecords] = useState([]);

  const [checkOP, setcheckOP] = useState("2");
  const [hiddenDays, sethiddenDays] = useState([""]);

  const [isRightOpen, setIsRightOpen] = useState(false);

  const [IsShowModalGuide, setShowModalGuide] = useState(false);
  const handleUserGuide = () => {
    setShowModalGuide(true);
  };

  const [IsShowModalGuide2, setShowModalGuide2] = useState(false);
  const handleUserGuide2 = () => {
    setShowModalGuide2(true);
  };

  const location = useLocation();
  useEffect(() => {
    const checkAdminStatus = async () => {
      const isSuccess = sessionStorage.getItem("isSuccess");
      if (isSuccess !== null && isSuccess === "1") {
        toast.success("You have successfully logged into your account.", {
          autoClose: 1000, // 3 seconds in this example
        });
        sessionStorage.setItem("isSuccess", "0");
      } else {
      }
    };

    checkAdminStatus();
  }, [location]);
  const navigate = useNavigate();
  const handleClick_slot = (e) => {
    // You can use the 'id' value here for further processing

    navigate(`/ListSlot`, {
      state: {
        cancel_session_key: `${e.cancel_session_key}`,
        fk_student_key: `${e.fk_student_key}`,
        pk_enrol_key: `${e.pk_enrol_key}`,
        st_first_name: `${e.st_first_name}`,
        st_surname: `${e.st_surname}`,
        subject_name: `${e.subject_name}`,
        fk_ses_key: `${e.fk_ses_key}`,
      },
    });
  };
  const handleMessageClick = async () => {
    toast.error("Sorry, this status cannot be changed.", {
      autoClose: 3000, // 3 seconds in this example
    });
  };

  const eventCategories = {
    work: "Work",
    personal: "Personal",
    social: "Social",
  };

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [currentView, setCurrentView] = useState("timeGridWeek"); // Set the initial view
  const [currentViewdata, setCurrentViewdata] = useState([]); // Set the initial view
  const [lessonCount, setlessonCount] = useState(0);
  const [CalfromDate, setCalFromDate] = useState(null);
  const [CaltoDate, setCalToDate] = useState(null);

  useEffect(() => {
    //fetchData('2');
    fetchNotesData();
    fetchMSData();
    fetchOPHData();
    fetchDRData();
    fetchDRDisApprovedData();

    fetchTutorData();
  }, []);

  useEffect(() => {
    //  const calendarEl = document.querySelector('.fc');
    const calendarhead = document.querySelector(".fc-col-header");
    const calendarElbody = document.querySelector(
      ".fc-scrollgrid-liquid tbody"
    );
    const calendarEltimebody = document.querySelector(".fc-timegrid-body");
    const calendarElslots = document.querySelector(".fc-timegrid-slots table");
    const calendarElcolstb = document.querySelector(".fc-timegrid-cols table");
    if (calendarhead) {
      calendarhead.style.width = isOpen
        ? "calc(100% - 0px)"
        : "calc(100% - 0px)";
      calendarElbody.style.width = isOpen
        ? "calc(100% - 16px)"
        : "calc(100% - 16px)";
      calendarEltimebody.style.width = isOpen
        ? "calc(100% - 0px)"
        : "calc(100% - 0px)";
      calendarElslots.style.width = isOpen
        ? "calc(100% - 16px)"
        : "calc(100% - 0px)";
      calendarElcolstb.style.width = isOpen ? "100%" : "calc(100% - 0px)";
    }
  }, [isOpen]);

  const fetchData = async (filtId) => {
    var dayval = 7;
    if (filtId == 1) {
      sethiddenDays([""]);
      dayval = 6;
    }

    var startday = "";
    var endday = "";
    //   const datacurrentView = JSON.parse(currentViewdata);

    // if (datacurrentView) {
    //   startday = Moment(datacurrentView.start).format('YYYY-MM-DD');
    //   endday = Moment(datacurrentView.end).format('YYYY-MM-DD');

    // } else {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + dayval; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();
    startday = Moment(firstday).format("YYYY-MM-DD");
    endday = Moment(lastday).format("YYYY-MM-DD");

    // }

    //Start and End date Convertion start by kathir
    const Calstartday = Moment(CalfromDate, "YYYY-MM-DDTHH:mm:ssZ").format(
      "YYYY-MM-DD"
    );
    const Calendday = Moment(CaltoDate, "YYYY-MM-DDTHH:mm:ssZ").format(
      "YYYY-MM-DD"
    );

    // Start and End date Convertion End by kathir
    setlessonCount(0);
    setLoading(true);
    const targetDiv = document.querySelector(".fc-timegrid");
    targetDiv.classList.add("blur-tbody");

    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllSessionFromTimeCal_defer",
        fk_cent_key: sessionStorage.getItem("key"),
        checkOP: filtId,
        startday: startday,
        endday: endday,
        Calstartday: Calstartday,
        Calendday: Calendday,
      });

      if (response.data.success === true) {
        // const startTime = new Date(event.start);
        // //   const endTime = new Date(event.end);
        // const dayOfWeek = startTime.getDay();
        // // const dayOfWeektext =
        // // 'Sunday' THEN 1 WHEN 'Monday' THEN 2 WHEN 'Tuesday' THEN 3  WHEN 'Wednesday' THEN 4  WHEN 'Thursday' THEN 5  WHEN 'Friday' THEN 6 WHEN 'Saturday'

        // const operatingStart = new Date(`1970-01-01T${operatingHours['Saturday'].start}`);
        // const operatingEnd = new Date(`1970-01-01T${operatingHours['Saturday'].end}`);

        // startTime >= operatingStart && startTime <= operatingEnd;

        setRecords(response.data.data);
        setItems(response.data.data);

        // Filter data where ses_date is not equal to Calendday and sum up the lessons
        const filteredData = response.data.data.flatMap((item) =>
          item.description.filter(
            (desc) => desc.lesson !== undefined && item.ses_date !== Calendday
          )
        );

        const totalLesson = filteredData.reduce(
          (sum, desc) => sum + (desc.lesson || 0),
          0
        );
        // console.log(totalLesson)
        setlessonCount(totalLesson);

        setLoading(false);
        const targetDiv = document.querySelector(".fc-timegrid");
        if (targetDiv) {
          targetDiv.classList.remove("blur-tbody");
        }
      } else {
        //alert message provide
        setLoading(false);
        const targetDiv = document.querySelector(".fc-timegrid");
        if (targetDiv) {
          targetDiv.classList.remove("blur-tbody");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      const targetDiv = document.querySelector(".fc-timegrid");
      if (targetDiv) {
        targetDiv.classList.remove("blur-tbody");
      }
    }
  };
  const fetchNotesData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllNotes",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setNotesRecords(response.data.data);
        setItems(response.data.data);
      } else {
        //alert message provide
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchMSData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllCSDeferBin",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setMSRecords(response.data.data);
        setItems(response.data.data);
      } else {
        //alert message provide
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOPHData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getCurrDateOPH",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        const resdata = response.data.data;
        setOPHRecords(resdata);

        sethiddenDays([resdata.op_weekday]);
      } else {
        //alert message provide
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Defer Request Call start

  const fetchDRData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllCSDeferRequest",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setDRRecords(response.data.data);
        //setItems(response.data.data);
      } else {
        //alert message provide
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDRDisApprovedData = async () => {
    try {
      const responseDRDA = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllCSDisapprovedDeferRequest",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (responseDRDA.data.success === true) {
        setDRDisApprovedRecords(responseDRDA.data.data);
        //setItems(response.data.data);
      } else {
        //alert message provide
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Defer Request Call End

  //Get Tutor Dropdown start

  const fetchTutorData = async () => {
    await axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllTutorbycenterSess",
        center_key: sessionStorage.getItem("key"),
      })
      .then((response) => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map((option) => ({
          value: option.pk_tut_key,
          label: option.tut_fname + " " + option.tut_surname,
        }));
        setOptions(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching options from API: ", error);
      });
  };

  //Get Tutor Dropdown End

  //Get ReEnrolled session data start
  const fetchReEnrolledSession = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllSessionTimeForReEnroled",
        fk_cent_key: sessionStorage.getItem("key"),
        fk_student_key: SelectStudentDetail.fk_stu_key,
        re_enrol_date: SelectStudentDetail.sdate,
      });

      if (response.data && response.data.data.length > 0) {
        setReEnrollRecords(response.data.data);
        toggleReenrol();
        setModalShow2(false);
      } else {
        setReEnrollRecords([]);
        toggleReenrol();
        //alert message provide
        setModalShow2(false);
      }
    } catch (error) {
      console.error("Error fetching reenrol data:", error);
    }
  };

  function handleReenroledClick(event) {
    setSelectStudentDetail(event);
    //setDeferShow(true);
  }
  useEffect(() => {
    if (SelectStudentDetail) {
      fetchReEnrolledSession();
    }
  }, [SelectStudentDetail]);

  const columnsReenrol = [
    {
      headerName: "Session Name",
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      width: 200,
      valueGetter: (params) => {
        return `${
          params.row.sest_name == null || params.row.sest_name == ""
            ? "-"
            : params.row.sest_name
        }`;
      },
    },
    {
      headerName: "From Date",
      field: "FromDate",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_from_date == null || params.row.sest_from_date == ""
            ? "-"
            : Moment(params.row.sest_from_date, "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              )
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "To Date",
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_to_date == null || params.row.sest_to_date == ""
            ? "-"
            : Moment(params.row.sest_to_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Start Time",
      field: "Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_start_time == null || params.row.sest_start_time == ""
            ? "-"
            : Moment(params.row.sest_start_time, "HH:mm:ss").format("HH:mm")
        }`;
      },
    },
    {
      headerName: "End Time",
      field: "End Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_end_time == null || params.row.sest_end_time == ""
            ? "-"
            : Moment(params.row.sest_end_time, "HH:mm:ss").format("HH:mm")
        }`;
      },
    },

    // {

    //   headerName: 'Day',
    //   field: "Day",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
    //   },

    // },
    {
      headerName: "Tutor",
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          (params.row.tut_fname == null || params.row.tut_fname == "") &&
          (params.row.tut_surname == null || params.row.tut_surname == "")
            ? "-"
            : params.row.tut_fname + " " + params.row.tut_surname
        }`;
      },
    },

    // {
    //   name: 'Student',
    //   selector: row => row.enrolment_count,
    //   sortable: true,
    // },
    {
      headerName: "Type",
      field: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_type == null || params.row.sest_type == ""
            ? "-"
            : params.row.sest_type
        }`;
      },
    },
    {
      headerName: "Classes",
      field: "Classes",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_is_holidays == 1
            ? "Regular"
            : params.row.sest_is_holidays == 2
            ? "One Off Session"
            : params.row.sest_is_holidays == 3
            ? "Make up Session"
            : "N/A"
        }`;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 120,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;

        return (
          <div className="d-flex  pad-12">
            <Button
              variant="outlined-red"
              className="primary-light"
              style={{ fontSize: "12px" }}
              onClick={() => {
                setReEnrolSelectSession(row);
                setReEnrolConfirm(true);
                toggleReenrol();
              }}
            >
              Select
            </Button>
          </div>
        );
      },
    },
  ];

  // Get ReEnrolled session data end

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*
    state = {
      calendarEvents: [
        {
          title: "Atlanta Monster",
          start: new Date("2019-04-04 00:00"),
          id: "99999998"
        },
        {
          title: "My Favorite Murder",
          start: new Date("2019-04-05 00:00"),
          id: "99999999"
        }
      ],
      events: [
        { title: "Event 1", id: "1" },
        { title: "Event 2", id: "2" },
        { title: "Event 3", id: "3" },
        { title: "Event 4", id: "4" },
        { title: "Event 5", id: "5" }
      ],
      isEditForm: false,
      formTitle: ""
    };
  */

  const handleEventClick = (event) => {
    //alert(`Event title: ${event.title}\nEvent description: ${event.extendedProps.description}`);
    setSelectedEvent(event);
    setShowModal_event(true);
  };

  // const handleMsOnChange = (cancel_ses_key,forfeitValue) => {
  //     const updatedData = data_MS.map(item => {
  //       if (item.cancel_session_key === cancel_ses_key) {
  //           // Add the "forfeitValue" key with a value of 1
  //           return { ...item, forfeitValue: forfeitValue };
  //       }
  //       return item;
  //   });

  //   // Now, updatedData contains the modified data
  //   setMSDetailsData(updatedData);
  // }

  // const handleMsOnChange = (cancel_ses_key, forfeitValue) => {

  //   const updatedData = data_MS.map(item => {
  //     if (item.cancel_session_key === cancel_ses_key) {
  //       // Add or update the "forfeitValue" key with the provided value
  //       return { ...item, forfeitValue: 1 };
  //     }
  //     return item;
  //   });
  //   // Set the updated data in your state using setMSDetailsData
  //   setMSDetailsData(updatedData);

  // };

  // const handleMsOnChange = (forfeitValue, cancel_ses_key) => {
  //   const updatedData = data_MS.map(item => {
  //     if (item.cancel_session_key === cancel_ses_key) {
  //       // Add or update the "forfeitValue" key with the provided value
  //       return { ...item, forfeitValue: forfeitValue };
  //     }
  //     return item;
  //   });

  //   // Set the updated data in your state using setMSDetailsData
  //   setMSDetailsData(updatedData);
  // };

  // async function removeRecord(keyToRemove){
  //   const updatedData = data_MS.filter((record) => record.cancel_session_key !== keyToRemove);
  //   setMSDetailsData(updatedData);
  // };

  async function removeRecord(keyToRemove) {
    console.log(keyToRemove);
    console.log(data_MS);
    // Create a promise to wait for the state update to complete
    const stateUpdatePromise = new Promise((resolve) => {
      setMSDetailsData((prevData) => {
        // Filter the data
        const updatedData = prevData.filter(
          (record) => record.cancel_session_key !== keyToRemove
        );
        // Resolve the promise with the updated data
        resolve(updatedData);
        return updatedData; // Update the state
      });
    });

    // Wait for the state update to complete
    const updatedData = await stateUpdatePromise;

    // Continue with your other processing steps
  }

  // async function removeDBParentRecord(keyToRemove) {
  //   // Create a promise to wait for the state update to complete
  //   const stateUpdatePromise = new Promise((resolve) => {
  //     setDBParentData((prevData) => {
  //       // Filter the data
  //       const updatedData = prevData.filter((record) => record.cancel_session_key !== keyToRemove);
  //       // Resolve the promise with the updated data
  //       resolve(updatedData);
  //       return updatedData; // Update the state
  //     });
  //   });

  //   // Wait for the state update to complete
  //   const updatedData = await stateUpdatePromise;

  //   // Now you can perform additional processing with the updatedData

  //   // Continue with your other processing steps
  // }

  // async function removeRecordsSequentially() {
  //   for (const keyToRemove of dataArray) {
  //     await removeRecord(keyToRemove);
  //   }
  // }

  // const filteredEvents = records.filter(event => {

  //   const startTime = new Date(event.start);
  //   //   const endTime = new Date(event.end);
  //   const dayOfWeek = startTime.getDay();
  //   // const dayOfWeektext =
  //   // 'Sunday' THEN 1 WHEN 'Monday' THEN 2 WHEN 'Tuesday' THEN 3  WHEN 'Wednesday' THEN 4  WHEN 'Thursday' THEN 5  WHEN 'Friday' THEN 6 WHEN 'Saturday'

  //   const operatingStart = new Date(`1970-01-01T${operatingHours['Saturday'].start}`);
  //   const operatingEnd = new Date(`1970-01-01T${operatingHours['Saturday'].end}`);

  //   return startTime >= operatingStart && startTime <= operatingEnd;
  // });

  // //setRecords(filteredEvents);

  const renderCategoryFilters = () => {
    return (
      <div className="category-filters">
        {Object.keys(eventCategories).map((category) => (
          <label key={category}>
            <input
              type="checkbox"
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={handleCategoryChange}
            />
            {eventCategories[category]}
          </label>
        ))}
      </div>
    );
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    if (e.target.checked) {
      // Add the category to selectedCategories if checked
      setSelectedCategories([...selectedCategories, category]);
    } else {
      // Remove the category from selectedCategories if unchecked
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    }
  };

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const eventContent = (info) => {
    //if schedule else existion system
    if (
      info.event.extendedProps.isschedule &&
      parseInt(info.event.extendedProps.isschedule) === 1
    ) {
      const eventWidth = calculateEventWidth(info);
      const eventStyle = {
        width: eventWidth,
      };

      const description = info.event.extendedProps.description;
      // const tut_profile_pic = info.event.extendedProps.tut_profile_pic;
      //const cls_typestar = info.event.extendedProps.sest_class;
      const cls_type = "card_blue";
      var abborwords = "";

      const descriptionList =
        Array.isArray(description) && description.length > 0
          ? description.map((number) => {
              // var abbreviation = '';
              // var words ='';
              // if ( number.st_year_level !='' && number.st_year_level !=null ) {
              //    words = number.st_year_level.split(/(\d+)/).filter(Boolean).map((element, index) => index === 0 ? element.charAt(0) : element);
              //    abbreviation = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() : word)).join('');
              // }
              // if (abbreviation) {
              //   abborwords = abbreviation;
              // } else {
              //   abborwords = words; // If no matching country is found, display just the country code
              // }
              // if (number.fcount == 1 || number.wcount == 2 || number.cancel_status == 2) {

              //   return (<span key={number.id}>
              //     <div class="d-flex justify-content-between bd-highlight">
              //       <div ><del>{(number.reassigned && number.reassigned != null && number.reassigned == 1) || (cls_typestar == 3) ? '*' : ''}{Capitalize(number.st_first_name)} {number.st_surname}   (
              //         {abborwords}{number.sub_abbreviation})</del></div>

              //      {(number.fcount == 1) ? <div className="cus-danger ms-4">Forfeit</div> : (number.wcount == 2) ? <div className="cus-danger ms-4  ">Writeoff</div> : (number.cancel_status == 2) ? <div className="cus-danger  ms-4 ">Cancelled</div> : (number.cancel_status == 0 && parseInt(number.cancel_defer_sources) == 1) ? <div className="cus-warning  ms-4 ">Deferment Requested</div> : (number.cancel_status == 1 && parseInt(number.cancel_defer_sources) == 1) ? <div className="cus-warning  ms-4 ">Slot Assign</div> : (number.cancel_status == 1) ? <div className="cus-warning  ms-4 ">Deferred</div> : <div className="cus-primary  ms-4 ">Pending</div>}
              //     </div>
              //   </span>);
              // }

              // else {
              return (
                <span key={number.id}>
                  <div class="d-flex justify-content-between bd-highlight ">
                    <div>
                      {Capitalize(number.st_first_name)} {number.st_surname}
                    </div>
                    {number.is_diagnostics == 1 &&
                    parseInt(number.is_diagnostics_close) === 1 &&
                    (number.is_student == null || number.is_student != 1) ? (
                      <div className="cus-danger  ms-4">Closed</div>
                    ) : number.is_diagnostics == 1 &&
                      (number.is_student == null || number.is_student != 1) ? (
                      <div className="cus-warning ms-4 ">
                        Diagnostics Completed
                      </div>
                    ) : number.is_diagnostics == 1 &&
                      (number.is_student != null || number.is_student == 1) ? (
                      <div className="cus-warning ms-4 ">Student Converted</div>
                    ) : (
                      <div className="cus-primary ms-4 ">Pending</div>
                    )}
                  </div>
                </span>
              );
              // }
            })
          : "";

      return (
        <div
          className={`cursor-pointer  w-100 fc_eventContent-cus ${cls_type}`}
          onClick={() => handleTutSessClick(info)}
        >
          <div class="title-slot">
            <strong>{info.event.title}</strong>

            {/* setTutSessDetailsData */}
            {/* <Icon.ThreeDotsVertical className="float-right filled-background-icon" onClick={() => handleTutSessClick(info)} /> */}
          </div>

          <div class="body-slot">{descriptionList}</div>
        </div>
      );
    } else {
      const eventWidth = calculateEventWidth(info);
      const eventStyle = {
        width: eventWidth,
      };

      const description = info.event.extendedProps.description;
      // const tut_profile_pic = info.event.extendedProps.tut_profile_pic;
      const cls_typestar = info.event.extendedProps.sest_class;
      const cls_type =
        info.event.extendedProps.sest_class == 1
          ? "card_regu"
          : info.event.extendedProps.sest_class == 2
          ? "card_oto"
          : info.event.extendedProps.sest_class == 3
          ? "card_mus"
          : "";
      var abborwords = "";
      const hasReassigned =
        Array.isArray(description) &&
        description.some((item) => item.reassigned === 1);

      const mark_note =
        hasReassigned || cls_typestar == 3 ? (
          <span>
            {" "}
            <div class="mt-4">
              {" "}
              <span class="cursor-pointer f-10 badge rounded-pill bg-success calender-badge mb-0 text-black">
                * - Mark One Off
              </span>
            </div>
          </span>
        ) : null;

      const descriptionList =
        Array.isArray(description) && description.length > 0
          ? description.map((number) => {
              var abbreviation = "";
              var words = "";
              if (number.st_year_level != "" && number.st_year_level != null) {
                words = number.st_year_level
                  .split(/(\d+)/)
                  .filter(Boolean)
                  .map((element, index) =>
                    index === 0 ? element.charAt(0) : element
                  );
                abbreviation = words
                  .map((word, index) =>
                    index === 0 ? word.charAt(0).toUpperCase() : word
                  )
                  .join("");
              }
              if (abbreviation) {
                abborwords = abbreviation;
              } else {
                abborwords = words; // If no matching country is found, display just the country code
              }
              if (
                (parseInt(number.fcount) === 1 ||
                parseInt(number.wcount) === 2 ||
                parseInt(number.cancel_status) === 2) && parseInt(number.attendance_status) === 6
              ) {
                return (
                  <span key={number.id}>
                    <div class="d-flex justify-content-between bd-highlight">
                      <div>
                        <del>
                          {(parseInt(number.reassigned) &&
                            parseInt(number.reassigned) != null &&
                            parseInt(number.reassigned) === 1) ||
                          cls_typestar == 3
                            ? "*"
                            : ""}
                          {Capitalize(number.st_first_name)} {number.st_surname}{" "}
                          ({abborwords}
                          {number.sub_abbreviation})
                        </del>
                      </div>

                      {parseInt(number.fcount) === 1 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger ms-4">Forfeit</div>
                      ) : parseInt(number.wcount) === 2 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger ms-4  ">Writeoff</div>
                      ) : parseInt(number.cancel_status) === 2 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger  ms-4 ">Cancelled</div>
                      ) : parseInt(number.cancel_status) === 0 &&
                        parseInt(number.cancel_defer_sources) === 1 ? (
                        <div className="cus-warning  ms-4 ">
                          Deferment Requested
                        </div>
                      ) : parseInt(number.cancel_status) === 1 &&
                        parseInt(number.cancel_defer_sources) === 1 ? (
                        <div className="cus-warning  ms-4 ">Slot Assign</div>
                      ) : parseInt(number.cancel_status) === 1 ? (
                        <div className="cus-warning  ms-4 ">Deferred</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 0 ? (
                        <div className="cus-primary  ms-4 ">Pending</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 1 ? (
                        <div className="cus-warning  ms-4 ">Ongoing</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 2 ? (
                        <div className="text-success  ms-4 ">Attended</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 3 ? (
                        <div className="cus-danger  ms-4 ">Missed</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 4 ? (
                        <div className="cus-danger  ms-4 ">Offline</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 5 ? (
                        <div className="cus-warning  ms-4 ">
                          Deferment Requested
                        </div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger  ms-4 ">Cancelled</div>
                      ) : (
                        <div className="cus-primary  ms-4 ">Pending</div>
                      )}
                    </div>
                  </span>
                );
              } else {
                return (
                  <span key={number.id}>
                    <div class="d-flex justify-content-between bd-highlight ">
                      <div>
                        {(parseInt(number.reassigned) &&
                          parseInt(number.reassigned) != null &&
                          parseInt(number.reassigned) === 1) ||
                        cls_typestar == 3
                          ? "*"
                          : ""}
                        {Capitalize(number.st_first_name)} {number.st_surname} (
                        {abborwords}
                        {number.sub_abbreviation})
                      </div>
                      {parseInt(number.fcount) === 1 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger  ms-4">Forfeit</div>
                      ) : parseInt(number.wcount) === 2 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger ms-4 ">Writeoff</div>
                      ) : parseInt(number.cancel_status) === 2 && parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger ms-4 ">Cancelled</div>
                      ) : parseInt(number.cancel_status) === 0 &&
                        parseInt(number.cancel_defer_sources) === 1 ? (
                        <div className="cus-warning ms-4 ">
                          Deferment Requested
                        </div>
                      ) : parseInt(number.cancel_status) === 1 &&
                        parseInt(number.cancel_defer_sources) === 1 ? (
                        <div className="cus-warning ms-4 ">Slot Assign</div>
                      ) : parseInt(number.cancel_status) === 1 ? (
                        <div className="cus-warning  ms-4">Deferred</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 0 ? (
                        <div className="cus-primary  ms-4 ">Pending</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 1 ? (
                        <div className="cus-warning  ms-4 ">Ongoing</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 2 ? (
                        <div className="text-success  ms-4 ">Attended</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 3 ? (
                        <div className="cus-danger  ms-4 ">Missed</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 4 ? (
                        <div className="cus-danger  ms-4 ">Offline</div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 5 ? (
                        <div className="cus-warning  ms-4 ">
                          Deferment Requested
                        </div>
                      ) : number.attendance_status &&
                        parseInt(number.attendance_status) === 6 ? (
                        <div className="cus-danger  ms-4 ">Cancelled</div>
                      ) : (
                        <div className="cus-primary ms-4 ">Pending</div>
                      )}
                    </div>
                  </span>
                );
              }
            })
          : "";

      return (
        <div
          className={`cursor-pointer  w-100 fc_eventContent-cus ${cls_type}`}
          onClick={() => handleTutSessClick(info)}
        >
          <div class="title-slot">
            <strong>{info.event.title}</strong>

            {/* setTutSessDetailsData */}
            {/* <Icon.ThreeDotsVertical className="float-right filled-background-icon" onClick={() => handleTutSessClick(info)} /> */}
          </div>

          <div class="body-slot">
            {descriptionList}
            {mark_note}
          </div>
        </div>
      );
    }
  };
  const calculateEventWidth = (info) => {
    const calendarApi = info.view.calendar;
    const viewName = info.view.type; // Get the view type (e.g., "dayGridWeek", "dayGridDay")
    const viewWidth = calendarApi.el.clientWidth;

    let dayCount;

    if (viewName === "dayGridWeek") {
      // For week view, calculate the number of visible days in the week
      dayCount = 7;
    } else if (viewName === "dayGridDay") {
      // For day view, there is only one visible day
      dayCount = 1;
    } else {
      // Handle other view types as needed
      // You can add additional cases for other view types
    }

    const minEventWidth = 800; // Minimum event width in pixels

    // Ensure the event width does not go below the minimum width
    let eventWidth = (viewWidth / dayCount) * 0.9; // 90% of the available width

    // Ensure the event width does not go below the minimum width
    if (eventWidth < minEventWidth) {
      eventWidth = minEventWidth;
    }

    return `${eventWidth}px`;
  };

  //   const calendarRef = useRef(null);

  // useEffect(() => {
  //   if (calendarRef.current) {
  //     const calendarApi = calendarRef.current.getApi();

  //   }
  // }, []);

  const formRef = useRef(null);
  const initialValues = {
    /*subjects*/

    nt_notes: "",

    fk_cent_key: sessionStorage.getItem("key"),
    created_by: sessionStorage.getItem("key"),
    visibility: 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    //  onSubmit,
    onSubmit: async (formValues) => {
      // setIsSubmitting(true);
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "createNotes",
          ...formValues,
        });
        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your note have been added.", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchNotesData();
            formik.handleChange({ target: { name: "nt_notes", value: "" } });
            // After 3 seconds, redirect to another page
            formik.setFieldTouched("nt_notes", false);

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create notes. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to create notes. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create notes. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button
      } finally {
        setIsSubmitting(false); // Re-enable the submit button
      }
    },
  });
  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChangeNote = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
  };

  const handleEventMouseEnter = (info) => {
    // Get the event element and add a custom class when hovering
    const eventElement = info.el;
    eventElement.classList.add("event-hovered");

    const targetDiv = document.querySelector(".event-hovered");
    const previousSibling = targetDiv.previousElementSibling;

    const parentElement = eventElement.parentElement;

    parentElement.classList.add("event-hoveredtop");
  };

  const handleEventMouseLeave = (info) => {
    // Get the event element and remove the custom class when mouse leaves
    const eventElement = info.el;

    const parentElement = eventElement.parentElement;

    // Remove the class from the parent element
    parentElement.classList.remove("event-hoveredtop");

    eventElement.classList.remove("event-hovered");
  };

  function handleDeferClick(event) {
    setDeferLabelData(event);
    setDeferShow(true);
  }

  function handleDRApproveClick(event) {
    setDRData(event);
    setDeferRequestShow(true);
  }

  function handleDisApproveClick(event) {
    setDRData(event);
    setDeferRequestDisApproveShow(true);
  }

  function handleForfeitClick(event, type) {
    const newKey = "type"; // Replace with your key
    const newValue = type; // Replace with your value
    const updatedData = { ...event, [newKey]: newValue };
    setForfeitLabelData(updatedData);
    setForfeitShow(true);
  }

  function handleTutSessClick(eventdata) {
    //Note: if session is diagnostic and else existing system
    //if(eventdata.event.extendedProps.isschedule && parseInt(eventdata.event.extendedProps.isschedule)===1){
    const descriptionaa = eventdata.event.extendedProps.description;

    const DSvalue = descriptionaa.map((eventval) => {
      return (
        <Col sm={12} lg={4} className="mb-2">
          <Card className="p-2">
            <div>
              <p className="mb-0 f-12 float-end">
                {" "}
                {parseInt(eventval.is_diagnostics) === 1 &&
                parseInt(eventval.is_diagnostics_close) === 1 &&
                (eventval.is_student == null || eventval.is_student != 1) ? (
                  <Badge pill bg="danger">
                    Closed
                  </Badge>
                ) : eventval.is_diagnostics == 1 &&
                  (eventval.is_student == null || eventval.is_student != 1) ? (
                  <Badge pill bg="warning" text="dark">
                    Diagnostics Completed
                  </Badge>
                ) : eventval.is_diagnostics == 1 &&
                  (eventval.is_student != null || eventval.is_student == 1) ? (
                  <Badge pill bg="warning" text="dark">
                    Student Converted
                  </Badge>
                ) : (
                  <Badge pill bg="info">
                    Pending{" "}
                  </Badge>
                )}
              </p>
            </div>
            <div>
              <div className="justify-content-between align-self-start">
                <div className="d-flex mb-2">
                  <PersonIcon className="my-auto me-2" />
                  <div className="">
                    <p className="f-14b mb-1 ms-0">
                      <span
                        className="f-14 name-clr text-caps cursor-pointer"
                        onClick={() => handleStuDSViewClick(eventval)}
                      >
                        {Capitalize(eventval.st_first_name) +
                          " " +
                          eventval.st_surname}
                      </span>
                      {/* <NavLink className="f-16 text-black text-caps" to={`/view-student?id=${eventval.fk_stu_key}`} >{Capitalize(eventval.st_first_name) + ' ' + eventval.st_surname}</NavLink> */}
                    </p>
                    {/* <p className="mb-1 f-12">({abborwordspop + '' + eventval.sub_abbreviation}) {eventval.subject_name}</p> */}
                  </div>
                </div>
                {/* <div className="float-end">    {(eventval.cancel_status == null && (eventval.fcount != 1 && eventval.wcount != 2)) ? (<div>
                  <Button variant="outlined-red" className="primary-light" style={{ fontSize: "12px" }} onClick={() => handleDeferClick(eventval)}> Defer </Button>
                </div>) : ''}   </div> */}{" "}
              </div>{" "}
            </div>
          </Card>{" "}
        </Col>
      );
    });
    const valDSValue =
      DSvalue.length > 0 ? (
        DSvalue
      ) : (
        <p class="text-center mb-0">
          There are no students in this diagnostics.
        </p>
      );

    // }else{
    // const descriptionvalall = eventdata.map(eventval => {

    //   // Use return to return a JSX element for each item
    //   //return <div > {eventval}</div>;
    // });
    // ses_date
    // sest_start_time
    //const descriptionaa = eventdata.event.extendedProps.description
    const cls_typestar = eventdata.event.extendedProps.sest_class;
    // const vaaal = descriptionaa.map(eventval => {
    //   return (<Col sm={12} lg={4} className="p-2"><Card className="rounded-1 card-bdr p-2"> <div> <div className="justify-content-between align-self-start"> <div className=""> <p className="f-14b mb-1 ms-0"> {eventval.st_first_name+ ' ' +eventval.st_surname }({eventval.sub_abbreviation.substring(0, 3)+'-'+eventval.st_year_level }) </p>   <Button size="small" variant="contained" style={{ fontSize: "10px", height: "16px" }} color="error" > Pending</Button>  </div>  <div className=" pt-2 float-end "> <Button variant="outlined-red" className="primary-danger btnmr-2" style={{ fontSize: "10px", height: "16px" }}><del>Forfeit</del> </Button>  <Button variant="outlined-red" className="primary-outlined" style={{ fontSize: "10px", height: "16px" }} onClick={() => handleForfeitClick([], 1)}>Writeoff </Button>  </div>   </div> </div></Card>  </Col>);
    // });
    const validDate = new Date(
      `${eventdata.event.extendedProps.ses_date}T${eventdata.event.extendedProps.ses_end_time}`
    );
    //const currentDate = new Date();
    const timezoneIdentifier = sessionStorage.getItem("timezone_identifier");
    const currentDate =
      new Date(Moment().tz(timezoneIdentifier).format("YYYY-MM-DDTHH:mm:ss")) ||
      new Date();

    var abborwordspop = "";
    const vaaal = descriptionaa.map((eventval) => {
      var abbreviationpop = "";
      var words = "";
      if (eventval.st_year_level != "" && eventval.st_year_level != null) {
        words = eventval.st_year_level
          .split(/(\d+)/)
          .filter(Boolean)
          .map((element, index) => (index === 0 ? element.charAt(0) : element));
        abbreviationpop = words
          .map((word, index) =>
            index === 0 ? word.charAt(0).toUpperCase() : word
          )
          .join("");
      }
      if (abbreviationpop) {
        abborwordspop = abbreviationpop;
      } else {
        abborwordspop = words; // If no matching country is found, display just the country code
      }
      return (
        <Col sm={12} lg={4} className="mb-2">
          <Card className="p-2">
            <div>
              <p className="mb-0 f-12 float-end">
                {" "}
                {parseInt(eventval.fcount) === 1 && parseInt(eventval.attendance_status) === 6 ? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Forfeit
                  </Badge>
                ) : parseInt(eventval.wcount) === 2 && parseInt(eventval.attendance_status) === 6 ? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Writeoff
                  </Badge>
                ) : parseInt(eventval.cancel_status) === 2 && parseInt(eventval.attendance_status) === 6? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Cancelled
                  </Badge>
                ) : parseInt(eventval.cancel_status) === 0 &&
                  parseInt(eventval.cancel_defer_sources) === 1 ? (
                  <Badge
                    pill
                    bg="warning"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Deferment Requested
                  </Badge>
                ) : parseInt(eventval.cancel_status) === 1 &&
                  parseInt(eventval.cancel_defer_sources) === 1 ? (
                  <Badge
                    pill
                    bg="warning"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Slot Assign
                  </Badge>
                ) : parseInt(eventval.cancel_status) === 1 ? (
                  <Badge
                    pill
                    bg="warning"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Deferred
                  </Badge>
                ) : eventval.attendance_status !== undefined &&
                  parseInt(eventval.attendance_status) === 0 ? (
                  <Badge
                    pill
                    bg="info"
                    className="cursor-pointer"
                    onClick={() => handleEditClick(eventval)}
                  >
                    Pending{" "}
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 1 ? (
                  <Badge
                    pill
                    bg="warning"
                    className="cursor-pointer"
                    onClick={() => handleEditClick(eventval)}
                  >
                    Ongoing{" "}
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 2 ? (
                  <Badge
                    pill
                    bg="success"
                    className="cursor-pointer"
                    onClick={() => handleEditClick(eventval)}
                  >
                    Attended{" "}
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 3 ? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleEditClick(eventval)}
                  >
                    Missed{" "}
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 4 ? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleEditClick(eventval)}
                  >
                    Offline{" "}
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 5 ? (
                  <Badge
                    pill
                    bg="warning"
                    text="dark"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Deferment Requested
                  </Badge>
                ) : parseInt(eventval.attendance_status) &&
                  parseInt(eventval.attendance_status) === 6 ? (
                  <Badge
                    pill
                    bg="danger"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Cancelled
                  </Badge>
                ) : (
                  <Badge
                    pill
                    bg="info"
                    className="cursor-pointer"
                    onClick={() => handleMessageClick()}
                  >
                    Pending{" "}
                  </Badge>
                )}
              </p>
            </div>
            <div>
              <div className="justify-content-between align-self-start">
                <div className="d-flex mb-2">
                  <PersonIcon className="my-auto me-2" />
                  <div className="">
                    <p className="f-14b mb-1 ms-0">
                      {(parseInt(eventval.fcount) === 1 ||
                      parseInt(eventval.wcount) === 2 ||
                      parseInt(eventval.cancel_status) === 2) && parseInt(eventval.attendance_status) === 6 ? (
                        <del>
                          <span
                            className="f-14 name-clr text-caps cursor-pointer"
                            onClick={() => handleStuViewClick(eventval)}
                          >
                            {(parseInt(eventval.reassigned) &&
                              parseInt(eventval.reassigned) != null &&
                              parseInt(eventval.reassigned) === 1) ||
                            cls_typestar == 3
                              ? "*"
                              : ""}
                            {Capitalize(eventval.st_first_name) +
                              " " +
                              eventval.st_surname}
                          </span>
                        </del>
                      ) : (
                        <span
                          className="f-14 name-clr text-caps cursor-pointer"
                          onClick={() => handleStuViewClick(eventval)}
                        >
                          {(parseInt(eventval.reassigned) &&
                            parseInt(eventval.reassigned) != null &&
                            parseInt(eventval.reassigned) === 1) ||
                          cls_typestar == 3
                            ? "*"
                            : ""}
                          {Capitalize(eventval.st_first_name) +
                            " " +
                            eventval.st_surname}
                        </span>
                      )}
                      {/* <NavLink className="f-16 text-black text-caps" to={`/view-student?id=${eventval.fk_stu_key}`} >{Capitalize(eventval.st_first_name) + ' ' + eventval.st_surname}</NavLink> */}
                    </p>
                    {/* <p className="mb-1 f-12">({abborwordspop + '' + eventval.sub_abbreviation}) {eventval.subject_name}</p> */}
                    <p className="mb-1 f-12">
                      {eventval.st_year_level} ({eventval.subject_name})
                    </p>
                  </div>
                </div>
                <div className="float-end d-flex gap-2">
                  {(parseInt(eventval.reassigned) &&
                    parseInt(eventval.reassigned) != null &&
                    parseInt(eventval.reassigned) === 1) ||
                  cls_typestar == 3 ? (
                    ""
                  ) : currentDate <= validDate ? (
                    <div>
                      <Button
                        variant="outlined-red"
                        className="calender-btn bg-warning re-enrol"
                        style={{ fontSize: "12px" }}
                        onClick={() => handleReenroledClick(eventval)}
                      >
                        {" "}
                        Reenrol{" "}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {(
                    (eventval.cancel_status == null &&
                      eventval.fcount != 1 &&
                      eventval.wcount != 2) 
                      || 
                      ((parseInt(eventval.fcount) === 1 ||
                      parseInt(eventval.wcount) === 2 ||
                      parseInt(eventval.cancel_status) === 2) && parseInt(eventval.attendance_status) !== 6)
                    ) ? (
                    <div>
                      <Button
                        variant="outlined-red"
                        className="primary-light"
                        style={{ fontSize: "12px" }}
                        onClick={() => handleDeferClick(eventval)}
                      >
                        {" "}
                        Defer{" "}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </Card>{" "}
        </Col>
      );
    });
    const valTutSess =
      vaaal.length > 0 ? (
        vaaal
      ) : (
        <p class="text-center mb-0">There are no students in this session.</p>
      );

    var i = 1;
    var k = 1;
    const seenStudentKeys = new Set();
    const uniqueData = [];
    const mscard = data_MS.map((eventval) => {
      const studentKey = eventval.fk_student_key; 
     
      if (seenStudentKeys.has(studentKey)) {
        return null; 
      }
      
      
      seenStudentKeys.add(studentKey);
      if (
        eventdata.event.extendedProps.isschedule &&
        parseInt(eventdata.event.extendedProps.isschedule) === 1
      ) {
        setMSDetailsData([]);
        //removeRecord(eventval.cancel_session_key);
        const lastItem = data_MS.length == i;

        if (lastItem) {
          toast.error("Diagnostics session is not assign!", {
            autoClose: 2000, // 3 seconds in this example
          });
          setIsSetMs(false);
        }
      } else {
        // const validDate = new Date(`${eventdata.event.extendedProps.ses_date}T${eventdata.event.extendedProps.sest_start_time}`);
        // const currentDate = new Date();

        // if (currentDate <= validDate) {
        const isKeyExists = eventdata.event.extendedProps.description.some(
          (item) =>
            item.fk_stu_key === eventval.fk_student_key &&
            item.cancel_status != 2
        );

        if (isKeyExists) {
          //setTimeout(function () {
          removeRecord(eventval.cancel_session_key);

          //}, 2000);

          toast.error(
            Capitalize(eventval.st_first_name) +
              " " +
              eventval.st_surname +
              " Student Already Exists!",
            {
              autoClose: 2000, // 3 seconds in this example
            }
          );

          //removeRecordsSequentially();
        } else {
          if (k === 1) {
            k++;
            setIsSetMs(true);
            uniqueData.push(eventval);

            return (
              <>
                <hr class="hr" />
                <p className="boarder">
                  Assign selected student(s) for make-up
                </p>
                <Col sm={12} lg={4} className="mb-2">
                  <Card className="p-2">
                    <div>
                      <div className="justify-content-between align-self-start">
                        <div className="d-flex ">
                          <PersonIcon className="my-auto me-2" />
                          <div className="">
                            <p className="f-14b mb-1 ms-0">
                              {Capitalize(eventval.st_first_name) +
                                " " +
                                eventval.st_surname}
                              {/* <NavLink className="f-16 text-black text-caps" to={`/view-student?id=${eventval.fk_stu_key}`} >{Capitalize(eventval.st_first_name) + ' ' + eventval.st_surname}</NavLink> */}
                            </p>
                            <p className="mb-1 f-12">
                              {" "}
                              {eventval.subject_name}
                            </p>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>{" "}
                </Col>
              </>
            );
          } else {
            uniqueData.push(eventval);
            return (
              <Col sm={12} lg={4} className="mb-2">
                <Card className="p-2">
                  <div>
                    <div className="justify-content-between align-self-start">
                      <div className="d-flex ">
                        <PersonIcon className="my-auto me-2" />
                        <div className="">
                          <p className="f-14b mb-1 ms-0">
                            {Capitalize(eventval.st_first_name) +
                              " " +
                              eventval.st_surname}
                            {/* <NavLink className="f-16 text-black text-caps" to={`/view-student?id=${eventval.fk_stu_key}`} >{Capitalize(eventval.st_first_name) + ' ' + eventval.st_surname}</NavLink> */}
                          </p>
                          <p className="mb-1 f-12"> {eventval.subject_name}</p>
                         
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </Card>{" "}
              </Col>
            );
          }
        }

        // } else {

        //   setMSDetailsData([])
        //   //removeRecord(eventval.cancel_session_key);
        //   const lastItem = ((data_MS.length) == i);
        //
        //   if (lastItem) {
        //     toast.error("Past session is not assign!", {
        //       autoClose: 2000, // 3 seconds in this example
        //     });
        //     setIsSetMs(false);
        //   }

        // }
      }
      i++;
    });
    setMSDetailsData(uniqueData);
    const vaMSSess = data_MS.length > 0 ? mscard : "";

    var j = 1;
    const msFooter = data_MS.map((eventval) => {
      // setIsSetMs(false);
      if (
        eventdata.event.extendedProps.isschedule &&
        parseInt(eventdata.event.extendedProps.isschedule) === 1
      ) {
      } else {
        const validDate = new Date(
          `${eventdata.event.extendedProps.ses_date}T${eventdata.event.extendedProps.ses_end_time}`
        );
        //const currentDate = new Date();
        const timezoneIdentifier = sessionStorage.getItem(
          "timezone_identifier"
        );
        const currentDate =
          new Date(
            Moment().tz(timezoneIdentifier).format("YYYY-MM-DDTHH:mm:ss")
          ) || new Date();

        if (currentDate <= validDate) {
          const isKeyExists = eventdata.event.extendedProps.description.some(
            (item) =>
              item.fk_stu_key === eventval.fk_student_key &&
              item.cancel_status != 2
          );

          if (isKeyExists) {
          } else {
            //
            if (j === 1) {
              j++;
              return (
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outlined-red"
                    className="primary-light"
                    onClick={() => {
                      setNewEventData(eventdata);
                      setMSForfeitShow(true);
                    }}
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    Confirm assignment
                  </Button>
                </div>
              );
            }
          }
        } else {
        }
      }
    });

    // }

    const dataTutor = () => {
      //Note: if section is diagnostic section else existing system

      if (
        eventdata.event.extendedProps.isschedule &&
        parseInt(eventdata.event.extendedProps.isschedule) === 1
      ) {
        return (
          <>
            <Modal.Header
              closeButton
              className="rounded-0 justify-content-between"
            >
              {/* <Modal.Title id="contained-modal-title-vcenter" className="rounded-0">
                <h2 className="h2 mb-0">Diagnostics schedule</h2>
              </Modal.Title> */}

              <div className="d-flex my-auto gap-3">
                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formTutor"
                >
                  <div>
                    <span
                      className="text-dark cursor-pointer "
                      onClick={() =>
                        handleDSViewClick(eventdata.event.extendedProps)
                      }
                      to={`/Diagnostics`}
                    >
                      {" "}
                      <CastForEducationIcon />{" "}
                      <span className="name-clr">
                        {eventdata.event.title}
                      </span>{" "}
                    </span>
                  </div>
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formDate"
                >
                  <div>
                    <EventIcon />{" "}
                    {Moment(eventdata.event.extendedProps.ses_date).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formTime"
                >
                  <div>
                    <AccessTimeFilledIcon />{" "}
                    {eventdata.event.extendedProps.sest_start_time != null &&
                    eventdata.event.extendedProps.sest_start_time != ""
                      ? Moment(
                          eventdata.event.extendedProps.sest_start_time,
                          "hh:mm:ss"
                        ).format("LT")
                      : null}{" "}
                    -{" "}
                    {eventdata.event.extendedProps.ses_end_time != null &&
                    eventdata.event.extendedProps.ses_end_time != ""
                      ? Moment(
                          eventdata.event.extendedProps.ses_end_time,
                          "hh:mm:ss"
                        ).format("LT")
                      : null}
                  </div>
                </Form.Group>
                {/* <Form.Group className="d-flex align-item-center gap-3" controlId="formTime">
                  <div>
                   
                  </div>
                </Form.Group> */}
                {/* <Form.Group className="d-flex align-item-center gap-3" controlId="formTime">
                  <div className="cursor-pointer card-cus-height " onClick={() => { setEditDTShow(true); setEditData(eventdata); }}>
                    <EditNoteIcon /> Edit Session
                  </div>
                </Form.Group> */}
              </div>
            </Modal.Header>
            <Modal.Body className="pt-2 p-15 rounded-0">
              <Row>{valDSValue}</Row>
            </Modal.Body>
          </>
        );
      } else {
        return (
          <>
            <Modal.Header
              closeButton
              className="rounded-0 justify-content-between"
            >
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="rounded-0"
              >
                <h2 className="h2 mb-0">Tutoring Session</h2>
              </Modal.Title>

              <div className="d-flex my-auto gap-3">
                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formTutor"
                >
                  <div>
                    <span
                      className="text-dark cursor-pointer "
                      onClick={() =>
                        handleTutViewClick(eventdata.event.extendedProps)
                      }
                      to={`/edit-tutor?id=${eventdata.event.extendedProps.fk_tut_key}`}
                    >
                      {" "}
                      <CastForEducationIcon />{" "}
                      <span className="name-clr">
                        {eventdata.event.title}
                      </span>{" "}
                    </span>
                  </div>
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formDate"
                >
                  <div>
                    <EventIcon />{" "}
                    {Moment(eventdata.event.extendedProps.ses_date).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formTime"
                >
                  <div>
                    <AccessTimeFilledIcon />{" "}
                    {Moment(
                      eventdata.event.extendedProps.sest_start_time,
                      "hh:mm:ss"
                    ).format("LT")}{" "}
                    -{" "}
                    {Moment(
                      eventdata.event.extendedProps.ses_end_time,
                      "hh:mm:ss"
                    ).format("LT")}
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center gap-3"
                  controlId="formTime"
                >
                  <div
                    className="cursor-pointer card-cus-height "
                    onClick={() => {
                      setEditDTShow(true);
                      setEditData(eventdata);
                    }}
                  >
                    <EditNoteIcon /> Edit Session
                  </div>
                </Form.Group>
              </div>
            </Modal.Header>
            <Modal.Body className="pt-2 p-15 rounded-0">
              <Row>
                {/* <Row> */}
                {/* <Col sm={12} lg={4}>
            
            <Form.Group className="d-flex align-item-center gap-3 mb-3" controlId="formTutor">
              <div>
                <NavLink className="text-dark" to={`/edit-tutor?id=${eventdata.event.extendedProps.fk_tut_key}`} > <CastForEducationIcon/> {eventdata.event.title}</NavLink> 
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} lg={4} className="mb-3"> 
            <Form.Group className="d-flex align-item-center gap-3" controlId="formDate">
              <div> 
                <EventIcon/> {Moment(eventdata.event.extendedProps.ses_date).format('DD-MM-YYYY')}
              </div>
            </Form.Group> 
          </Col>
          <Col sm={12} lg={4}>
            <Form> 
              <Form.Group className="d-flex align-item-center gap-3" controlId="formTime">   
               <div>
                  <AccessTimeFilledIcon/> {Moment(eventdata.event.extendedProps.sest_start_time, "hh:mm:ss").format('LT')}
                </div>
              </Form.Group>
            </Form>
          </Col> */}
                {/* </Row> */}
                {valTutSess}
                <div className="row">
                  {vaMSSess}
                  {msFooter}
                </div>

                {/* <div className="row">
              {cardParentSlots}
              {slotfooter}
            </div>
          */}
              </Row>
            </Modal.Body>
          </>
        );
      }
    };

    setTutSessDetailsData(dataTutor);
    setModalShow2(true);
    // setMSDetailsData([]);
    // setIsmsSubmitting(false);
  }

  function onHandulerMissedSession(msData) {
    // console.log(msData);
    // console.log(data_MS);
    setMSDetailsData((prevData) => [...prevData, msData]);
    setIsmsSubmitting(true);
  }
  //console.log(data_MS);
  // function onHandulerDBParent(DBParentData) {
  //

  //   // const msData1 = {
  //   //   ...msData,
  //   //   forfeitValue: 1
  //   // };

  //
  //   //setMSDetailsData([msData1]);
  //   setDBParentData(prevData => [...prevData, DBParentData]);
  //   setIsDBParentSubmitting(true);
  // }

  const validationSchema4 = yup.object().shape({
    status: yup.mixed().required("Status is required."),
  });

  const initialValues4 = {
    fk_cent_key: sessionStorage.getItem("key"),
    attendance_key: editStatus
      ? editStatus.attendance_key != null && editStatus.attendance_key != ""
        ? editStatus.attendance_key
        : ""
      : "",
    status: editStatus
      ? editStatus.attendance_status != undefined
        ? editStatus.attendance_status
        : 0
      : 0,

    updated_by: sessionStorage.getItem("key"),
  };

  const formik4 = useFormik({
    initialValues: initialValues4,
    validationSchema: validationSchema4,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingEdit(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "EditAttendanceStatus",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Attendance status has been changed.", {
              autoClose: 2000, // 3 seconds in this example
            });

            // fetchData(startDate,endDate);
            //resetForm();
            setModalShowEdit(false);
            setTimeout(function () {
              window.location.reload(false);
            }, 2000);

            setIsSubmittingEdit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update status. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingEdit(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update status. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingEdit(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingEdit(false); // Re-enable the submit button
      } finally {
        setIsSubmittingEdit(false); // Re-enable the submit button
      }
    },
  });
  function onSubmitRemoveNotes(noteskey) {
    setIsnoteDelSubmitting(true);

    const responseforfeit = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "deleteNotes",
        noteskey: noteskey,
      })
      .then((responseforfeit) => {
        // Handle the successful response here
        toast.success("Success! Your note have been deleted.", {
          autoClose: 3000, // 3 seconds in this example
        });

        // handleTutSessClick(data_forfeit);
        // setTimeout(function () {
        //   window.location.reload(false);

        // }, 3000);
        fetchNotesData();
        setIsnoteDelSubmitting(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete notes. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsnoteDelSubmitting(false);
      });
  }

  function onSubmitDefer() {
    setIsDeferSubmitting(true);

    const responseforfeit = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "createCancelSessionTCMS",
        stuky: data_defercancel.fk_stu_key,
        sestky: data_defercancel.fk_sest_key,
        sesky: data_defercancel.seskey,
        pk_enrol_key: data_defercancel.pk_enrol_key,
      })
      .then((responsedefer) => {
        if (responsedefer.data.data === 1) {
          setDeferShow(false);
          setIsDeferSubmitting(false);

          // Handle the successful response here
          toast.success("Success! Defer session updated successfully", {
            autoClose: 3000, // 3 seconds in this example
          });

          // handleTutSessClick(data_forfeit);
          setTimeout(function () {
            window.location.reload(false);
          }, 3000);
          setIsDeferSubmitting(false);
        } else {
          // Handle any errors that occurred during the request
          toast.error(
            "Failure! Unable to process cencel session. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsDeferSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to cencel session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsDeferSubmitting(false);
      });
  }

  function onSubmitForfeit(type) {
    setIsForfeitSubmitting(true);
    const responseforfeit = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "changeStudentEnrolmentForfeitWriteoff",
        pk_enrol_key: data_forfeit[0].pk_enrol_key,
        fw_session_date: data_forfeit[0].sDate,
        fw_session_time: data_forfeit[0].sTime,
        stuky: data_forfeit[0].fk_student_key,
        sestky: data_forfeit[0].fk_sest_key,
        fk_ses_key: data_forfeit[0].fk_ses_key,
        fw_type: type,
      })
      .then((responseforfeit) => {
        setForfeitShow(false);
        setIsForfeitSubmitting(false);

        // Handle the successful response here
        toast.success(
          "Success! Session has been " +
            (type == 1 ? "forfeit " : type == 2 ? "writeoff" : "") +
            " successfully.",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );

        // handleTutSessClick(data_forfeit);
        setTimeout(function () {
          window.location.reload(false);
        }, 3000);
        setIsForfeitSubmitting(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to process forfeit or writeoff. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );

        setIsForfeitSubmitting(false);
      });
  }

  function onSubmitMSForfeit(eventdata) {
    setIsMSForfeitSubmitting(true);
    const responseforfeit = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "ResignSessionArray",

        pk_ses_key: eventdata.event.extendedProps.pk_ses_key,
        fk_sest_key: eventdata.event.extendedProps.fk_sest_key,
        fk_tut_key: eventdata.event.extendedProps.fk_tut_key,
        ses_date: eventdata.event.extendedProps.ses_date,
        sest_start_time: eventdata.event.extendedProps.sest_start_time,
        data: data_MS,
      })
      .then((responseMs) => {
        if (responseMs.data.data == 1) {
          // Handle the successful response here
          toast.success("Success! Reassign session has been successfully.", {
            autoClose: 3000, // 3 seconds in this example
          });

          // handleTutSessClick(data_forfeit);
          setTimeout(function () {
            window.location.reload(false);
            setMSForfeitShow(false);
            setIsMSForfeitSubmitting(false);
            fetchMSData();
            setMSDetailsData([]);
            //fetchData('2');
          }, 3000);
        } else {
          toast.error("Failure! Unable to process reassign. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsMSForfeitSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to process reassign. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsMSForfeitSubmitting(false);
      });
  }

  function onSubmitDeferRequest() {
    setIsDeferRequestSubmitting(true);

    axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "ApproveDeferRequest",
        cancel_session_key: data_DR.cancel_session_key,
        fk_student_key: data_DR.fk_student_key,
        fk_ses_key: data_DR.fk_ses_key,
      })
      .then((responseDRApprove) => {
        if (responseDRApprove.data.data == 1) {
          setDeferRequestShow(false);
          setIsDeferRequestSubmitting(false);

          // Handle the successful response here
          toast.success("Success! Approved the defer request", {
            autoClose: 3000, // 3 seconds in this example
          });

          // handleTutSessClick(data_forfeit);
          setTimeout(function () {
            window.location.reload(false);

            // fetchDRData();
            // fetchMSData();
            setIsDeferRequestSubmitting(false);
            setDRData([]);
          }, 2000);
        } else {
          toast.error("Failure! Unable to defer approve. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });
          setIsDeferRequestSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to defer approve. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsDeferRequestSubmitting(false);
      });
  }

  function onSubmitDeferRequestDisApprove() {
    setIsDeferRequestDisApproveSubmitting(true);

    axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "DisApproveDeferRequest",
        cancel_session_key: data_DR.cancel_session_key,
        fk_student_key: data_DR.fk_student_key,
        fk_ses_key: data_DR.fk_ses_key,
      })
      .then((responseDRDisApprove) => {
        if (responseDRDisApprove.data.data == 1) {
          setDeferRequestDisApproveShow(false);
          setIsDeferRequestDisApproveSubmitting(false);

          // Handle the successful response here
          toast.success("Success! Disapproved the defer request", {
            autoClose: 3000, // 3 seconds in this example
          });

          // handleTutSessClick(data_forfeit);
          setTimeout(function () {
            window.location.reload(false);

            setIsDeferRequestDisApproveSubmitting(false);
            setDRData([]);
          }, 2000);
        } else {
          toast.error("Failure! Unable to defer disapprove. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });
          setIsDeferRequestDisApproveSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to defer disapprove. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsDeferRequestDisApproveSubmitting(false);
      });
  }

  const validationSchema1 = yup.object().shape({
    fk_tut_key: yup.mixed().required("Tutor  is required."),
    ses_date: yup
      .string()
      .test("is-valid-date", "Please provide a valid date.", function (value) {
        const parsedDate = dayjs(value, "YYYY-MM-DD");
        return parsedDate.isValid();
      })
      .required("Session date is required."),
    ses_start_time: yup
      .string()
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return dayjs(value, "HH:mm:ss").isValid();
      })
      .required("Start Time is required."),
    ses_end_time: yup
      .string()
      .required("End Time  is required.")
      .test(
        "is-valid-time",
        "End Time must be after Start Time.",
        function (value) {
          const ses_start_time = Moment(this.parent.ses_start_time, "HH:mm");
          const ses_end_time = Moment(value, "HH:mm");
          return ses_end_time.isAfter(ses_start_time);
        }
      )
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return dayjs(value, "HH:mm:ss").isValid();
      }),
  });

  const initialValues1 = {
    pk_ses_key:
      EventData &&
      EventData.event &&
      EventData.event.extendedProps.pk_ses_key &&
      EventData.event.extendedProps.pk_ses_key != ""
        ? EventData.event.extendedProps.pk_ses_key
        : "",
    fk_tut_key:
      EventData &&
      EventData.event &&
      EventData.event.extendedProps.fk_tut_key &&
      EventData.event.extendedProps.fk_tut_key != ""
        ? EventData.event.extendedProps.fk_tut_key
        : "",
    ses_date:
      EventData &&
      EventData.event &&
      EventData.event.extendedProps.ses_date &&
      EventData.event.extendedProps.ses_date != ""
        ? EventData.event.extendedProps.ses_date
        : "",
    ses_start_time:
      EventData &&
      EventData.event &&
      EventData.event.extendedProps.sest_start_time &&
      EventData.event.extendedProps.sest_start_time != ""
        ? EventData.event.extendedProps.sest_start_time
        : "",
    ses_end_time:
      EventData &&
      EventData.event &&
      EventData.event.extendedProps.ses_end_time &&
      EventData.event.extendedProps.ses_end_time != ""
        ? EventData.event.extendedProps.ses_end_time
        : "",
    ses_duration: "",
    updated_by: sessionStorage.getItem("key"),
  };

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsEditDTSubmitting(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateSessionDateTime_new",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Session has been updated successfully.", {
              autoClose: 3000, // 3 seconds in this example
            });
            setTimeout(function () {
              window.location.reload(false);
            }, 2000);
            // setShow(false);

            // resetForm();
            // toggleModal1();

            setIsEditDTSubmitting(false); // Re-enable the submit button
          } else if (response.data.data === 2) {
            toast.error(
              "This tutor already has session on these date and time.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsEditDTSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update session. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsEditDTSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsEditDTSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsEditDTSubmitting(false); // Re-enable the submit button
      } finally {
        setIsEditDTSubmitting(false); // Re-enable the submit button
      }
    },
  });

  function onSubmitReEnrol(eventdata) {
    setReEnrolSubmitting(true);
    const responseReEnrol = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "ReEnrolStudent",
        pk_enrol_key: SelectStudentDetail.pk_enrol_key,
        fk_sest_key: SelectStudentDetail.fk_sest_key,
        fk_stu_key: SelectStudentDetail.fk_stu_key,
        reenrol_fk_sest_key: ReEnrolSelectSession.pk_sest_key,
        ses_date: SelectStudentDetail.sdate,
      })
      .then((responseReEnrol) => {
        if (responseReEnrol.data && parseInt(responseReEnrol.data.data) == 1) {
          // Handle the successful response here
          toast.success("Success! Student has been re-enrolled successfully.", {
            autoClose: 3000, // 3 seconds in this example
          });

          // handleTutSessClick(data_forfeit);
          setTimeout(function () {
            // window.location.reload();
            window.location.reload(false);
          }, 3000);
        } else {
          toast.error("Failure! Unable to process reenrol. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setReEnrolSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to process reenrol. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setReEnrolSubmitting(false);
      });
  }

  // function MyVerticallyCenteredModal2(props) {

  //   const descriptionList ='';
  //        const descriptiondata = data_tutsess.description;

  //   // const description = data_tutsess.extendedProps.description;
  //   // // const tut_profile_pic = info.event.extendedProps.tut_profile_pic;

  //   // const descriptionList =
  //   //   Array.isArray(description) && description.length > 0
  //   //     ? description.map((number) => {
  //   //       if (number.fcount == 1) {

  //   //         return (<span key={number.id}>
  //   //           {/* fcount
  //   //             wcount */}

  //   //           <del>
  //   //             {number.st_first_name} {number.st_surname} (
  //   //             {number.sub_abbreviation.substring(0, 3)} - {number.st_year_level})
  //   //           </del>
  //   //           <br />
  //   //         </span>);
  //   //       }
  //   //       else {
  //   //         return (<span key={number.id}>
  //   //           {/* fcount
  //   //             wcount */}

  //   //           {number.st_first_name} {number.st_surname} (
  //   //           {number.sub_abbreviation.substring(0, 3)} - {number.st_year_level})

  //   //           <br />
  //   //         </span>);
  //   //       }
  //   //     })
  //   //     : 'N/A';

  //   return (

  //   );
  // }
  const onchangecheckOP = (event) => {
    setcheckOP(event.target.value); //checkOP
    fetchData(event.target.value);
    if (event.target.value == 2) {
      fetchOPHData();
    } else {
      setOPHRecords([]);
    }
  };

  const handleDatesSet = (dateInfo) => {
    setCurrentViewdata(dateInfo);
    setCalFromDate(dateInfo.startStr);
    setCalToDate(dateInfo.endStr);

    // Add logic here to fetch data with Axios based on the dateInfo
  };

  useEffect(() => {
    // This will run whenever fromDate or toDate changes
    if (CalfromDate && CaltoDate) {
      fetchData(checkOP);
    }
  }, [CalfromDate, CaltoDate]);

  const toggleRightSidebar = () => {
    setIsRightOpen(!isRightOpen);
  };

  const handleStuViewClick = async (e) => {
    if (e.stu_visibility == 1) {
      navigate(`/view-student`, {
        state: { pk_student_key: `${e.fk_stu_key}` },
      });
    } else {
      toast.error("Sorry! Allow Only active Student.", {
        autoClose: 3000, // 3 seconds in this example
      });
    }
    // navigate(`/view-student`, { state: { pk_student_key: `${e.fk_stu_key}` } });
  };
  const handleStuDSViewClick = async (e) => {
    if (e.is_diagnostics == 1 && (e.is_student != null || e.is_student == 1)) {
      if (e.stu_visibility == 1) {
        navigate(`/view-student`, {
          state: { pk_student_key: `${e.fk_student_key}` },
        });
      } else {
        toast.error("Sorry! Allow Only active Student.", {
          autoClose: 3000, // 3 seconds in this example
        });
      }
    } else {
      navigate(`/Diagnostics`);
    }
  };
  const handleTutViewClick = async (e) => {
    navigate(`/edit-tutor`, { state: { pk_tut_key: `${e.fk_tut_key}` } });
  };

  const handleDSViewClick = async (e) => {
    navigate(`/Diagnostics`);
  };
  const calendarRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // Current date
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Function to handle the custom "Current Date" button click
  const handleCurrentDateClick = () => {
    setShowDatePicker(!showDatePicker); // Toggle the date picker visibility
  };

  // Function to handle date selection from the date picker
  const handleDateSelect = (date) => {
    if (date) {
      setShowDatePicker(false);

      // const datepicked = date.toISOString();
      const localDate = new Date(date);
      const datepicked = format(localDate, "yyyy-MM-dd HH:mm:ss", {
        timeZone: "UTC",
      });
      setSelectedDate(datepicked);
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(datepicked);
    } else {
      // Handle invalid date scenario here
      console.error("Invalid date selected");
    }
  };

  return (
    <div className="">
      <ToastContainer position="top-center" />

      <Modal
        show={IsShowModalGuide}
        onHide={() => setShowModalGuide(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="page-title">Guide</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* --------------Date--------------- */}
          <div className="userguide_video-wrapper">
            {/* <video src={BASE_URL+'API/guide/scenario_1.mp4'}
              frameborder='0'
              allow='autoplay; encrypted-media'
              allowfullscreen
              controls
              controlsList="nodownload"
              title='video'>
        </video>  */}

            <iframe
              src={BASE_URL + "API/guide/scenario_1.mp4"}
              frameborder="0"
              allow="autoplay; encrypted-media"
              // allowfullscreen
              title="video"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setShowModalGuide(false)}
            >
              Close
            </Button>
            {/* <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button> */}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={IsShowModalGuide2}
        onHide={() => setShowModalGuide2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="page-title">Guide</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* --------------Date--------------- */}
          <div className="userguide_video-wrapper">
            {/* <video src={BASE_URL+'API/guide/scenario_1.mp4'}
              frameborder='0'
              allow='autoplay; encrypted-media'
              allowfullscreen
              controls
              controlsList="nodownload"
              title='video'>
        </video>  */}

            <iframe
              src={BASE_URL + "API/guide/scenario_1.mp4"}
              frameborder="0"
              allow="autoplay; encrypted-media"
              // allowfullscreen
              title="video"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setShowModalGuide2(false)}
            >
              Close
            </Button>
            {/* <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button> */}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form autoComplete="off" onSubmit={formik4.handleSubmit} ref={formRef}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="page-title">Edit Status </h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* --------------Date--------------- */}
            <Row>
              <div className="cus-pyt-dtls">
                <h6 className="mb-0">Student Name :</h6>
                <p className="mb-0">
                  {editStatus
                    ? editStatus.st_first_name != ""
                      ? editStatus.st_first_name + " " + editStatus.st_surname
                      : ""
                    : ""}
                </p>
              </div>
              {/* <div className="cus-pyt-dtls">
                <h6 className='mb-0'>Session :</h6>
                <p className="mb-0">
                  {editStatus?editStatus.sest_name!=''? editStatus.sest_name : '' : ''}
                </p>
              </div> */}
              <Col lg="12" md="12" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Status</h6>
                <Select
                  menuPlacement="auto"
                  options={options_attendance_status}
                  name="status"
                  //value={options_yearlevel.find(option => option.value && formik1.values.la_maths_year_level && option.value.toString() === formik1.values.la_maths_year_level.toString())}
                  value={options_attendance_status.find(
                    (option) =>
                      option.value &&
                      formik4.values.status != undefined &&
                      option.value.toString() ==
                        formik4.values.status.toString()
                  )}
                  // value={formik1.values.la_maths_year_level}
                  id="status"
                  onChange={(selectedOption) => {
                    const event = {
                      target: {
                        name: "status",
                        value: selectedOption["value"],
                      },
                    };
                    formik4.handleChange(event);
                  }}
                  onBlur={(event) => {
                    formik4.handleBlur(event);
                    formik4.setTouched({ ...formik4.touched, status: true });
                  }}
                  className={
                    formik4.errors.status && formik4.touched.status
                      ? "input-error"
                      : ""
                  }
                />
                {formik4.errors.status && formik4.touched.status && (
                  <span className="error">{formik4.errors.status}</span>
                )}
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <div className="mx-auto d-flex gap-2">
              <Button
                variant="contained"
                className="primary-gray"
                onClick={() => setModalShowEdit(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="primary-filled "
                disabled={isSubmittingEdit}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* --------------Page Start--------------- */}

      <Modal
        show={showEditDT}
        variant="danger"
        size="lg"
        style={{ zIndex: "1111" }}
      >
        <Modal.Header>Edit Session</Modal.Header>
        <Form onSubmit={formik1.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col lg="6" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Tutor</h6>
                <Select
                  menuPlacement="auto"
                  options={options_tutor}
                  name="fk_tut_key"
                  id="fk_tut_key"
                  value={options_tutor.find(
                    (option) =>
                      option.value &&
                      formik1.values.fk_tut_key &&
                      option.value.toString() ===
                        formik1.values.fk_tut_key.toString()
                  )}
                  onChange={(selectedOption) => {
                    const event = {
                      target: {
                        name: "fk_tut_key",
                        value: selectedOption["value"],
                      },
                    };
                    formik1.handleChange(event);
                  }}
                  onBlur={(event) => {
                    formik1.handleBlur(event);
                    formik1.setTouched({
                      ...formik1.touched,
                      fk_tut_key: true,
                    });
                  }}
                  className={
                    formik1.errors.fk_tut_key && formik1.touched.fk_tut_key
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.fk_tut_key && formik1.touched.fk_tut_key && (
                  <p className="error">{formik1.errors.fk_tut_key}</p>
                )}
              </Col>
              <Col lg="6" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Session Date</h6>
                {/* <Form.Control type="date"
                  value={formik1.values.ses_date}
                  onBlur={formik1.handleBlur}
                  min={formik1.values.ses_date}
                  onChange={(value) => {
                    const event = {
                      target: {
                        name: "ses_date",
                        value: value.target["value"],
                      },
                    };
                   
                    formik1.handleChange(event);


                  }}
                  id="ses_date"
                  name="ses_date"
                  className={formik1.errors.ses_date && formik1.touched.ses_date ? "input-error" : ""} /> */}

                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    views={["year", "month", "day"]}
                    value={dayjs(formik1.values.ses_date, "YYYY-MM-DD")}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "ses_date",
                          value:
                            date != null
                              ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                              : date,
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        ses_date: true,
                      });
                    }}
                    name="ses_date"
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "ses_date",
                      },
                    }}
                    className={
                      formik1.errors.ses_date && formik1.touched.ses_date
                        ? "input-error w-100"
                        : "w-100"
                    }
                    // minDate={
                    //   EventData && EventData.event && EventData.event.extendedProps.ses_date && EventData.event.extendedProps.ses_date != '' && dayjs(EventData.event.extendedProps.ses_date, 'YYYY-MM-DD').isBefore(dayjs())
                    //   ? dayjs(EventData.event.extendedProps.ses_date, 'YYYY-MM-DD')
                    //   : dayjs()}
                    minDate={dayjs()}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
                {formik1.errors.ses_date && formik1.touched.ses_date && (
                  <span className="error">{formik1.errors.ses_date}</span>
                )}
              </Col>

              <Col lg="6" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Start Time</h6>
                {/* <Form.Control type="time" value={formik1.values.ses_start_time}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="ses_start_time"
                  className={formik1.errors.ses_start_time && formik1.touched.ses_start_time ? "input-error" : ""}
                /> */}
                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    value={dayjs(formik1.values.ses_start_time, "HH:mm:ss")}
                    onChange={(time) => {
                      const event = {
                        target: {
                          name: "ses_start_time",
                          value: dayjs(time, "HH:mm:ss").format("HH:mm:ss"),
                        },
                      };

                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        ses_start_time: true,
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "ses_start_time",
                      },
                    }}
                    className={
                      formik1.errors.ses_start_time &&
                      formik1.touched.ses_start_time
                        ? "input-error w-100 h-auto"
                        : "w-100 h-auto"
                    }
                  />
                </LocalizationProvider>
                {formik1.errors.ses_start_time &&
                  formik1.touched.ses_start_time && (
                    <span className="error ">
                      {formik1.errors.ses_start_time}
                    </span>
                  )}
              </Col>
              {/* --------------End Time--------------- */}

              <Col lg="6" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">End Time</h6>
                {/* <Form.Control type="time" value={formik1.values.ses_end_time}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="ses_end_time"
                  className={formik1.errors.ses_end_time && formik1.touched.ses_end_time ? "input-error" : ""}
                /> */}
                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    value={dayjs(formik1.values.ses_end_time, "HH:mm:ss")}
                    onChange={(time) => {
                      const event = {
                        target: {
                          name: "ses_end_time",
                          value: dayjs(time, "HH:mm:ss").format("HH:mm:ss"),
                        },
                      };

                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        ses_end_time: true,
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "ses_end_time",
                      },
                    }}
                    className={
                      formik1.errors.ses_end_time &&
                      formik1.touched.ses_end_time
                        ? "input-error w-100 h-auto"
                        : "w-100 h-auto"
                    }
                  />
                </LocalizationProvider>
                {formik1.errors.ses_end_time &&
                  formik1.touched.ses_end_time && (
                    <span className="error ">
                      {formik1.errors.ses_end_time}
                    </span>
                  )}
              </Col>
              <Col
                lg="6"
                md="6"
                sm="12"
                className="position-relative"
                style={{ display: "none" }}
              >
                <h6 className="form-lable required-field">Duration</h6>
                <Form.Control
                  type="text" // Use "text" type for displaying duration
                  id="ses_duration"
                  value={
                    formik1.values.ses_end_time && formik1.values.ses_start_time
                      ? Moment.utc(
                          Moment(formik1.values.ses_end_time, "HH:mm").diff(
                            Moment(formik1.values.ses_start_time, "HH:mm")
                          )
                        ).format("HH:mm") // Format the duration as HH:mm
                      : "00.00"
                  }
                  readOnly // Make the input field read-only
                  className={
                    formik1.errors.ses_duration && formik1.touched.ses_duration
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.ses_duration &&
                  formik1.touched.ses_duration && (
                    <p className="error">{formik1.errors.ses_duration}</p>
                  )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="mx-auto d-flex gap-2">
              <Button
                variant="contained"
                className="primary-gray"
                onClick={() => setEditDTShow(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="primary-filled "
                disabled={iSEditDTSubmitting}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showDeferRequest}
        variant="danger"
        style={{ zIndex: "1111" }}
      >
        <Modal.Header>Defer request</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want to approve this request?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setDeferRequestShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isdeferRequestSubmitting}
              onClick={() => onSubmitDeferRequest()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeferRequestDisApprove}
        variant="danger"
        style={{ zIndex: "1111" }}
      >
        <Modal.Header>Defer request</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want to disapprove this request?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setDeferRequestDisApproveShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isdeferRequestDisApproveSubmitting}
              onClick={() => onSubmitDeferRequestDisApprove()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showMSForfeit} variant="danger" style={{ zIndex: "1111" }}>
        <Modal.Header>Reassign Session</Modal.Header>

        <Modal.Body>
          <p className="mb-0">
            Are you sure? You want to this reassign session?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setMSForfeitShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isMSforfeitSubmitting}
              onClick={() => onSubmitMSForfeit(dataNewEvent)}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="rounded-0"
      >
        {data_tutsess}
        {/* <Modal.Footer className=' justify-content-between'>

          <Stack gap={2} className="">
            <div className="text-end">

              <Button variant="contained" className="primary-gray me-2" onClick={() => setForfeitShow(false)}>Cancel</Button>
              <Button type="submit" variant="contained" className="primary-filled " onClick={() => onSubmitForfeit(dataforfeit.type)} >Save</Button>
            </div>
          </Stack>

        </Modal.Footer> */}
      </Modal>

      <Modal show={showDefer} variant="danger" style={{ zIndex: "1111" }}>
        <Modal.Header>Defer a Session</Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to defer this session?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setDeferShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isdeferSubmitting}
              onClick={() => onSubmitDefer()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showForfeit} variant="danger" style={{ zIndex: "1111" }}>
        <Modal.Header>
          {data_forfeit.type == 1
            ? "Forfeit "
            : data_forfeit.type == 2
            ? "Writeoff"
            : ""}
        </Modal.Header>

        <Modal.Body>
          <p className="mb-0">
            Are you sure? You want to{" "}
            {data_forfeit.type == 1
              ? "forfeit "
              : data_forfeit.type == 2
              ? "writeoff"
              : ""}{" "}
            this session?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setForfeitShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isforfeitSubmitting}
              onClick={() => onSubmitForfeit(data_forfeit.type)}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal_event}
        onHide={() => setShowModal_event(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="page-title"> Event </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* --------------Date--------------- */}
          <Row>
            <div className="ten columns col-12">
              <ul className="">
                <li>Title : {selectedEvent ? selectedEvent.title : ""}</li>
                <li>
                  Session Name :{" "}
                  {selectedEvent ? selectedEvent.extendedProps.sest_name : ""}
                </li>
                <li>
                  Session Time :{" "}
                  {selectedEvent
                    ? Moment(
                        selectedEvent.extendedProps.sest_start_time,
                        "HH:mm:ss"
                      ).format("hh:mm a")
                    : ""}
                </li>
                <li>
                  Session Type :{" "}
                  {selectedEvent ? selectedEvent.extendedProps.sest_type : ""}
                </li>
                <li>Student List :</li>
                <ul className="">
                  {selectedEvent
                    ? selectedEvent.extendedProps.description.map((number) => {
                        number.fcount == 1 ||
                        number.wcount == 2 ||
                        number.cancel_status == 0 ||
                        number.cancel_status == 1 ? (
                          <li>
                            <del>
                              {Capitalize(number.st_first_name)}{" "}
                              {number.st_surname}{" "}
                            </del>
                          </li>
                        ) : (
                          <li>
                            {Capitalize(number.st_first_name)}{" "}
                            {number.st_surname}{" "}
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </ul>
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setShowModal_event(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={ReEnrolConfirm} variant="danger" style={{ zIndex: "1114" }}>
        <Modal.Header>Re-enrol Session</Modal.Header>

        <Modal.Body>
          <p className="mb-0">
            Are you sure? You want to re-enrol{" "}
            {SelectStudentDetail &&
            SelectStudentDetail.st_first_name &&
            SelectStudentDetail.st_surname
              ? "`" +
                SelectStudentDetail.st_first_name +
                " " +
                SelectStudentDetail.st_surname +
                "`"
              : null}{" "}
            in this session?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => {
                setReEnrolConfirm(false);
                toggleReenrol();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isReEnrolSubmitting}
              onClick={() => onSubmitReEnrol()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Reenroll popup start */}
      <Modal
        show={showReenrol}
        onHide={() => {
          toggleReenrol();
          setSelectStudentDetail(null);
          setModalShow2(true);
        }}
        variant="danger"
        style={{ zIndex: "1111" }}
        size="xl"
      >
        <Modal.Header closeButton>
          Reenrol session{" "}
          {SelectStudentDetail &&
          SelectStudentDetail.st_first_name &&
          SelectStudentDetail.st_surname
            ? " - " +
              SelectStudentDetail.st_first_name +
              " " +
              SelectStudentDetail.st_surname
            : null}
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <Box
                sx={{
                  height: "fit-content",
                  width: "100%",
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                  "& .super-app-theme--header": {
                    backgroundColor: "#e3e6ee",
                  },
                }}
                style={{ height: "100%", width: "100%" }}
              >
                <DataGrid
                  //sx={{ border: "none", m: 2 }}
                  autoHeight
                  slots={{
                    loadingOverlay: LinearProgress,
                    //noRowsOverlay: CustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    // toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { fileName: "SessionList" },
                      printOptions: {
                        pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                        disableToolbarButton: true,
                        hideFooter: true,
                        hideToolbar: true,
                        fileName: "SessionList",
                      },
                      records,
                      showQuickFilter: true,
                    },
                  }}
                  initialState={{
                    ...records.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  showQuickFilter={true}
                  pageSizeOptions={[10, 25, 50, 100]}
                  //loading={loading}
                  rows={ReEnrollRecords.map((row, index) => ({
                    ...row,
                    id: `row-${index}`,
                  }))}
                  columns={columnsReenrol}
                  rowHeight={60}
                  density="compact"
                  disableRowSelectionOnClick
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  // onRowClick={(params) =>{ setEditData(params.row); setEditDTShow(true);}}
                />
              </Box>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Reenroll popup end */}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className="page-title"> Calendar </h5>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
              <NavLink underline="hover" color="inherit" to="/calendar">
                {" "}
                Home
              </NavLink>

              <Typography color="text.primary">Calendar</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          {/* <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <div>
                <Button className="primary-gray-outline pxy-6" variant="contained" {...bindTrigger(popupState)}>
                  Attendance
                  <ArrowDropDownIcon className='p-0' />
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close}>Attendance</MenuItem>
                  <MenuItem onClick={popupState.close}>Money Report (by Date)</MenuItem>
                </Menu>
              </div>
            )}
          </PopupState> */}
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <div>
                <Button
                  className="primary-filled pxy-6"
                  variant="contained"
                  {...bindTrigger(popupState)}
                >
                  New
                  <ArrowDropDownIcon className="p-0" />
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => navigate("/add-student")}>
                    Students
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/add-tutor")}>
                    Tutors
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/AddStaff")}>
                    Staff
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/add-lead")}>
                    Leads
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/AddInitialDiagnostics")}>
                    Initial Diagnostics
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/add-session-time")}>
                    Session Times
                  </MenuItem>
                </Menu>
              </div>
            )}
          </PopupState>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <div
            className="calendar-section"
            style={{
              flex: 1,
              width: !isRightOpen ? "100%" : "0", // Adjust the margin when the sidebar is open
              transition: "margin-left 0.3s ease",
              display: !isRightOpen ? "flex" : "unset",
            }}
          >
            <div className="calendar-main">
              <Box>
                <Item sx={{ p: 2 }} className="my-card">
                  <div className="my-loader d-flex justify-content-end">
                    {loading === true ? (
                      <Spinner animation="border" size="sm" />
                    ) : null}
                  </div>
                  <span>
                    <div className="custom-tabs">
                      <label>
                        <input
                          type="radio"
                          value="2"
                          name="filterView"
                          checked={checkOP == "2"}
                          // checked={currentView === 'dayGridMonth'}
                          onChange={onchangecheckOP}
                        />
                        Operating Hours View
                      </label>

                      <label>
                        <input
                          type="radio"
                          value="1"
                          name="filterView"
                          checked={checkOP == "1"}
                          // checked={currentView === 'timeGridWeek'}
                          onChange={onchangecheckOP}
                        />
                        All Hours View
                      </label>
                    </div>
                    <div className="text-end me-3">
                      <b>Lesson</b> : <strong>{lessonCount}</strong>
                    </div>
                  </span>
                  <div className="float-end righttogglediv">
                    {" "}
                    <IconButton
                      className="cus-shrink-btn my-button"
                      variant="contained"
                      aria-label="delete"
                      size="large"
                      sx={{ mr: 2, ...(isRightOpen && { display: "none" }) }}
                      onClick={toggleRightSidebar}
                    >
                      <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      sx={{ mr: 2, ...(!isRightOpen && { display: "none" }) }}
                      className="cus-shrink-btn my-button"
                      onClick={toggleRightSidebar}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <ArrowBackIosNewIcon fontSize="inherit" />
                    </IconButton>
                  </div>

                  {showDatePicker && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        marginTop: "50px",
                        backgroundColor: "white",
                      }}
                    >
                      {/* Wrap the date picker inside LocalizationProvider */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          value={dayjs(selectedDate)}
                          onChange={handleDateSelect}
                          renderInput={() => null}
                          open={showDatePicker}
                        />
                      </LocalizationProvider>
                    </div>
                  )}

                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView={"timeGridWeek"}
                    editable={false}
                    selectable={false}
                    selectMirror={false}
                    dayMaxEvents={true}
                    onClick={() => this.setState({ showModal: true })}
                    displayEventEnd={true}
                    allDaySlot={false}
                    scrollTime={"09:00:00"}
                    timeZone={"UTC"}
                    // views= {{
                    //   dayGrid: {
                    //     // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                    //   },
                    //   timeGrid: {
                    //     // options apply to timeGridWeek and timeGridDay views
                    //   },
                    //   week: {
                    //     // options apply to dayGridWeek and timeGridWeek views
                    //   },
                    //   day: {
                    //     // options apply to dayGridDay and timeGridDay views
                    //   }}

                    headerToolbar={{
                      left: "prev,next today currentDateButton",

                      center: "title",
                      // right: 'timeGridDay,timeGridWeek',
                      start: "today prev,next",
                      center: "title",
                      end: "timeGridWeek,timeGridDay",
                    }}
                    customButtons={{
                      currentDateButton: {
                        text: moment(selectedDate).isValid()
                          ? moment(selectedDate).format("DD MMMM YYYY")
                          : "Invalid Date",
                        click: handleCurrentDateClick, // When clicked, navigate to the month view and the current date
                      },
                    }}
                    dayHeaderContent={(args) => {
                      return Moment(args.date).format("ddd DD/MM");
                    }}
                    datesSet={handleDatesSet}
                    viewDidMount={(viewInfo) => {
                      // Update the current view when it changes
                      setCurrentView(viewInfo.view.type);
                    }}
                    slotEventOverlap="false"
                    slotMinTime={ophdata.sttime ? ophdata.sttime : "00:00:00"}
                    slotMaxTime={ophdata.endtime ? ophdata.endtime : "24:00:00"}
                    events={records.map((event) => ({
                      ...event,
                      textColor: event.textColor || "black", // Default text color is black
                      borderColor: event.backgroundColor || "black", // Default text color is black
                      borderRadius: "25px", // Default text color is black
                      padding: "12px",
                    }))}
                    hiddenDays={hiddenDays}
                    eventContent={eventContent}
                    eventMouseEnter={handleEventMouseEnter}
                    eventMouseLeave={handleEventMouseLeave}
                    dayMaxEventRows={3} // Adjust the number of events displayed
                    eventDidMount={(info) => {
                      //  console.log("info", info)
                      if (info.event._context.calendarApi.currentData) {
                        const current_date =
                          info.event._context.calendarApi.currentData
                            .currentDate;
                        console.log("demo", current_date);
                        setSelectedDate(current_date);
                      }
                      // Note :if section id diagnostic event else existion system
                      if (
                        info.event.extendedProps.isschedule &&
                        parseInt(info.event.extendedProps.isschedule) === 1
                      ) {
                        const description =
                          info.event.extendedProps.description;
                        // info.event.extendedProps.sest_class
                        const cls_type = "cls_blue";
                        const cls_time_data =
                          info.event.extendedProps.sest_start_time != null
                            ? Moment(
                                info.event.extendedProps.sest_start_time,
                                "HH:mm:ss"
                              ).format("hh:mm a")
                            : "-";
                        // const cls_typestar = info.event.extendedProps.sest_class;

                        let abborwords = ""; // Use 'let' instead of 'const' to allow reassignment
                        const descriptionList =
                          Array.isArray(description) && description.length > 0
                            ? '<ul class="">' +
                              description
                                .map((number) => {
                                  // var abbreviation ='';
                                  // var words ='';
                                  // if (number.st_year_level != '' && number.st_year_level != null) {

                                  // words = number.st_year_level.split(/(\d+)/).filter(Boolean).map((element, index) => index === 0 ? element.charAt(0) : element);
                                  // abbreviation = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() : word)).join('');
                                  // }
                                  // if (abbreviation) {
                                  //   abborwords = abbreviation; // Update the 'abborwords' value if an abbreviation exists
                                  // } else {
                                  //   abborwords = words; // Update the 'abborwords' value with the full word if no abbreviation is found
                                  // }(number.is_diagnostics == 1 && parseInt(number.is_diagnostics_close)== 1 && (number.is_student == null || number.is_student != 1) ) ? <div className="cus-danger  ms-4">Closed</div> :(number.is_diagnostics == 1 && (number.is_student == null || number.is_student != 1) )? <div className="cus-warning ms-4 ">Diagnostics Completed</div>: (number.is_diagnostics == 1 && (number.is_student != null || number.is_student == 1) )? <div className="cus-warning ms-4 ">Student Converted</div> : <div className="cus-primary ms-4 ">Pending</div>

                                  return (
                                    '<li > <div class="d-flex justify-content-between"> <p class="mb-0">' +
                                    Capitalize(number.st_first_name) +
                                    " " +
                                    number.st_surname +
                                    "</p> " +
                                    (number.is_diagnostics == 1 &&
                                    parseInt(number.is_diagnostics_close) ===
                                      1 &&
                                    (number.is_student == null ||
                                      number.is_student != 1)
                                      ? '<span class="cus-danger">Closed</span>'
                                      : number.is_diagnostics == 1 &&
                                        (number.is_student == null ||
                                          number.is_student != 1)
                                      ? '<span class="cus-warning">Diagnostics Completed</span>'
                                      : number.is_diagnostics == 1 &&
                                        (number.is_student != null ||
                                          number.is_student == 1)
                                      ? '<span class="cus-warning">Student Converted</span>'
                                      : '<span class="cus-primary">Pending</span>') +
                                    " </div></li>"
                                  );
                                })
                                .join("") +
                              "</ul>"
                            : "";

                        return new bootstrap.Popover(info.el, {
                          title:
                            '<div class="d-flex justify-content-between"> <p class="tm-title mb-0' +
                            cls_type +
                            '">' +
                            cls_time_data +
                            '</p> <p class="mb-0">' +
                            info.event.title +
                            '</p><p class="mb-0"> <b class="">  <i class="fa fa-user"></i><b class="p-2">' +
                            description.length +
                            "</b></b></p></div>",
                          placement: "bottom",
                          trigger: "hover",
                          customClass: "popoverStyle_" + cls_type,
                          content:
                            '<div class="' +
                            cls_type +
                            '"<div  ></div><div >' +
                            descriptionList +
                            "</div>",
                          html: true,
                        });
                      } else {
                        const description =
                          info.event.extendedProps.description;
                        // info.event.extendedProps.sest_class
                        const cls_type =
                          info.event.extendedProps.sest_class == 1
                            ? "cls_regul"
                            : info.event.extendedProps.sest_class == 2
                            ? "cls_oto"
                            : info.event.extendedProps.sest_class == 3
                            ? "cls_mus"
                            : "";
                        const cls_typestar =
                          info.event.extendedProps.sest_class;

                        const cls_time_data =
                          info.event.extendedProps.sest_start_time != null
                            ? Moment(
                                info.event.extendedProps.sest_start_time,
                                "HH:mm:ss"
                              ).format("hh:mm a")
                            : "-";

                        let abborwords = ""; // Use 'let' instead of 'const' to allow reassignment

                        const hasReassigned =
                          Array.isArray(description) &&
                          description.some((item) => item.reassigned === 1);

                        const mark_note =
                          hasReassigned || cls_typestar == 3
                            ? '<li > <div class="mt-4"> <span class="cursor-pointer f-10 badge rounded-pill bg-success calender-badge mb-0 text-black"> * - Mark One Off</span></div></li>'
                            : "";

                        const descriptionList =
                          Array.isArray(description) && description.length > 0
                            ? '<ul class="">' +
                              description
                                .map((number) => {
                                  var abbreviation = "";
                                  var words = "";
                                  if (
                                    number.st_year_level != "" &&
                                    number.st_year_level != null
                                  ) {
                                    words = number.st_year_level
                                      .split(/(\d+)/)
                                      .filter(Boolean)
                                      .map((element, index) =>
                                        index === 0
                                          ? element.charAt(0)
                                          : element
                                      );
                                    abbreviation = words
                                      .map((word, index) =>
                                        index === 0
                                          ? word.charAt(0).toUpperCase()
                                          : word
                                      )
                                      .join("");
                                  }
                                  if (abbreviation) {
                                    abborwords = abbreviation; // Update the 'abborwords' value if an abbreviation exists
                                  } else {
                                    abborwords = words; // Update the 'abborwords' value with the full word if no abbreviation is found
                                  }

                                  //setlessonCount((prevCount) => prevCount + number.lesson);
                                  return (
                                    '<li > <div class="d-flex justify-content-between"> <p class="mb-0 ' +
                                    ((parseInt(number.fcount) === 1 ||
                                    parseInt(number.wcount) === 2 ||
                                    parseInt(number.cancel_status) === 2) && parseInt(number.attendance_status) === 6
                                      ? "strike_fw_student"
                                      : "") +
                                    '">' +
                                    ((parseInt(number.reassigned) &&
                                      parseInt(number.reassigned) != null &&
                                      parseInt(number.reassigned) === 1) ||
                                    cls_typestar == 3
                                      ? "*"
                                      : "") +
                                    "" +
                                    Capitalize(number.st_first_name) +
                                    " " +
                                    number.st_surname +
                                    " (" +
                                    abborwords +
                                    "" +
                                    number.sub_abbreviation +
                                    ") </p> " +
                                    (parseInt(number.fcount) === 1 && parseInt(number.attendance_status) === 6
                                      ? '<span class="cus-danger">Forfeit</span>'
                                      : parseInt(number.wcount) === 2 && parseInt(number.attendance_status) === 6
                                      ? '<span class="cus-danger">Writeoff</span>'
                                      : parseInt(number.cancel_status) === 2 && parseInt(number.attendance_status) === 6
                                      ? '<span class="cus-danger">Cancelled</span>'
                                      : parseInt(number.cancel_status) === 0 &&
                                        parseInt(
                                          number.cancel_defer_sources
                                        ) === 1
                                      ? '<span class="cus-warning">Deferment Requested</span>'
                                      : parseInt(number.cancel_status) === 1 &&
                                        parseInt(
                                          number.cancel_defer_sources
                                        ) === 1
                                      ? '<span class="cus-warning">Slot Assign</span>'
                                      : parseInt(number.cancel_status) === 1
                                      ? '<span class="cus-warning">Deferred</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 0
                                      ? '<span class="cus-primary">Pending</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 1
                                      ? '<span class="cus-warning">Ongoing</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 2
                                      ? '<span class="text-success">Attended</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 3
                                      ? '<span class="cus-danger">Missed</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 4
                                      ? '<span class="cus-danger">Offline</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 5
                                      ? '<span class="cus-warning">Deferment Requested</span>'
                                      : parseInt(number.attendance_status) &&
                                        parseInt(number.attendance_status) === 6
                                      ? '<span class="cus-danger">Cancelled</span>'
                                      : '<span class="cus-primary">Pending</span>') +
                                    " </div></li>"
                                  );
                                })
                                .join("") +
                              mark_note +
                              "</ul>"
                            : "";

                        return new bootstrap.Popover(info.el, {
                          title:
                            '<div class="d-flex justify-content-between"> <p class="tm-title mb-0' +
                            cls_type +
                            '">' +
                            cls_time_data +
                            '</p> <p class="mb-0">' +
                            info.event.title +
                            '</p><p class="mb-0"> <b class="">  <i class="fa fa-user"></i><b class="p-2">' +
                            description.length +
                            "</b></b></p></div>",
                          placement: "bottom",
                          trigger: "hover",
                          customClass: "popoverStyle_" + cls_type,
                          content:
                            '<div class="' +
                            cls_type +
                            '"<div ></div><div >' +
                            descriptionList +
                            "</div>",
                          html: true,
                        });
                      }
                    }}
                  />
                </Item>
              </Box>
            </div>

            <div className="calendar-aside">
              <div className={isRightOpen ? "rightSidebar" : ""}>
                <Box
                  className="mb-3"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tooltip title="Defer Requests" arrow>
                      <Tab
                        className="mf-tab"
                        icon={<BookmarkAddIcon />}
                        {...a11yProps(0)}
                      />
                    </Tooltip>
                    <Tooltip title="Deferred Bin" arrow>
                      <Tab
                        className="mf-tab"
                        icon={<BookmarkAddedIcon />}
                        {...a11yProps(1)}
                      />
                    </Tooltip>
                    <Tooltip title="Disapproved" arrow>
                      <Tab
                        className="mf-tab"
                        icon={<BookmarkRemoveIcon />}
                        {...a11yProps(2)}
                      />
                    </Tooltip>
                    <Tooltip title="Notes" arrow>
                      <Tab
                        className="mf-tab"
                        icon={<DescriptionIcon />}
                        {...a11yProps(3)}
                      />
                    </Tooltip>

                    {/* <Tab onClick={() => setModalShow1(true)} className='mf-tab' label="Missed Sessions" {...a11yProps(0)} />
                <Tab onClick={() => setModalShow2(true)} className='mf-tab' label="Notes" {...a11yProps(1)} /> */}
                  </Tabs>
                </Box>
                <div className="my-card bg-white p-2">
                  <div sx={{ width: "100%" }}>
                    <CustomTabPanel value={value} index={0}>
                      <div className="section-heading border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center gap-3">
                        <div>
                          <h6 className="mb-0 d-flex align-items-center gap-2">
                            Defer Requests{" "}
                            <span className="d-flex justify-content-end">
                              <InfoIcon
                                onClick={() => handleUserGuide()}
                                className="float-end icon-blue cursor-pointer"
                              />
                            </span>
                          </h6>
                        </div>
                      </div>
                      <ul className="meeting-list list-unstyled mb-0">
                        {/* recordsMS */}
                        {
                          // st_first_name,st.st_surname,s.ses_date, s.ses_start_time,t.tut_fname,t.tut_surname
                          recordsDR.length > 0 ? (
                            <List
                              className="py-0"
                              sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              {recordsDR.map(
                                (
                                  recorddr,
                                  index // Note the parentheses instead of curly braces
                                ) => (
                                  <div key={index}>
                                    {" "}
                                    {/* Ensure each item has a unique key */}
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        className="my-0"
                                        primary={
                                          <div className="d-flex justify-content-between">
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                              className="text-small"
                                            >
                                              <b className="name-clr">
                                                {" "}
                                                {Capitalize(
                                                  recorddr.st_first_name +
                                                    " " +
                                                    recorddr.st_surname
                                                )}{" "}
                                              </b>
                                            </Typography>
                                          </div>
                                        }
                                        secondary={
                                          <div className="">
                                            <div>
                                              {" "}
                                              {Moment(recorddr.sDate).format(
                                                "DD-MM-YYYY"
                                              )}{" "}
                                              {Moment(
                                                recorddr.sTime,
                                                "HH:mm:ss"
                                              ).format("hh:mm a")}
                                            </div>

                                            <div> {recorddr.subject_name}</div>
                                            <div>
                                              <b>
                                                {" "}
                                                {Capitalize(
                                                  recorddr.tut_fname
                                                ) +
                                                  " " +
                                                  recorddr.tut_surname}
                                              </b>
                                            </div>
                                            <div className=" pt-2  ">
                                              <Button
                                                variant="outlined-red"
                                                className="primary-blue btn-red box-shadow-cus"
                                                style={{ fontSize: "12px" }}
                                                onClick={() =>
                                                  handleDRApproveClick(recorddr)
                                                }
                                              >
                                                Approve
                                              </Button>
                                              <Button
                                                variant="outlined-red"
                                                className="filled-danger btn-red box-shadow-cus"
                                                style={{ fontSize: "12px" }}
                                                onClick={() =>
                                                  handleDisApproveClick(
                                                    recorddr
                                                  )
                                                }
                                              >
                                                Disapprove
                                              </Button>
                                            </div>
                                          </div>
                                        }
                                      />
                                    </ListItem>
                                  </div>
                                )
                              )}
                            </List>
                          ) : (
                            <p>No pending requests.</p>
                          )
                        }
                      </ul>
                      {/* <Link href="#" className='text-center d-block mt-3 text-primary'>70 More</Link> */}
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                      <div className="section-heading border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center gap-3">
                        <div>
                          {/* <h6 className='mb-0 d-flex align-items-center gap-2'>Deferred Bin <span className='badge'>{recordsMS.length}</span>
                          </h6> */}

                          <h6 className="mb-0 d-flex align-items-center gap-2">
                            Deferred Bin{" "}
                            <span className="d-flex justify-content-end">
                              <InfoIcon
                                onClick={() => handleUserGuide2()}
                                className="float-end icon-blue cursor-pointer"
                              />
                            </span>
                          </h6>
                        </div>

                        {/* <div
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <i class="fa-solid fa-sort"></i>
                    </div> */}

                        {/* <Menu
                      className='margin-tp-cl'
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                      <ListSubheader>Sort By</ListSubheader>

                      <MenuItem onClick={handleClose}>Date</MenuItem>
                      <MenuItem onClick={handleClose}>Student</MenuItem>
                      <MenuItem onClick={handleClose}>Tutor</MenuItem>
                      <MenuItem onClick={handleClose}>Subject</MenuItem>
                      <ListSubheader>Filter By</ListSubheader>

                      <MenuItem onClick={handleClose}>Student</MenuItem>
                      <MenuItem onClick={handleClose}>Tutor</MenuItem>

                    </Menu> */}
                      </div>
                      <ul className="meeting-list list-unstyled mb-0">
                        {/* recordsMS */}
                        {
                          // st_first_name,st.st_surname,s.ses_date, s.ses_start_time,t.tut_fname,t.tut_surname
                          recordsMS.length > 0 ? (
                            <List
                              className="py-0"
                              sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              {recordsMS.map(
                                (
                                  recordms,
                                  index // Note the parentheses instead of curly braces
                                ) => (
                                  <div key={index}>
                                    {" "}
                                    {/* Ensure each item has a unique key */}
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        className="my-0"
                                        primary={
                                          <div className="d-flex justify-content-between">
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                              className="text-small"
                                            >
                                              <b className="name-clr">
                                                {" "}
                                                {Capitalize(
                                                  recordms.st_first_name +
                                                    " " +
                                                    recordms.st_surname
                                                )}{" "}
                                                {parseInt(
                                                  recordms.defer_source
                                                ) === 1
                                                  ? "(" +
                                                    recordms.slot_count +
                                                    ")"
                                                  : ""}
                                              </b>
                                              {/* Date:  {Moment(recordms.sDate).format('DD-MM-YYYY')} | Time: {Moment(recordms.sTime, "HH:mm:ss").format('hh:mm a')} */}
                                            </Typography>

                                            {parseInt(recordms.defer_source) ===
                                            2 ? (
                                              ismsSubmitting &&
                                              data_MS.length > 0 &&
                                              data_MS.some(
                                                (item) =>
                                                  item.cancel_session_key ===
                                                  recordms.cancel_session_key
                                              ) ? (
                                                <CheckBoxIcon
                                                  onClick={() =>
                                                    removeRecord(
                                                      recordms.cancel_session_key
                                                    )
                                                  }
                                                  className="float-end icon-black cursor-pointer"
                                                />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon
                                                  onClick={() =>
                                                    onHandulerMissedSession(
                                                      recordms
                                                    )
                                                  }
                                                  className="float-end icon-black cursor-pointer"
                                                  disabled={ismsSubmitting}
                                                />
                                              )
                                            ) : (
                                              <ListAltIcon
                                                onClick={() =>
                                                  handleClick_slot(recordms)
                                                }
                                                className="float-end icon-blue cursor-pointer"
                                              />
                                            )}

                                            {/* {ismsSubmitting && ((data_MS.length > 0) && data_MS.some(item => item.cancel_session_key === recordms.cancel_session_key)) ? <CheckIcon onClick={() => removeRecord(recordms.cancel_session_key)} className="float-end icon-green cursor-pointer" /> : <ArrowForwardIcon disabled={ismsSubmitting} onClick={() => onHandulerMissedSession(recordms)} className="float-end icon-red cursor-pointer" />}  */}
                                          </div>
                                        }
                                        secondary={
                                          <div className="">
                                            <div>
                                              {" "}
                                              {Moment(recordms.sDate).format(
                                                "DD-MM-YYYY"
                                              )}{" "}
                                              {Moment(
                                                recordms.sTime,
                                                "HH:mm:ss"
                                              ).format("hh:mm a")}
                                            </div>

                                            <div> {recordms.subject_name}</div>
                                            <div>
                                              <b>
                                                {" "}
                                                {Capitalize(
                                                  recordms.tut_fname
                                                ) +
                                                  " " +
                                                  recordms.tut_surname}
                                              </b>
                                            </div>
                                            <div className=" pt-2  ">
                                              <Button
                                                variant="outlined-red"
                                                className="primary-blue btn-red box-shadow-cus"
                                                style={{ fontSize: "12px" }}
                                                onClick={() =>
                                                  handleForfeitClick(
                                                    recordsMS,
                                                    1
                                                  )
                                                }
                                              >
                                                Forfeit{" "}
                                              </Button>
                                              <Button
                                                variant="outlined-red"
                                                className="primary-blue btn-green box-shadow-cus"
                                                style={{ fontSize: "12px" }}
                                                onClick={() =>
                                                  handleForfeitClick(
                                                    recordsMS,
                                                    2
                                                  )
                                                }
                                              >
                                                Writeoff{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        }
                                      />
                                    </ListItem>
                                  </div>
                                )
                              )}
                            </List>
                          ) : (
                            <p>No deferred bin available.</p>
                          )
                        }
                      </ul>
                      {/* <Link href="#" className='text-center d-block mt-3 text-primary'>70 More</Link> */}
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={2}>
                      <div className="section-heading border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center gap-3">
                        <div>
                          <h6 className="mb-0 d-flex align-items-center gap-2">
                            Defer Request Disapproved{" "}
                            <span className="badge">
                              {recordsDRDisApproved.length}
                            </span>
                          </h6>
                        </div>
                      </div>
                      <ul className="meeting-list list-unstyled mb-0">
                        {/* recordsMS */}
                        {
                          // st_first_name,st.st_surname,s.ses_date, s.ses_start_time,t.tut_fname,t.tut_surname
                          recordsDRDisApproved.length > 0 ? (
                            <List
                              className="py-0"
                              sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              {recordsDRDisApproved.map(
                                (
                                  recorddr,
                                  index // Note the parentheses instead of curly braces
                                ) => (
                                  <div key={index}>
                                    {" "}
                                    {/* Ensure each item has a unique key */}
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        className="my-0"
                                        primary={
                                          <div className="d-flex justify-content-between">
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                              className="text-small"
                                            >
                                              <b className="name-clr">
                                                {" "}
                                                {Capitalize(
                                                  recorddr.st_first_name +
                                                    " " +
                                                    recorddr.st_surname
                                                )}{" "}
                                              </b>
                                            </Typography>
                                          </div>
                                        }
                                        secondary={
                                          <div className="">
                                            <div>
                                              {" "}
                                              {Moment(recorddr.sDate).format(
                                                "DD-MM-YYYY"
                                              )}{" "}
                                              {Moment(
                                                recorddr.sTime,
                                                "HH:mm:ss"
                                              ).format("hh:mm a")}
                                            </div>

                                            <div> {recorddr.subject_name}</div>
                                            <div>
                                              <b>
                                                {" "}
                                                {Capitalize(
                                                  recorddr.tut_fname
                                                ) +
                                                  " " +
                                                  recorddr.tut_surname}
                                              </b>
                                            </div>
                                            <div className=" pt-2  ">
                                              {/* <Button variant="outlined-red" className="primary-blue btn-red box-shadow-cus" style={{ fontSize: "12px", }} onClick={() => handleDRApproveClick(recorddr)}>Approve</Button> */}
                                            </div>
                                          </div>
                                        }
                                      />
                                    </ListItem>
                                  </div>
                                )
                              )}
                            </List>
                          ) : (
                            <p>No records.</p>
                          )
                        }
                      </ul>
                      {/* <Link href="#" className='text-center d-block mt-3 text-primary'>70 More</Link> */}
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={3} className="">
                      <div className="section-heading border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center gap-3">
                        <div>
                          <h6 className="mb-0 d-flex align-items-center gap-2">
                            Enter a quick memo to be saved
                          </h6>
                        </div>
                      </div>
                      <Form
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        ref={formRef}
                        className="border-bottom pb-2"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={values.nt_notes}
                          onChange={handleChangeNote}
                          onBlur={handleBlur}
                          id="nt_notes"
                          className={
                            errors.nt_notes && touched.nt_notes
                              ? "input-error control_textarea mb-0"
                              : "control_textarea mb-2"
                          }
                        />
                        {errors.nt_notes && touched.nt_notes && (
                          <p className="noteerror">{errors.nt_notes}</p>
                        )}

                        <Stack gap={2} className="">
                          <div className="text-end">
                            <Button
                              type="submit"
                              variant="contained"
                              className="primary-filled  btnmr-2 my-auto"
                              style={{ padding: "4px" }}
                              disabled={isSubmitting}
                            >
                              Save Note
                            </Button>
                          </div>
                        </Stack>
                      </Form>

                      <div className="cus-notes-height">
                        <h6 className="mt-2">Notes</h6>
                        {recordsNotes.length > 0 ? (
                          <List
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            {recordsNotes.map((record, index) => {
                              // Note the parentheses instead of curly braces

                              const timezoneIdentifierNotes =
                                sessionStorage.getItem("timezone_identifier");
                              //const currentDate = new Date(Moment().tz(timezoneIdentifier).format('YYYY-MM-DDTHH:mm:ss')) || new Date();
                              //const createdOnDate = Moment(record.created_on).toDate();
                              const createdOnDate = new Date(
                                Moment(record.created_on).tz(
                                  timezoneIdentifierNotes
                                )
                              );
                              return (
                                <div key={index}>
                                  {" "}
                                  {/* Ensure each item has a unique key */}
                                  <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Icon.CardList />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={record.nt_notes}
                                      className="custom-note-text"
                                      secondary={
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body3"
                                          color="text.primary"
                                          className="text-small"
                                        >
                                          {Moment(createdOnDate).format(
                                            "DD-MM-YYYY hh:mm a"
                                          )}

                                          <DeleteForeverIcon
                                            disabled={isnoteDelSubmitting}
                                            onClick={() =>
                                              onSubmitRemoveNotes(
                                                record.pk_notes_key
                                              )
                                            }
                                            className="float-end icon-red cursor-pointer"
                                          />
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </div>
                              );
                            })}
                          </List>
                        ) : (
                          <p>No notes available.</p>
                        )}
                      </div>
                    </CustomTabPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Box>
      {/* <MyVerticallyCenteredModal1
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      /> */}

      {/* <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      /> */}
    </div>
  );
}
