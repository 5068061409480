/*
Purpose: Edit details of TUTOR
Designed: BY KTH
Date:30/10/2023 
Project: TCMS
*/

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Row, Col, Stack, Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import {
  security_key,
  options_gender,
  options_wage_type,
  options_emp_type,
  options_time_zone,
  options_countries,
  options_salutation,
  options_paymen_frequecy,
  BASE_URL,
} from "../global";
import "react-phone-input-2/lib/style.css"; // Import CSS styles for the phone input
import PhoneInput from "react-phone-input-2";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons
const phoneNumberRules = {
  'SG': {
    min: 8,
    max: 8,
    message: "Phone number must be exactly 8 digits.",
  }, // Singapore

  'AU': {
    min: 9,
    max: 10,
    message: "Phone number must be between 9 and 10 digits.",
  }, // Australia

  'GB': {
    min: 10,
    max: 10,
    message: "Phone number must be exactly 10 digits.",
  }, // United Kingdom

  'NZ': {
    min: 8,
    max: 10,
    message: "Phone number must be between 8 and 10 digits.",
  }, // New Zealand

  
  'General': {
  min: 5,
  max: 15,
  message: "Phone number must be between 5 to 15 digits.",
} // general number lenght
};

const postalCodeRules = {
  1: {
    pattern: /^[0-9]{6}$/,
    min: 6,
    max: 6,
    message: "Postal code must be exactly 6 digits.",
  }, // Singapore
  185: {
    pattern: /^[0-9]{4}$/,
    min: 4,
    max: 4,
    message: "Postal code must be exactly 4 digits.",
  }, // Australia
  184: {
    pattern: /^[0-9]{4}$/,
    min: 4,
    max: 4,
    message: "Postal code must be exactly 4 digits.",
  }, // New Zealand
  183: {
    pattern: /^[a-zA-Z0-9\/-]+$/,
    min: 4,
    max: 7,
    message: "Postal code must be between 4 and 7 characters.",
  }, // United Kingdom
};

const basicSchema = yup.object().shape({
  tut_fname: yup
    .string()
    .required("First name is required.")
    .min(2, "Minimum 2 characters is required.")
    .max(30, "Maximum 30 characters is required.")
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      "Letters and spaces only at between."
    ),
  tut_surname: yup
    .string()
    .required("Last Name is required.")
    .min(1, "Minimum 1 characters is required.")
    .max(30, "Maximum 30 characters is required.")
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      "Letters and spaces only at between."
    ),
  tut_dob: yup
    .string()
    .nullable(true)
    .test("is-valid-date", "Please provide a valid date.", function (value) {
      // Check if the value is null or a valid date
      return (
        value === undefined ||
        value === null ||
        value === "" ||
        dayjs(value, "YYYY-MM-DD").isValid()
      );
    }),
  //salutation: yup.mixed().required("Salutation is required."),
  //tut_dob: yup.date().required("Date of birth is required."),
  //tut_profile_pic: yup.mixed().required("File is required."),
  //tut_notes: yup.mixed().required("Notes is required."),
  tut_notes: yup
    .string()
    .notRequired()
    .matches(
      /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
      "Avoid start & end with a space or comma"
    ),

  //   tut_address: yup
  //     .string()
  //     .min(2, "Minimum 2 characters is required.")
  //     .max(250, "Maximum 250 characters is required.")
  //     .required("Address is required.")
  //     .matches(
  //       /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
  //       "Avoid start & end with a space or comma"
  //     ),
  //   tut_zip: yup
  //     .string()
  //     .min(4, "Minimum 4 characters is required.")
  //     .max(8, "Maximum 8 characters is required.")
  //     .required("Postal code is required.")
  //     .matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
  tut_zip: yup
    .string()
    .required("Postal code is required.")
    .test("valid-postal-code", function (value) {
      const { tut_country } = this.parent;

      const onlyCountries = ["1", "183", "184", "185"];
      const countryCode = onlyCountries.includes(tut_country) ? tut_country : 1;
      
      const postalCodeRule = postalCodeRules[countryCode];

      if (
        value &&
        (value.length < postalCodeRule.min || value.length > postalCodeRule.max)
      ) {
        if (postalCodeRule.min === postalCodeRule.max) {
          return this.createError({
            message: `Postal code must be exactly ${postalCodeRule.min} characters.`,
          });
        } else {
          return this.createError({
            message: `Postal code must be between ${postalCodeRule.min} and ${postalCodeRule.max} characters.`,
          });
        }
      }

      // Validate pattern
      if (value && !postalCodeRule.pattern.test(value)) {
        return this.createError({
          message: postalCodeRule.message,
        });
      }

      // Fallback: if no pattern is defined for the country, assume validation passes
      return true;
    }),

  //tut_address2: yup.mixed().required("Address2 is required."),
  //tut_suburb: yup.mixed().required("Suburb is required."),
  tut_city: yup
    .string()
    .min(2, "Minimum 2 characters is required.")
    .max(250, "Maximum 250 characters is required.")
    .required(" City/Town is required.")
    .matches(
      /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
      "Avoid start & end with a space or comma"
    )
    .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
  tut_state: yup
    .string()
    .min(2, "Minimum 2 characters is required.")
    .max(250, "Maximum 250 characters is required.")
    .required("State is required.")
    .matches(
      /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
      "Avoid start & end with a space or comma"
    )
    .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
  //tut_postcode: yup.mixed().required("Postcode is required."),
  //tut_country: yup.mixed().required("Country is required."),
  // tut_home_phone: yup.string().min(7, "Minimum 5 digit required").required("Mobile Phone is required."),
  // tut_work_phone: yup.string().min(7, "Minimum 5 digit required").notRequired(),
  tut_home_countryCode: yup.string().notRequired(),
  tut_work_countryCode: yup.string().notRequired(),
  tut_gender: yup.mixed().required("Gender is required."),
  tut_home_phone: yup
    .string()
    .test( 'valid-phone-number', function (value) {
      const { tut_home_countryCode } = this.parent;
      const countryCodeMap = {
        '44': 'GB',   // UK
        '61': 'AU',   // Australia
        '64': 'NZ',   // New Zealand
        '65': 'SG',   // Singapore
        
      };

     
      const countryKey = countryCodeMap[tut_home_countryCode];

      
      const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];

    
      const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${tut_home_countryCode}\\s?`, 'g'), '');

      if (countrySpecificRule) {
        const { min, max, message } = countrySpecificRule;
        const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
        // Check if the phone number length is within the valid range for the country
        if (phoneLength < min || phoneLength > max) {
          return this.createError({ message: message });
        }
      }

      return true;
    })
    .required("Mobile phone is required."),
  tut_work_phone: yup
    .string()
    .test( 'valid-phone-number', function (value) {
      const { tut_work_countryCode } = this.parent;
      const countryCodeMap = {
        '44': 'GB',   // UK
        '61': 'AU',   // Australia
        '64': 'NZ',   // New Zealand
        '65': 'SG',   // Singapore
        
      };

     
      const countryKey = countryCodeMap[tut_work_countryCode];

      
      const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];

    
      const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${tut_work_countryCode}\\s?`, 'g'), '');
      if (!cleanPhoneNumber ) {
        return true; 
      }
      if (countrySpecificRule) {
        const { min, max, message } = countrySpecificRule;
        const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
        // Check if the phone number length is within the valid range for the country
        if (phoneLength < min || phoneLength > max) {
          return this.createError({ message: message });
        }
      }

      return true;
    })
    .notRequired(),
  //tut_mobile: yup.mixed().required("Mobile is required."),
  tut_emailid: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required.")
    .matches(
      /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
      "Input cannot start with a special character"
    )
    .test("no-dot", "Please enter a valid email address", function (value) {
      return value && value.includes(".");
    }),

  // ta_login_id: yup.string().required("Username is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
  // ta_password: yup.string().min(5, "Minimum 5 characters is required.").matches(passwordRules).required("Password is required."),
  // ta_confirm_password: yup
  //     .string()
  //     .oneOf([yup.ref("ta_password"), null], "Password is not matching")
  //     .required("Confirm password is required."),
  //tut_timezone: yup.mixed().required("Time zone is required."),
  //is_active: yup.boolean().oneOf([true], "Please check the Is Active checkbox").required("Please check the Is Active checkbox"),
  te_emp_type: yup.mixed().required("Employee type is required."),
  te_st_date: yup
    .string()
    .test("is-valid-date", "Please provide a valid date.", function (value) {
      const parsedDate = dayjs(value, "YYYY-MM-DD");
      return parsedDate.isValid();
    })
    .required("Start date is required."),
  te_en_date: yup
    .string()
    .nullable(true)
    .test("is-valid-date", "Please provide a valid date.", function (value) {
      // Check if the value is null or a valid date
      return (
        value === undefined ||
        value === null ||
        value === "" ||
        dayjs(value, "YYYY-MM-DD").isValid()
      );
    }),
  //te_en_date: yup.mixed().required("End date is required."),
  //effective_date: yup.mixed().required("Effective date is required."),
  //te_wage_typ: yup.mixed().required("Wage type is required."),
  //te_wages:  yup.number().positive().integer().required("Wage rate is required."),
  tut_month_wage: yup
    .number()
    .integer()
    .min(0, "Minimum value 0 required")
    .required("Monthly Wage is required."),
  tut_weekly_wage: yup
    .number()
    .integer()
    .min(0, "Minimum value 0 required")
    .required("Weekly Wage is required."),
  tut_flat_rate_fees: yup
    .number()
    .integer()
    .min(0, "Minimum value 0 required")
    .required("Flat Rate Fee is required."),
  tut_per_stu_fees: yup
    .number()
    .integer()
    .min(0, "Minimum value 0 required")
    .required("Per Student Fee is required."),
  tut_min_student: yup
    .number()
    .integer()
    .min(0, "Minimum value 0 required")
    .required("Minimum No. of Students is required."),
  tut_payment_frequency: yup.mixed().required("Payment frequency is required."),
});

//Tutor function
function EditTutor() {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [ConpasswordVisible, setConPasswordVisible] = useState(false);

  const toggleConPasswordVisibility = () => {
    setConPasswordVisible(!ConpasswordVisible);
  };

  const navigate = useNavigate();

  const handleSave = () => {
    navigate("/list-tutor");
  };

  const [tutorData, setTutorData] = useState([]);

  //const [id, setId] = useState('');

  const location = useLocation();
  useEffect(() => {
    const { pk_tut_key } = location.state || {};
    const extractedTId = pk_tut_key;
    if (extractedTId !== "" && extractedTId !== undefined) {
      //setId(extractedId);
      fetchData(extractedTId);
    } else {
      navigate("/list-tutor");
    }
  }, [location]);
  const [imageUrl, setImageUrl] = useState("");
  //Api call start

  const fetchData = async (id) => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getSingleTutor_new",
        center_key: sessionStorage.getItem("key"),
        pk_tut_key: id,
      });

      if (response.data.success === true) {
        if (response.data.data.length > 0) {
          setTutorData(response.data.data[0]);

          if (
            response.data.data[0]["tut_profile_pic"] != null &&
            response.data.data[0]["tut_profile_pic"] != ""
          ) {
            const fullImageUrl = `${response.data.data[0]["tut_profile_pic"]}`;

            setImageUrl(fullImageUrl);
          }
        }
      } else {
        // Handle unsuccessful response, e.g., show an alert to the user.
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //Api call End

  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const onSubmit = async (formValues) => {
    // Disable the submit button and reset alerts
    setIsSubmitting(true);

    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "updateTutor_new",
        file_name: formValues.tut_profile_pic,
        ...formValues,
      });

      if (response.data.success === true) {
        if (response.data.data === 1) {
          //resetFormFields(resetForm, setValues, initialValues);
          toast.success("Success! Your form was submitted", {
            autoClose: 3000, // 3 seconds in this example
          });

          // After 3 seconds, redirect to another page
          setTimeout(function () {
            navigate("/list-tutor");
            setIsSubmitting(false); // Re-enable the submit button
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
        } else {
          toast.error("Failure! Unable to update tutor. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button
        }
      } else {
        toast.error("Failure! Unable to update tutor. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failure! Unable to update tutor. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });

      setIsSubmitting(false); // Re-enable the submit button
    } finally {
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  const formik = useFormik({
    initialValues: {
      /*tbl_tutor*/
      salutation: "", //Not in db
      tut_dob: tutorData ? tutorData.tut_dob : "",
      tut_fname: tutorData ? tutorData.tut_fname : "",
      tut_surname: tutorData ? tutorData.tut_surname : "",
      tut_profile_pic: tutorData ? tutorData.tut_profile_pic : null,
      tut_notes: tutorData ? tutorData.tut_notes : "",
      tut_address: tutorData ? tutorData.tut_address : "",
      tut_address2: "", //Not in db
      tut_suburb: "", //Not in db
      tut_city: tutorData ? tutorData.tut_city : "",
      tut_state: tutorData ? tutorData.tut_state : "",
      tut_postcode: "",
      tut_country: tutorData
        ? tutorData.tut_country != null && tutorData.tut_country != ""
          ? tutorData.tut_country
          : sessionStorage.getItem("country_code") != null &&
            sessionStorage.getItem("country_code") != ""
          ? sessionStorage.getItem("country_code")
          : "SG"
        : sessionStorage.getItem("country_code") != null &&
          sessionStorage.getItem("country_code") != ""
        ? sessionStorage.getItem("country_code")
        : "SG",
      //tut_country: tutorData ? tutorData.tut_country : '',
      tut_home_countryCode: "",
      tut_work_countryCode: "",
      tut_zip: tutorData ? tutorData.tut_zip : "",
      tut_home_phone: tutorData ? tutorData.tut_home_phone : "",
      tut_work_phone: tutorData ? tutorData.tut_work_phone : "",
      tut_mobile: "", //Not in db
      tut_emailid: tutorData ? tutorData.tut_emailid : "",
      tut_gender: tutorData
        ? tutorData.tut_gender != ""
          ? tutorData.tut_gender == "1"
            ? "Male"
            : tutorData.tut_gender == "2"
            ? "Female"
            : tutorData.tut_gender == "3"
            ? "Other"
            : ""
          : ""
        : "",
      /*tbl_tutor_auth*/
      // ta_login_id: tutorData ? tutorData.ta_login_id : '',
      // ta_password: tutorData ? tutorData.ta_password : '',
      // ta_confirm_password: tutorData ? tutorData.ta_password : '', //Not in db

      /*tbl_tut_employee*/
      tut_timezone: tutorData
        ? tutorData.tut_timezone != null && tutorData.tut_timezone != ""
          ? tutorData.tut_timezone
          : sessionStorage.getItem("time_zone")
        : sessionStorage.getItem("time_zone"),
      is_active: tutorData
        ? parseInt(tutorData.visibility) === 1
          ? true
          : false
        : false, //Not in db
      effective_date: "", //Not in db
      te_emp_type: tutorData ? tutorData.te_emp_type : "",
      te_st_date: tutorData ? tutorData.te_st_date : "", //Not in form
      te_en_date: tutorData ? tutorData.te_en_date : "", //Not in form
      te_wage_typ: tutorData ? tutorData.te_wage_typ : "",
      te_wages: tutorData ? tutorData.te_wages : "",

      tut_month_wage: tutorData ? tutorData.tut_month_wage : "0",
      tut_weekly_wage: tutorData ? tutorData.tut_weekly_wage : "0",
      tut_flat_rate_fees: tutorData ? tutorData.tut_flat_rate_fees : "0",
      tut_per_stu_fees: tutorData ? tutorData.tut_per_stu_fees : "0",
      tut_min_student: tutorData ? tutorData.tut_min_student : "0",
      tut_payment_frequency: tutorData ? tutorData.tut_payment_frequency : "",

      /*Common*/
      updated_by: sessionStorage.getItem("key"),
      fk_cent_key: tutorData ? tutorData.fk_cent_key : "",
      pk_tut_key: tutorData ? tutorData.pk_tut_key : "",
    },
    enableReinitialize: true,
    validationSchema: basicSchema,
    onSubmit,
  });
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;
  const fileInputRef = useRef(null);
  const [hide_some_filed, setFideSomeFiled] = useState(false);

  function handelRemove(event) {
    event.preventDefault();
    setImageUrl("");
  }

  const [isSubmittingPass, setIsSubmittingPass] = useState(false);

  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);

  //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema1 = yup.object().shape({
    ta_login_id: yup
    .string()
    .required("Username is required.")
    .min(2, "Minimum 2 characters is required.")
    .max(30, "Maximum 30 characters is required.")
    .matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    ta_password: yup
      .string()
      .min(5, "Minimum 5 characters is required.")
      .required("Password is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    ta_confirm_password: yup
      .string()
      .oneOf([yup.ref("ta_password"), null], "Password is not matching")
      .required("Confirm password is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
  });

  const initialValues1 = {
    pk_tut_key: tutorData ? tutorData.pk_tut_key : "",

    /*tbl_center_auth*/
    ta_login_id: tutorData ? tutorData.ta_login_id : "",

    ta_password: tutorData ? tutorData.ta_direct_password : "",
    ta_confirm_password: "", //Not in db
    fk_cent_key: sessionStorage.getItem("key"),
    updated_by: sessionStorage.getItem("key"),
  };

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingPass(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateTutorAuth_new",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Manage Login.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
              navigate("/list-tutor");
              setIsSubmittingPass(false); // Re-enable the submit button
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          } else if (response.data.data === 2) {
            toast.error("Tutor username is not available", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingPass(false); // Re-enable the submit button
          } else {
            toast.error(
              "Failure! Unable to update manage login. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingPass(false); // Re-enable the submit button
          }
        } else {
          toast.error(
            "Failure! Unable to update manage login. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );

          setIsSubmittingPass(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update manage login. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingPass(false); // Re-enable the submit button
      } finally {
        setIsSubmittingPass(false); // Re-enable the submit button
      }
    },
  });

  const handleUsernameBlur = useCallback(async () => {
    const username = formik1.values.ta_login_id;
    if (username) {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          pk_tut_key: tutorData.pk_tut_key,
          userID: username,
          security_key: security_key,
          mode: "checkTutorUserId",
        });

        if (response.data.success === true) {
          formik1.setFieldTouched("ta_login_id", true, false);
          formik1.setFieldError("ta_login_id", "Username is not available");
        } else {
          formik1.setFieldTouched("ta_login_id", true, false);
          formik1.setFieldError("ta_login_id", null);
        }
      } catch (error) {
        console.error("Error checking username:", error);
        formik1.setFieldError("ta_login_id", null);
      }
    }
  }, [formik1.values.ta_login_id]);

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const firstErrorField = Object.keys(formik.errors)[0];
      //const firstErrorFieldId = `${firstErrorField}Id`;

      const errorFieldElement = document.getElementById(firstErrorField);

      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
      }
      //document.getElementById(Object.keys(formik.errors)[0]).focus();
    }
  }, [formik]);
  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />
      <Modal
        show={showModal1}
        onHide={toggleModal1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Login</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik1.handleSubmit}>
          <Modal.Body>
            {/* --------------Date--------------- */}

            <div className="section-heading border-bottom pb-3 mb-3">
              <h6 className="mb-0">Tutor App Login</h6>
            </div>
            <Row>
              {/* --------------Contact Details Title--------------- */}

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Username</h6>
                <Form.Control
                  type="text"
                  //readOnly
                  id="ta_login_id"
                  value={formik1.values.ta_login_id}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  //onBlur={handleUsernameBlur}
                  className={
                    formik1.errors.ta_login_id && formik1.touched.ta_login_id
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.ta_login_id && formik1.touched.ta_login_id && (
                  <p className="error">{formik1.errors.ta_login_id}</p>
                )}
              </Col>

              {/* --------------Contact Details Title--------------- */}

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Password</h6>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  id="ta_password"
                  value={formik1.values.ta_password}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  className={
                    formik1.errors.ta_password && formik1.touched.ta_password
                      ? "input-error"
                      : ""
                  }
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle-button pswicon"
                  title="show / hide"
                >
                  {passwordVisible ? <FiEye /> : <FiEyeOff />}
                </button>
                {formik1.errors.ta_password && formik1.touched.ta_password && (
                  <p className="error">{formik1.errors.ta_password}</p>
                )}
              </Col>

              {/* --------------Contact Details Title--------------- */}

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Confirm Password</h6>
                <Form.Control
                  type={ConpasswordVisible ? "text" : "password"}
                  id="ta_confirm_password"
                  value={formik1.values.ta_confirm_password}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  className={
                    formik1.errors.ta_confirm_password &&
                    formik1.touched.ta_confirm_password
                      ? "input-error"
                      : ""
                  }
                />
                <button
                  type="button"
                  onClick={toggleConPasswordVisibility}
                  className="password-toggle-button pswicon"
                  title="show / hide"
                >
                  {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}
                </button>
                {formik1.errors.ta_confirm_password &&
                  formik1.touched.ta_confirm_password && (
                    <p className="error">
                      {formik1.errors.ta_confirm_password}
                    </p>
                  )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="mx-auto d-flex gap-2">
              <Button
                variant="contained"
                className="primary-gray"
                onClick={toggleModal1}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="primary-filled "
                disabled={isSubmittingPass}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* center form */}
      <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <div className="">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
              <h5 className="page-title"> Edit Tutor </h5>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                  <NavLink underline="hover" color="inherit" to="/calendar">
                    {" "}
                    Home
                  </NavLink>

                  <Typography color="text.primary">Tutor</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-end gap-2">
                <div>
                  <Button
                    variant="contained"
                    className="primary-filled"
                    style={{ fontSize: "12px" }}
                    onClick={() => toggleModal1()}
                  >
                    {" "}
                    Manage Login
                  </Button>
                  {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                        <Dropdown.Item onClick={() => toggleModal1()}>  Manage Login
                                        </Dropdown.Item>


                                    </DropdownButton> */}
                </div>
              </div>
            </div>
          </div>

          {/* --------------Tutor Personal--------------- */}
          <Stack>
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Personal </h6>
              </div>
              <Row>
                {/* --------------Tutor Salutation--------------- */}
                {hide_some_filed && (
                  <Form.Group as={Row} className="mb-3" controlId="salutation">
                    <Form.Label column sm="3">
                      <h6>Salutation</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <Select
                        menuPlacement="auto"
                        options={options_salutation}
                        //value={values.salutation}
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "salutation",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, salutation: true });
                        }}
                        className={
                          errors.salutation && touched.salutation
                            ? "input-error"
                            : ""
                        }
                      />
                      {errors.salutation && touched.salutation && (
                        <p className="error">{errors.salutation}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}

                {/* --------------Tutor First Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">First Name</h6>
                  <Form.Control
                    type="text"
                    id="tut_fname"
                    value={values.tut_fname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_fname && touched.tut_fname ? "input-error" : ""
                    }
                  />
                  {errors.tut_fname && touched.tut_fname && (
                    <p className="error">{errors.tut_fname}</p>
                  )}
                </Col>

                {/* --------------Tutor Last Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Last Name</h6>
                  <Form.Control
                    type="text"
                    id="tut_surname"
                    value={values.tut_surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_surname && touched.tut_surname
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_surname && touched.tut_surname && (
                    <p className="error">{errors.tut_surname}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Gender</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_gender}
                    name="tut_gender"
                    value={options_gender.find(
                      (option) =>
                        option.value &&
                        values.tut_gender &&
                        option.value.toString() === values.tut_gender.toString()
                    )}
                    //value={values.tut_gender["value"]}
                    id="tut_gender"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "tut_gender",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_gender: true });
                    }}
                    className={
                      errors.tut_gender && touched.tut_gender
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_gender && touched.tut_gender && (
                    <p className="error">{errors.tut_gender}</p>
                  )}
                </Col>

                {/* --------------Tutor Date of Birth--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Date of Birth</h6>
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.tut_dob, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "tut_dob",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, tut_dob: true });
                      }}
                      name="tut_dob"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "tut_dob",
                        },
                      }}
                      className={
                        errors.tut_dob && touched.tut_dob
                          ? "input-error w-100"
                          : "w-100"
                      }
                      maxDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.tut_dob && touched.tut_dob && (
                    <span className="error">{errors.tut_dob}</span>
                  )}
                </Col>

                {/* --------------Tutor Photo--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Photo</h6>
                  {!imageUrl && (
                    <Form.Control
                      type="file"
                      name="tut_profile_pic"
                      id="tut_profile_pic"
                      accept=".png, .jpeg, .jpg, .jfif"
                      onChange={(event) => {
                        setTouched({ ...touched, tut_profile_pic: true });
                        if (event.target.files.length > 0) {
                          const fileName = event.target.files[0];
                          async function handleFileUpload(fileName) {
                            try {
                              // Create a FormData object to send the form data, including the image file
                              const formData = new FormData();
                              formData.append("mode", "file_s3_upload_image");
                              formData.append("user", "tutor");
                              formData.append("image", fileName); // Append the image file

                              // Make an API request here with the FormData object
                              const response_file = await axios.post(
                                BASE_URL + "API/upload/index.php",
                                formData
                              );

                              // Handle the API response here
                              if (response_file.data.success === true) {
                                handleChange({
                                  target: {
                                    name: "tut_profile_pic",
                                    value: response_file.data.data,
                                  },
                                });
                              } else {
                                handleChange({
                                  target: {
                                    name: "tut_profile_pic",
                                    value: null,
                                  },
                                }); // Clear the input value
                                fileInputRef.current.value = null;
                              }

                              // You can also perform further actions based on the API response
                              // For example, show a success message or redirect to another page.
                            } catch (error) {
                              handleChange({
                                target: {
                                  name: "tut_profile_pic",
                                  value: null,
                                },
                              }); // Clear the input value
                              fileInputRef.current.value = null;
                            }
                          }

                          // Call the async function when needed, passing the fileName as an argument
                          handleFileUpload(fileName);
                        } else {
                          // handleChange(event);

                          handleChange({
                            target: { name: "tut_profile_pic", value: null },
                          }); // Clear the input value
                          fileInputRef.current.value = null;
                        }
                      }}
                      ref={fileInputRef}
                      className={
                        errors.tut_profile_pic && touched.tut_profile_pic
                          ? "input-error"
                          : ""
                      }
                    />
                  )}
                  {imageUrl && (
                    <Col
                      lg="4"
                      md="6"
                      sm="12"
                      className="d-flex justify-content-between"
                    >
                      <img src={imageUrl} alt="image" width="50" height="50" />
                      <div className="text-end">
                        <Button
                          variant="contained"
                          className="primary-filled  btn btn-contained"
                          onClick={handelRemove}
                        >
                          X
                        </Button>
                      </div>
                    </Col>
                  )}

                  {errors.tut_profile_pic && touched.tut_profile_pic && (
                    <p className="error">{errors.tut_profile_pic}</p>
                  )}
                </Col>

                {/* --------------Tutor Notes--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Notes</h6>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="tut_notes"
                    value={values.tut_notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_notes && touched.tut_notes
                        ? "input-error control_textarea"
                        : "control_textarea"
                    }
                  />
                  {errors.tut_notes && touched.tut_notes && (
                    <p className="error">{errors.tut_notes}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Tutor Personal End--------------- */}

          {/* --------------Contact Details--------------- */}
          <Stack className="mt-4">
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Contact Details </h6>
              </div>

              <Row>
                {/* --------------Contact Address1--------------- */}

                <Col lg="12" md="12" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Address</h6>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="tut_address"
                    value={values.tut_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_address && touched.tut_address
                        ? "input-error control_textarea"
                        : "control_textarea"
                    }
                  />
                  {errors.tut_address && touched.tut_address && (
                    <p className="error">{errors.tut_address}</p>
                  )}
                </Col>

                {/* --------------Contact Address2--------------- */}
                {hide_some_filed && (
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="tut_address2"
                  >
                    <Form.Label column sm="3">
                      <h6>Address 2</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        // value={tutorData.tut_city}
                        value={values.tut_address2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.tut_address2 && touched.tut_address2
                            ? "input-error control_textarea"
                            : "control_textarea"
                        }
                      />
                      {errors.tut_address2 && touched.tut_address2 && (
                        <p className="error">{errors.tut_address2}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}
                {/* --------------Contact Details Title--------------- */}
                {hide_some_filed && (
                  <Form.Group as={Row} className="mb-3" controlId="tut_suburb">
                    <Form.Label column sm="3">
                      <h6>Suburb</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <Form.Control
                        type="text"
                        value={values.tut_suburb}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.tut_suburb && touched.tut_suburb
                            ? "input-error"
                            : ""
                        }
                      />
                      {errors.tut_suburb && touched.tut_suburb && (
                        <p className="error">{errors.tut_suburb}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}

                {/* --------------Contact Details city--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable required-field">City/Town</h6>
                  <Form.Control
                    type="text"
                    id="tut_city"
                    value={values.tut_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_city && touched.tut_city ? "input-error" : ""
                    }
                  />
                  {errors.tut_city && touched.tut_city && (
                    <p className="error">{errors.tut_city}</p>
                  )}
                </Col>

                {/* --------------Contact Details Title--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable required-field">
                    County/Region/State
                  </h6>
                  <Form.Control
                    type="text"
                    id="tut_state"
                    value={values.tut_state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_state && touched.tut_state ? "input-error" : ""
                    }
                  />
                  {errors.tut_state && touched.tut_state && (
                    <p className="error">{errors.tut_state}</p>
                  )}
                </Col>

                {/* --------------Contact Details Title--------------- */}
                {hide_some_filed && (
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="tut_postcode"
                  >
                    <Form.Label column sm="3">
                      <h6>Postcode</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <Form.Control
                        type="text"
                        value={values.tut_postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.tut_postcode && touched.tut_postcode
                            ? "input-error"
                            : ""
                        }
                      />
                      {errors.tut_postcode && touched.tut_postcode && (
                        <p className="error">{errors.tut_postcode}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}

                {/* --------------Student Country--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable required-field">Country</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_countries}
                    //value={values.country["value"]}
                    value={options_countries.find(
                      (option) =>
                        option.value &&
                        values.tut_country &&
                        option.value.toString() ===
                          values.tut_country.toString()
                    )}
                    // value={options_countries.find(option => option.value === values.tut_country)}
                    id="tut_country"
                    isDisabled={true}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "tut_country",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_country: true });
                    }}
                    className={
                      errors.tut_country && touched.tut_country
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_country && touched.tut_country && (
                    <p className="error">{errors.tut_country}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Postal Code</h6>
                  <Form.Control
                    type="text"
                    value={values.tut_zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="tut_zip"
                    className={
                      errors.tut_zip && touched.tut_zip ? "input-error" : ""
                    }
                  />
                  {errors.tut_zip && touched.tut_zip && (
                    <p className="error">{errors.tut_zip}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Contact Details End--------------- */}

          {/* --------------Contact Details--------------- */}
          <Stack className="mt-4">
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Contact </h6>
              </div>

              <Row>
                {/* --------------Contact Details Title--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Mobile Phone{" "}
                    <a
                      href={`https://wa.me/${
                        values
                          ? values.tut_home_phone != "" &&
                            values.tut_home_phone != null
                            ? values.tut_home_phone
                            : ""
                          : ""
                      }`}
                      target="_blank"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <i className="fa fa-whatsapp me-2"></i>
                    </a>
                  </h6>
                  {/* <Form.Control 
                            type="text"
                            id='tut_home_phone'
                            value={values.tut_home_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.tut_home_phone && touched.tut_home_phone ? "input-error" : ""}
                            
                             />
                            {errors.tut_home_phone && touched.tut_home_phone && <p className="error">{errors.tut_home_phone}</p>} */}
                  <PhoneInput
                    name="tut_home_phone"
                    countryCodeEditable={false}
                    onlyCountries={["sg", "nz", "gb", "au"]}
                    country={
                      sessionStorage.getItem("country_alph_code") != null &&
                      sessionStorage.getItem("country_alph_code") != ""
                        ? sessionStorage
                            .getItem("country_alph_code")
                            .toLowerCase()
                        : "sg"
                    } // Set the default country (e.g., singapore)
                    value={values.tut_home_phone}
                    onChange={(phone, country) => {
                      const event = {
                        target: {
                          name: "tut_home_phone",
                          value: phone,
                        },
                      };
                      const event1 = {
                        target: {
                          name: "tut_home_countryCode",
                          value: country.dialCode,
                        },
                      };
                      handleChange(event1);
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_home_phone: true });
                    }}
                    inputProps={{ id: "tut_home_phone" }}
                    id="tut_home_phone"
                    inputClass={
                      errors.tut_home_phone && touched.tut_home_phone
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  {errors.tut_home_phone && touched.tut_home_phone && (
                    <span className="error">{errors.tut_home_phone}</span>
                  )}
                </Col>

                {/* --------------Contact Details Title--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">
                    Other Phone{" "}
                    <a
                      href={`https://wa.me/${
                        values
                          ? values.tut_work_phone != "" &&
                            values.tut_work_phone != null
                            ? values.tut_work_phone
                            : ""
                          : ""
                      }`}
                      target="_blank"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <i className="fa fa-whatsapp me-2"></i>
                    </a>
                  </h6>
                  {/* <Form.Control type="text"
                            id='tut_work_phone'
                            value={values.tut_work_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.tut_work_phone && touched.tut_work_phone ? "input-error" : ""}
                            
                             />
                            {errors.tut_work_phone && touched.tut_work_phone && <p className="error">{errors.tut_work_phone}</p>} */}
                  <PhoneInput
                    name="tut_work_phone"
                    countryCodeEditable={false}
                    onlyCountries={["sg", "nz", "gb", "au"]}
                    country={
                      sessionStorage.getItem("country_alph_code") != null &&
                      sessionStorage.getItem("country_alph_code") != ""
                        ? sessionStorage
                            .getItem("country_alph_code")
                            .toLowerCase()
                        : "sg"
                    } // Set the default country (e.g., singapore)
                    value={values.tut_work_phone}
                    onChange={(phone, country) => {
                      const event = {
                        target: {
                          name: "tut_work_phone",
                          value: phone,
                        },
                      };
                      const event1 = {
                        target: {
                          name: "tut_work_countryCode",
                          value: country.dialCode,
                        },
                      };
                      handleChange(event1);
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_work_phone: true });
                    }}
                    inputProps={{ id: "tut_work_phone" }}
                    id="tut_work_phone"
                    inputClass={
                      errors.tut_work_phone && touched.tut_work_phone
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  {errors.tut_work_phone && touched.tut_work_phone && (
                    <span className="error">{errors.tut_work_phone}</span>
                  )}
                </Col>

                {/* --------------Contact Details Title--------------- */}
                {hide_some_filed && (
                  <Form.Group as={Row} className="mb-3" controlId="tut_mobile">
                    <Form.Label column sm="3">
                      <h6>Mobile</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <h6 className="form-lable required-field">Work Phone</h6>
                      <Form.Control
                        type="text"
                        value={values.tut_mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.tut_mobile && touched.tut_mobile
                            ? "input-error"
                            : ""
                        }
                      />
                      {errors.tut_mobile && touched.tut_mobile && (
                        <p className="error">{errors.tut_mobile}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}
                {/* --------------Contact Details Title--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Email{" "}
                    <a
                      href={`mailto:${
                        values.tut_emailid !== ""
                          ? values.tut_emailid
                          : "userID@example.com"
                      }`}
                      target="_blank"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <i class="fa-solid fa-envelope me-2"></i>
                    </a>
                  </h6>
                  <Form.Control
                    type="text"
                    id="tut_emailid"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tut_emailid}
                    className={
                      errors.tut_emailid && touched.tut_emailid
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_emailid && touched.tut_emailid && (
                    <p className="error">{errors.tut_emailid}</p>
                  )}
                </Col>

                {/* --------------Student Parents / Guardians --------------- */}

                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="position-relative"
                  style={{ display: "none" }}
                >
                  <h6 className="form-lable required-field">Time zone</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_time_zone}
                    id="tut_timezone"
                    value={options_time_zone.find(
                      (option) => option.value === values.tut_timezone
                    )}
                    name="tut_timezone"
                    isDisabled={true}
                    // value={values.tut_timezone["value"]}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "tut_timezone",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_timezone: true });
                    }}
                    className={
                      errors.tut_timezone && touched.tut_timezone
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_timezone && touched.tut_timezone && (
                    <p className="error">{errors.tut_timezone}</p>
                  )}
                </Col>

                {/* --------------Contact Details Title--------------- */}

                <Col lg="12" md="12" sm="12" className="position-relative">
                  <Form.Check
                    type="checkbox"
                    id="is_active"
                    label="Is Active"
                    checked={values.is_active}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.is_active && touched.is_active && (
                    <p className="form-check-error">{errors.is_active}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Contact Details End--------------- */}

          {/* --------------Login Details--------------- */}
          {/* <Stack className='mt-4'>
                    <div className="my-card p-4 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Login </h6>
                        </div>
            
                            <Row> */}

          {/* --------------Contact Details Title--------------- */}

          {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control type="text"
                                    id='ta_login_id' 
                                    value={values.ta_login_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.ta_login_id && touched.ta_login_id ? "input-error" : ""}
                                     />
                                        {errors.ta_login_id && touched.ta_login_id && <p className="error">{errors.ta_login_id}</p>}
                                </Col> */}

          {/* --------------Contact Details Title--------------- */}

          {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control type="password"
                                    id='ta_password'
                                        value={values.ta_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ta_password && touched.ta_password ? "input-error" : ""}
                                         />
                                        {errors.ta_password && touched.ta_password && <p className="error">{errors.ta_password}</p>}
                                </Col> */}

          {/* --------------Contact Details Title--------------- */}
          {/*                                    
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control type="password" 
                                    id='ta_confirm_password'
                                        value={values.ta_confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ta_confirm_password && touched.ta_confirm_password ? "input-error" : ""}
                                    
                                     />
                                        {errors.ta_confirm_password && touched.ta_confirm_password && (
                                            <p className="error">{errors.ta_confirm_password}</p>
                                        )}
                                </Col> */}

          {/* </Row>
                
                        </div>
                    </Stack> */}

          {/* --------------Login Details End--------------- */}

          {/* --------------Conditions Details--------------- */}
          <Stack className="mt-4">
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3 d-flex">
                <h6 className="mb-0">Employment Conditions</h6>&nbsp;
                {["right"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip
                        id={`tooltip-${placement}`}
                        className="custom-tooltip"
                      >
                        If both flat rate fee and per student fee are input, the
                        wage calculated for the tutor will use the following
                        formula: Flat Rate Fee + (Number of Students * Per
                        Student Fee)
                      </Tooltip>
                    }
                  >
                    <Button variant="secondary" className="no-bdr">
                      <i class="fa-solid fa-info-circle"></i>
                    </Button>
                  </OverlayTrigger>
                ))}
              </div>

              <Row>
                {/* --------------Student Parents / Guardians --------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable required-field">Employee Type</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_emp_type}
                    id="te_emp_type"
                    value={options_emp_type.find(
                      (option) => option.value === values.te_emp_type
                    )}
                    name="te_emp_type"
                    //value={values.te_emp_type["value"]}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "te_emp_type",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, te_emp_type: true });
                    }}
                    className={
                      errors.te_emp_type && touched.te_emp_type
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.te_emp_type && touched.te_emp_type && (
                    <p className="error">{errors.te_emp_type}</p>
                  )}
                </Col>

                {/* --------------Student Parents / Guardians --------------- */}
                {hide_some_filed && (
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="effective_date"
                  >
                    <Form.Label column sm="3">
                      <h6>Effective Date</h6>
                    </Form.Label>
                    <Col lg="4" md="6" sm="12" className="position-relative">
                      <Form.Control
                        type="date"
                        value={values.effective_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.effective_date && touched.effective_date
                            ? "input-error"
                            : ""
                        }
                      />
                      {errors.effective_date && touched.effective_date && (
                        <p className="error">{errors.effective_date}</p>
                      )}
                    </Col>
                  </Form.Group>
                )}

                {/* --------------Start Date--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable required-field">Start Date</h6>
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.te_st_date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "te_st_date",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, te_st_date: true });
                      }}
                      name="te_st_date"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "te_st_date",
                        },
                      }}
                      className={
                        errors.te_st_date && touched.te_st_date
                          ? "input-error w-100"
                          : "w-100"
                      }
                      minDate={dayjs(values.te_st_date, "YYYY-MM-DD")}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.te_st_date && touched.te_st_date && (
                    <span className="error">{errors.te_st_date}</span>
                  )}
                </Col>

                {/* ------------- Emp End DATE--------------- */}
                {/* {hide_some_filed && (   */}
                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable ">End Date</h6>
                  {/* <Form.Control type="date"
                                        id='te_en_date'
                                        min={values.te_st_date ? values.te_st_date : undefined} // Set min to te_st_date if it's defined
                                        value={values.te_en_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.te_en_date && touched.te_en_date ? "input-error" : ""}

                                    /> */}

                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.te_en_date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "te_en_date",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, te_en_date: true });
                      }}
                      name="te_en_date"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "te_en_date",
                        },
                      }}
                      className={
                        errors.te_en_date && touched.te_en_date
                          ? "input-error w-100"
                          : "w-100"
                      }
                      minDate={dayjs(values.te_st_date, "YYYY-MM-DD")}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.te_en_date && touched.te_en_date && (
                    <span className="error">{errors.te_en_date}</span>
                  )}
                </Col>
                {/* )} */}
                {/* --------------Student Parents / Guardians --------------- */}

                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="position-relative"
                  style={{ display: "none" }}
                >
                  <h6 className="form-lable required-field">Wage Type</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_wage_type}
                    id="te_wage_typ"
                    value={options_wage_type.find(
                      (option) => option.value === values.te_wage_typ
                    )}
                    name="te_wage_typ"
                    //value={values.te_wage_typ["value"]}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "te_wage_typ",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, te_wage_typ: true });
                    }}
                    className={
                      errors.te_wage_typ && touched.te_wage_typ
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.te_wage_typ && touched.te_wage_typ && (
                    <p className="error">{errors.te_wage_typ}</p>
                  )}
                </Col>

                {/* --------------onditions Details Title--------------- */}

                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="position-relative"
                  style={{ display: "none" }}
                >
                  <h6 className="form-lable required-field">Wage Rate</h6>
                  <Form.Control
                    type="text"
                    id="te_wages"
                    value={values.te_wages}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.te_wages && touched.te_wages ? "input-error" : ""
                    }
                  />
                  {errors.te_wages && touched.te_wages && (
                    <p className="error">{errors.te_wages}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Monthly Wage</h6>
                  <Form.Control
                    type="number"
                    id="tut_month_wage"
                    value={values.tut_month_wage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_month_wage && touched.tut_month_wage
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_month_wage && touched.tut_month_wage && (
                    <p className="error">{errors.tut_month_wage}</p>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Weekly Wage</h6>
                  <Form.Control
                    type="number"
                    id="tut_weekly_wage"
                    value={values.tut_weekly_wage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_weekly_wage && touched.tut_weekly_wage
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_weekly_wage && touched.tut_weekly_wage && (
                    <p className="error">{errors.tut_weekly_wage}</p>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Flat Rate Fee</h6>
                  <Form.Control
                    type="number"
                    id="tut_flat_rate_fees"
                    value={values.tut_flat_rate_fees}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_flat_rate_fees && touched.tut_flat_rate_fees
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_flat_rate_fees && touched.tut_flat_rate_fees && (
                    <p className="error">{errors.tut_flat_rate_fees}</p>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Per Student Fee</h6>
                  <Form.Control
                    type="number"
                    id="tut_per_stu_fees"
                    value={values.tut_per_stu_fees}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_per_stu_fees && touched.tut_per_stu_fees
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_per_stu_fees && touched.tut_per_stu_fees && (
                    <p className="error">{errors.tut_per_stu_fees}</p>
                  )}
                </Col>
                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Minimum No. of Students</h6>
                                    <Form.Control
                                        type="number"
                                        id="tut_min_student"

                                        value={values.tut_min_student}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.tut_min_student && touched.tut_min_student ? "input-error" : ""}
                                    />
                                    {errors.tut_min_student && touched.tut_min_student && <p className="error">{errors.tut_min_student}</p>}
                                </Col> */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-label required-field">
                    Minimum No. of Students
                  </h6>
                  <Form.Select
                    id="tut_min_student"
                    value={values.tut_min_student}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.tut_min_student && touched.tut_min_student
                        ? "input-error"
                        : ""
                    }
                  >
                    <option value="">---Select---</option>
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.tut_min_student && touched.tut_min_student && (
                    <p className="error">{errors.tut_min_student}</p>
                  )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Payment Frequency
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_paymen_frequecy}
                    name="tut_payment_frequency"
                    id="tut_payment_frequency"
                    value={options_paymen_frequecy.find(
                      (option) => option.value == values.tut_payment_frequency
                    )}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "tut_payment_frequency",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, tut_payment_frequency: true });
                    }}
                    className={
                      errors.tut_payment_frequency &&
                      touched.tut_payment_frequency
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.tut_payment_frequency &&
                    touched.tut_payment_frequency && (
                      <p className="error">{errors.tut_payment_frequency}</p>
                    )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Conditions Details End--------------- */}

          <Stack gap={2} className="mt-4">
            <div className="text-end">
              <Button
                variant="contained"
                className="primary-gray me-2"
                onClick={handleSave}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="primary-filled "
                disabled={isSubmitting}
              >
                Update
              </Button>
            </div>
          </Stack>
        </div>
      </Form>
    </>
  );
}

export default EditTutor;
